import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const palette = {
    // primary: {
    //     main: '#0C3C4E',
    //     light: '#3e667a',
    //     dark: '#001726',
    // },
    // secondary: {
    //     main: '#7ba7ae',
    //     light: '#abd9e0',
    //     dark: '#4d787f',
    //     contrastText: '#ffffff',
    // },
    // warning: {
    //     main: '#ff4d00',
    // },
    primary: {
        main: '#21AFB1',
        light: '#CAEDEE',
        contrastText: '#ffffff'
    },
    secondary: {
        main: '#0E3C64',
        light: '#41B6E6',
        dark: '#0E3C64'
    },
    accent: {
        main: '#EC6530',
        dark: '#D55321',
        contrastText: '#ffffff'
    },
    warning: {
        main: '#ec6530',
    },
};

export const theme = createTheme({
    palette: palette,
});

export const useMobile = () => useMediaQuery(useTheme().breakpoints.down('md'));
export const useXsSize = () => useMediaQuery(useTheme().breakpoints.down('sm'));