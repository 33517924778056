export const getPercentColor = number => {
    if (0 <= number && number < 25) return 'rgba(252, 61, 61, 0.5)';
    else if (25 <= number && number < 75) return 'rgba(255, 165, 0, 0.5)';
    else return 'rgba(144, 238, 144, 0.5)';
};

export const getRandomCustomerName = () => {
    const customerNames = [
        'Norrköpings Kommun',
        'Tage Rejmes i Linköping',
        'Scenkonst Öst',
        'Motorsport i Vimmerby',
        'Auoja Fastighetsförmedling',
        'Luthagens Livs',
        'Region Uppsala',
        'Länsförsäkringar Gotland',
        'Däckcenter i Katrineholm',
        'Bil Brodd',
        'Piteå MC & Motor',
        'Kiruna Kommun',
        'Forslunds Glas & Porslin',
        'Didriksons Brasvärme',
        'Region Sörmland',
        'Uppsala Kommun',
        'Skellefteå Kulturhus',
        'Nya Hem i Västervik',
        'Wikan Personal Botnia',
        'Rosins Pappershandel',
        'Luleå Hockey',
        'Eskilstuna Handelsstål',
        'Blomsterglädje i Motala',
        'Herberts Guld & Silver',
        'Theibus',
        'Enköping Maskincenter',
        'Nelins Partiaffär',
    ];
    return customerNames[Math.floor(Math.random() * customerNames.length)];
};

export const dateFormatter = date => {
    //12 gmt fix s
    if (date.year && date.month && date.day) return new Date(date.year, date.month - 1, date.day, 12).toISOString().substring(0, 10);
    else return '-';
};

export const formatDate = (date, includeTime = false) => {
    if (date) {
        if (includeTime) return new Date(date).toISOString().substring(0, 16).replace('T', ' ');
        else return new Date(date).toISOString().substring(0, 10);
    }
    return null;
};

export const sortAndFilterOrders = (arr, sorting) => {
    arr = filterOrders(arr, sorting.filter);
    const v = sorting.ascending ? 1 : -1;
    arr.sort((a, b) => {
        if (a[sorting.column] > b[sorting.column]) return v;
        if (a[sorting.column] < b[sorting.column]) return -v;
        return 0;
    });
    return arr;
};

const filterOrders = (arr, filterOption) => {
    const now = new Date();
    switch (filterOption) {
        case 'upcoming':
            return arr.filter(obj => new Date(obj?.start) > now);
        case 'ongoing':
            return arr.filter(obj => new Date(obj?.start) < now && new Date(obj?.end) > now);
        case 'finished':
            return arr.filter(obj => new Date(obj?.end) < now);
        case 'all':
            return arr;
        default:
            return arr;
    }
};

export const caluclateLineitemCTR = lineitem => {
    const clicks = lineitem?.stats?.clicksDelivered;
    const imps = lineitem?.stats?.impressionsDelivered;
    if (clicks && imps) return (clicks / imps) * 100;
    else return 0;
};

export const getTomorrowsDate = () => {
    const tomorrow = new Date(Date.now() + 3600 * 1000 * 24);
    return {
        year: tomorrow.getFullYear(),
        month: tomorrow.getMonth() + 1,
        day: tomorrow.getDate(),
        hour: 0,
        minute: 0,
        second: 0,
    };
};

export const timeUnitTranslate = {
    weeks: 'Vecka',
    months: 'Månad',
};

export const deliverySort = lineitem => {
    const actual = lineitem?.deliveryIndicator?.actualDeliveryPercentage ?? 0;
    const expected = lineitem?.deliveryIndicator?.expectedDeliveryPercentage ?? 0;
    const deliveryDiff = actual === 0 || expected === 0 ? 0 : (actual / expected) * 100;
    return deliveryDiff;
};

export const monthTranslate = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'];

export const doohClickFilterNames = ['borttagen', 'test'];

export const timeUnitConvert = (date, type) => {
    const dateSplit = date.split('-')[1];
    switch (type) {
        case '1': 
            const monthTranslate = {
                January: 'Januari',
                February: 'Februari',
                March: 'Mars',
                April: 'April',
                May: 'Maj',
                June: 'Juni',
                July: 'Juli',
                August: 'Augusti',
                September: 'September',
                October: 'Oktober',
                November: 'November',
                December: 'December',
            };
            return monthTranslate[dateSplit];
        case '2':
            return dateSplit.replace('Week', 'Vecka');
        case '3': 
        return date.split('.').reverse().join('-');

        default:
            return dateSplit;
    }
};
export const formatCustomTargeting = ct => {
    if (!ct.key || !ct.values || ct.values?.length === 0) return null;
    return {
        key: ct.key?.id,
        values: ct.values?.map(v => v?.id),
    };
};

export const formatAudienceTargeting = at => {
    if (!at || at?.length === 0) return null;
    return at?.map(v => v?.id);
};

export const formatFrequencyCap = fc => {
    if (!fc.maxImpressions || !fc.numTimeUnits || !fc.timeUnit) return null;
    return {
        max_impressions: fc.maxImpressions,
        num_time_units: fc.numTimeUnits,
        time_unit: fc.timeUnit,
    };
};

export const sortByName = arr => {
    arr.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });
    return arr;
};
