import { useParams } from "react-router-dom"
import { ConfigTools } from "./ConfigTools"
import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { DefaultBody, DefaultPage, HeaderWithText } from "../../DefaultComponents"
import { flattenedLayout } from "../../../config/navigation"
import Upload from "./FileUpload"
import { getBackendSubDomain } from "../../../functions/subDomain"

export const ScreenMaterialPreview = ({ }) => {
    const { materialId, screenId } = useParams()
    const [material, setMaterial] = useState(null)
    const [background, setBackground] = useState(null)
    const [position, setPosition] = useState(null)

    const fetchMaterial = async (id) => {
        try {
            const response = await fetch(`${getBackendSubDomain('nadir')}/files/download/${id}`);
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setMaterial(url);
            } else {
                console.error('Failed to fetch image:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };


    //3 routes 
    // (fhv/ -välj skylt )
    // fhv/screenId/ ladda upp material -> skapa länk till ...
    // fhv/screenId/materialId bara visa, visa lite skylt info i feta vita bokstäver. 

    const load = async () => {
        const screen = screens['default']
        setBackground(screen.url)
        setPosition(screen.config)
        await fetchMaterial(materialId)

        // await fetchMaterial(materialId)

    }

    useEffect(() => {
        screenId && materialId && load()
    }, [screenId])

    return <DefaultPage>
        <DefaultBody>
        {background && position && <Grid container item xs={12}><Grid item xs={12}>
            <div style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
                perspective: `${position.perspective}px`
            }}>
                <img src={background} style={{ width: '100%', display: 'block' }} />
                {material && <img src={material} style={{
                    position: 'absolute',
                    left: `${position.left}%`,
                    top: `${position.top}%`,
                    width: `${position.width}%`,
                    height: `${position.height}%`,
                    opacity: position.opacity,
                    transform: `rotateX(${position.rotateX}deg) rotateY(${position.rotateY}deg) rotateZ(${position.rotateZ}deg)`,
                    transformStyle: 'preserve-3d'
                }} />}
            </div>
        </Grid></Grid>}
        </DefaultBody>
    </DefaultPage>
}

//replace with req to a db or a table with this information on every screen
const screens = {
    default: {
        config: {
            "left": 47,
            "top": 31,
            "width": 23,
            "height": 15,
            "rotateX": 10,
            "rotateY": -49,
            "rotateZ": 0,
            "perspective": 1122,
            "opacity": 1
        },
        url: "https://dcstoragemainlive.blob.core.windows.net/screens/74/17080/Lulea%20energi%20arena.jpg"
    }
}