import { Grid, Autocomplete, TextField, Paper } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { DefaultPage, HeaderWithText } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import { CustomersTable } from './CustomersTable';
import { CustomersContext } from '../../App';
import { grey } from '@mui/material/colors';
import { useMobile } from '../../material/theme';

const getFilters = () => {
    const cts = localStorage.getItem('customers-table-filters');
    return cts ? JSON.parse(cts) : [];
};

export const Customers = props => {
    const customersContext = useContext(CustomersContext);
    const { customers, customersLoading, customersUniqueSorts } = customersContext.data;

    const isMobile = useMobile();
    // const info = flattenedLayout.customers;
    const info =  { text: 'Kunder', description: "Ger dig en översikt av alla kunder" };

    const [myFilter, setMyFilter] = React.useState(getFilters());

    useEffect(() => {
        if (customers.length === 0) customersContext.triggerCustomersFetch(true);
    }, []);

    useEffect(() => {
        localStorage.setItem('customers-table-filters', JSON.stringify(myFilter));
    }, [myFilter]);

    let groupedOptions = [];
    const translateGroupNames = {
        team: 'Team',
        division: 'Division',
        salesRep: 'Kundansvarig',
        labels: 'Bransch',
    };
    for (let [g, o] of Object.entries(customersUniqueSorts)) {
        o.sort();
        for (const v of o) {
            groupedOptions.push({ group: g, value: v });
        }
    }

    const filterItems = () => {
        let arr = customers;

        const _f = key =>
            myFilter.reduce(function (filtered, option) {
                if (option?.group === key) filtered.push(option.value);
                return filtered;
            }, []);

        const [spf, tf, df, lf] = ['salesRep', 'team', 'division', 'labels'].map(key => _f(key));
        const name = myFilter.find(o => typeof o === 'string');

        arr = arr.filter(i => {
            //om saknas return false
            if (lf.length > 0 && !lf.includes(i?.labels?.[0]?.name)) return false;
            if (spf.length > 0 && !spf.includes(i?.salesRep)) return false;
            if (tf.length > 0 && !tf.includes(i?.team)) return false;
            if (df.length > 0 && !df.includes(i?.division)) return false;
            if (name) if (!i.name.toLowerCase()?.includes(name?.toLowerCase())) return false;
            return true;
        });
        return arr;
    };

    const filteredItems = filterItems(customers);
    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description} />
            <Grid item xs={12} sx={{ width: "100%" }}>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                fullWidth
                                freeSolo
                                value={myFilter}
                                options={groupedOptions}
                                groupBy={option => option.group}
                                onChange={(event, value) => setMyFilter(value)}
                                getOptionLabel={option => option.value ?? 'Namn: ' + option}
                                renderInput={params => <TextField {...params} label='Filtrera' variant='outlined' />}
                                renderGroup={params => [<li style={{ padding: 16, fontSize: 14, userSelect: "none", color: grey[600] }} key={params.key}>{translateGroupNames[params.group]}</li>, params.children]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomersTable items={filteredItems} loading={customersLoading} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </DefaultPage>
    );
};