import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getBackendSubDomain } from '../functions/subDomain';
import { Alert, Button, Grid, Snackbar, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { DefaultTooltip } from './DefaultComponents';
import { trackGAEvent } from '../functions/googleAnalytics';

export const RefreshButton = ({ collection, collectionKey, id, updateData, lastUpdated, lineItem }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const refreshTimeLimitMinutes = 15;

    const update = async () => {
        if (!loading) {
            setLoading(true);

            trackGAEvent("Button click", text().button, location.pathname + location.search);

            const options = {
                headers: {
                    'content-type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    key: 'supersecretkey',
                    collection: collection,
                    [collectionKey]: id,
                }),
            };

            await fetch(`${getBackendSubDomain('nadir')}/gam/update`, options);

            await updateData();
            setLoading(false);
            setShowAlert(true);
        }
    };

    const text = () => {
        switch (collection) {
            case 'orders':
                return {
                    button: 'Uppdatera ordrar',
                    tooltip: 'Uppdatera kundens ordrar för att hämta den senaste datan från adservern',
                    alert: 'Kundens ordrar har uppdaterats',
                };
            case 'lineitem':
                return {
                    button: 'Uppdatera kampanj',
                    tooltip: 'Uppdatera kampanjen för att hämta den senaste datan från adservern',
                    alert: 'Kampanjen har uppdaterats',
                };
            default:
                return {
                    button: 'Uppdatera',
                    alert: 'Uppdaterat',
                    tooltip: 'Uppdatera',
                };
        }
    };

    function updateAvailable(date, minutes = refreshTimeLimitMinutes) {
        const now = new Date();
        date = new Date(date);
        const timeDiffInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);
        return timeDiffInMinutes > minutes;
    }

    return (
        <>
            <Grid container alignItems='center' spacing={2}>
                <Grid item>
                    <DefaultTooltip title='Datan hämtas automatiskt från vår adserver varje dag vid kl. 05:30, det går även att uppdatera manuellt med hjälp av knappen till höger'>
                        <Typography variant='caption' color='white'>
                            Senast uppdaterad {new Date(lastUpdated)?.toLocaleString('sv-SE')}
                        </Typography>
                    </DefaultTooltip>
                </Grid>
                <Grid item>
                    <DefaultTooltip
                        title={
                            loading
                                ? `Uppdaterar...`
                                : updateAvailable(lastUpdated)
                                    ? text().tooltip
                                    : `Kan endast uppdateras med ${refreshTimeLimitMinutes} minuters mellanrum`
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                size='small'
                                color='accent'
                                disableElevation
                                disabled={!updateAvailable(lastUpdated)}
                                onClick={() => update()}
                                startIcon={
                                    <AutorenewIcon
                                        sx={loading ? {
                                            'animation': 'spin 2s linear infinite',
                                            '@keyframes spin': {
                                                '0%': {
                                                    transform: 'rotate(0deg)',
                                                },
                                                '100%': {
                                                    transform: 'rotate(360deg)',
                                                },
                                            },
                                        } : {}}
                                    />
                                }
                            >
                                {loading ? "Uppdaterar..." : text().button}
                            </Button>
                        </span>
                    </DefaultTooltip>
                </Grid>
            </Grid>
            <Snackbar open={showAlert} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} variant='filled' severity='success' sx={{ width: '100%' }}>
                    {text().alert}
                </Alert>
            </Snackbar>
        </>
    );
};