import React, { useState } from "react";
import { Grid, Box, Checkbox, TextField, FormControlLabel, Divider, Stack } from "@mui/material";
import { DefaultBody, DefaultPage, HeaderWithText } from "../DefaultComponents";
import { screenData } from "./DoohCalcData";
import { flattenedLayout } from "../../config/navigation";

// Sort the screenData by city then by name using Swedish locale
const sortedData = screenData.sort((a, b) => a.city.localeCompare(b.city, "sv") || a.name.localeCompare(b.name, "sv"));

const DoohCalc = () => {
    const info = flattenedLayout.toolDooh;
    const [selectedScreens, setSelectedScreens] = useState([]);
    const [costPerContact, setCostPerContact] = useState("");
    const [sov, setSov] = useState("");
    const [days, setDays] = useState("");

    // Toggle screen selection
    const handleCheckboxChange = screen => {
        setSelectedScreens(prev => (prev.includes(screen) ? prev.filter(s => s !== screen) : [...prev, screen]));
    };

    // Calculate the total contacts for selected screens
    const totalContacts = selectedScreens.reduce((sum, screen) => sum + screen.contacts, 0);

    // Calculate the total contacts for selected screens multiplied by days and share of voice
    const totalContactsMultipliedByDaysAndSov = totalContacts * parseFloat(days || 0) * (parseFloat(sov || 0) / 100);
    const totalContactsMultipliedByDaysAndSovRounded = Math.round(totalContacts * parseFloat(days || 0) * (parseFloat(sov || 0) / 100));

    // Calculate the brutto total cost based on the user-entered "Pris per kontakt"
    const bruttoTotalCost = costPerContact && sov && days ? Math.round(totalContactsMultipliedByDaysAndSov * parseFloat(costPerContact)) : 0;

    // Calculate the netto total cost using the sum of the selected screens' prices
    const selectedScreensTotalPrice = selectedScreens.reduce((sum, screen) => sum + (screen.price || 0), 0);
    const nettoTotalCost = selectedScreensTotalPrice
        ? Math.round(totalContactsMultipliedByDaysAndSov * (selectedScreensTotalPrice / selectedScreens.length))
        : 0;

    // Calculate the discount percentage based on the difference between brutto and netto prices
    const discountPercentage = bruttoTotalCost && nettoTotalCost ? (((nettoTotalCost - bruttoTotalCost) / nettoTotalCost) * 100).toFixed(1) : 0;

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description} />
            <DefaultBody>
                <Grid item container xs={12} md={8}>
                    {/* First Column - First Half of the Screen Data */}
                    <Grid item xs={12} sm={6}>
                        {sortedData.slice(0, Math.ceil(sortedData.length / 2)).map(screen => (
                            <Box key={screen.name} display="flex" alignItems="center" paddingY={0.5}>
                                <FormControlLabel
                                    control={<Checkbox size="small" checked={selectedScreens.includes(screen)} onChange={() => handleCheckboxChange(screen)} />}
                                    label={`${screen.city} - ${screen.name}`}
                                    sx={{ flex: 1, marginLeft: "10px", fontSize: "0.75rem" }}
                                />
                            </Box>
                        ))}
                    </Grid>

                    {/* Second Column - Second Half of the Screen Data */}
                    <Grid item xs={12} sm={6}>
                        {sortedData.slice(Math.ceil(sortedData.length / 2)).map(screen => (
                            <Box key={screen.name} display="flex" alignItems="center" paddingY={0.5}>
                                <FormControlLabel
                                    control={<Checkbox size="small" checked={selectedScreens.includes(screen)} onChange={() => handleCheckboxChange(screen)} />}
                                    label={`${screen.city} - ${screen.name}`}
                                    sx={{ flex: 1, marginLeft: "10px", fontSize: "0.75rem" }}
                                />
                            </Box>
                        ))}
                    </Grid>
                </Grid>

                {/* Third Column - Calculator */}
                <Grid item xs={12} md={4} alignContent="center">
                    <Stack spacing={2}>
                        <TextField label="Antal dagar" type="number" variant="outlined" value={days} onChange={e => setDays(e.target.value)} />
                        <TextField label="SOV (%)" type="number" variant="outlined" value={sov} onChange={e => setSov(e.target.value)} />
                        <TextField
                            label="Pris per kontakt"
                            type="number"
                            variant="outlined"
                            value={costPerContact}
                            onChange={e => setCostPerContact(e.target.value)}
                        />
                        <Divider sx={{ my: 2 }} />
                        <TextField
                            label="Totalt antal kontakter"
                            type="text"
                            variant="outlined"
                            value={totalContactsMultipliedByDaysAndSovRounded.toLocaleString("sv-SE")}
                            disabled
                        />
                        <TextField label="Bruttopris" type="text" variant="outlined" value={nettoTotalCost.toLocaleString("sv-SE")} disabled />
                        <TextField label="Nettopris" type="text" variant="outlined" value={bruttoTotalCost.toLocaleString("sv-SE")} disabled />
                        <TextField label="Rabatt (%)" type="text" variant="outlined" value={discountPercentage} disabled />
                    </Stack>
                </Grid>
            </DefaultBody>
        </DefaultPage>
    );
};

export default DoohCalc;
