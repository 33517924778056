import { useEffect, useState } from 'react';
import { getBackendSubDomain } from '../../subDomain';

export const useDoohForecast = request => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const filter = arr => arr?.filter(i => !['borttagen', 'test'].some(word => i.name.toLowerCase().includes(word)));

    const _fetch = async () => {
        try {
            //week == 2 month == 1
            const payload = {
                startdate: request?.settings?.dates?.from?.toISOString(),
                enddate: request?.settings?.dates?.to?.toISOString(),
                type: request?.settings?.type
            }

            if (request?.settings?.ids) payload.ids = request?.settings?.ids

            const response = await fetch(`${getBackendSubDomain('krv')}/dc/forecast`, {
                headers: { 'content-type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(payload)
            });

            const res = await response.json();
            res.occupancyData = filter(res.occupancyData);

            return res;
        } catch (err) {
            console.warn(err);
            return null;
        }
    };

    const resetData = () => setData(null);

    const loadData = async () => {
        setLoading(true);

        const resReg = await _fetch();

        setData(resReg)
        setLoading(false);
    };

    useEffect(() => {
        //Check ok values
        if (request) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    return [data, loading, resetData];
};
