import { grey, orange } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import { DefaultTooltip } from './DefaultComponents';

export const CustomInfoIcon = (props) => {
    const { text, color, sx, isWarning } = props;

    return (
        <DefaultTooltip title={text}>
            {isWarning ? (
                <OfflineBoltIcon 
                    sx={{ ...sx, color: color ?? grey[500] }} 
                />
            ) : (
                <InfoIcon 
                    sx={{ ...sx, color: color ?? grey[500] }} 
                />
            )}
        </DefaultTooltip>
    );
};
