import { useEffect, useState } from 'react';
import { getBackendSubDomain } from '../subDomain';

export const useCheckinternalUser = () => {
    const [loading, setLoading] = useState(false);
    const [isInternal, setIsInternal] = useState(true);

    useEffect(() => {
        const domain = getBackendSubDomain('krv');
        if (domain === 'http://localhost:5000' || process.env?.REACT_APP_DEPLOYMENT === 'Local') setIsInternal(true);
        else {
            setLoading(true);
            fetch(`${domain}/ip`)
                .then(res => res.json())
                .then(res => setIsInternal(res.internalUser)) //res.internalUser TODO
                .catch(err => {
                    setIsInternal(false);
                    console.warn(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    return [isInternal, loading];
};
