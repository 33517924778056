import { useState, useEffect } from 'react';

export const useCustomSettings = () => {
    const lsKey = 'rapportverktyg-settings';
    const [customSettings, setCustomSettings] = useState({});

    useEffect(() => {
        const ls = localStorage.getItem(lsKey);
        if (ls) setCustomSettings(JSON.parse(ls));
    }, []);

    const handleSettings = (key, value) => {
        setCustomSettings({
            ...customSettings,
            [key]: value,
        });
    };

    const toggleItemInLocalStorage = (arrayKey, value) => {
        let array = customSettings[arrayKey] ?? [];
        if (arrayKey === 'favoriteAdvertisers') {
            const isFavorite = array.find(a => a.id === value.id);
            if (isFavorite) {
                array = array.filter(b => b.id !== value.id);
                handleSettings(arrayKey, array);
            } else handleSettings(arrayKey, [...array, value]);
        } else {
            array = array.filter(v => v.id !== value.id);
            array.unshift(value);
            if (array.length > 5) array.pop();
            handleSettings(arrayKey, array);
        }
    };

    //TODO maybe add debounce
    useEffect(() => {
        localStorage.setItem(lsKey, JSON.stringify(customSettings));
    }, [customSettings]);

    return [customSettings, toggleItemInLocalStorage];
};
