import { Grid, Typography } from "@mui/material";
import React from "react";
import { PaperBody } from "../../DefaultComponents";
import { grey } from '@mui/material/colors';

export const MaterialGrid = ({ item }) => {
    if (item?.info?.files) return item?.info?.files?.map((file) => <Material key={file?.azureUrl} url={file?.azureUrl} />)
    else return <></>
}

const Material = ({ url }) => {
    const videoFormat = '.mp4';

    return (
        <Grid container item xs>
            <PaperBody>
                <Typography variant='h5' sx={{ color: grey[600] }}>
                    Skyltmaterial
                </Typography>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        {url?.includes(videoFormat) ? (
                            <MaterialVideo url={url} />
                        ) : (
                            <MaterialImage url={url} />
                        )}
                    </Grid>
                </Grid>
            </PaperBody>
        </Grid>
    );
};

const MaterialImage = ({ url }) => (
    <img
        style={{
            margin: "0 auto",
            maxWidth: '100%',
            height: 'auto',
            display: 'block', // This ensures that the image doesn't affect the layout
        }}
        alt=""
        src={url}
    />
);

const MaterialVideo = ({ url }) => (
    <video
        style={{
            margin: "0 auto",
            maxWidth: '100%',
            height: 'auto',
            display: 'block', // This ensures that the video doesn't affect the layout
        }}
        controls
    >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
);