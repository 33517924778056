import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { GenerateExternalLink } from '../GenerateExternalLink';
import { RefreshButton } from '../../RefreshButton';
import { useMobile } from '../../../material/theme';

export const HeaderButtons = ({ lineItem, updateLineitem }) => {
    const isMobile = useMobile();

    return (
        <Grid item container xs={12} md={7} spacing={2} justifyContent={isMobile ? 'space-between' : 'flex-end'} alignItems='center'>
            <Grid item>
                <RefreshButton
                    collection='lineitem'
                    collectionKey='lineitemId'
                    id={lineItem?.id}
                    updateData={updateLineitem}
                    lastUpdated={lineItem?.timestamp}
                    lineItem={lineItem}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent='flex-end'>
                    <GenerateExternalLink stringToEncrypt={lineItem?.externalId ?? lineItem?.id} />
                </Grid>
            </Grid>
        </Grid>
    );
};
