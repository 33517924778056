import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import { formatDate } from '../../functions/misc';
import { DefaultTooltip } from '../DefaultComponents';
import { useXsSize } from '../../material/theme';
import { trackGAEvent } from '../../functions/googleAnalytics';

export const ViewCombinedReports = ({ reports }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isXs = useXsSize();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item>
            <DefaultTooltip title={reports.length === 0 ? 'Kunden har inga sparade kombinerade rapporter' : `Visa sparade kombinerade rapporter för kunden`}>
                <span>
                    <Button
                        color='secondary'
                        size='small'
                        disableElevation
                        variant='outlined'
                        disabled={reports.length === 0}
                        onClick={() => {
                            trackGAEvent("Button click", "Visa kombinerade rapporter", location.pathname + location.search);
                            handleOpen();
                        }}
                    >
                        Visa kombinerade rapporter
                    </Button>
                </span>
            </DefaultTooltip>
            <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>Kombinerade rapporter</Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={1} py={1}>
                        {!isXs && (
                            <Grid item container mb={1}>
                                <Grid item xs={12} sm={5}>
                                    <Typography>
                                        Namn
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography>
                                        Skapad
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography>
                                        Antal kampanjer
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {reports?.sort((a, b) => b.createdAt["$date"] - a.createdAt["$date"]).map((report, i) => {
                            const reportId = report?._id?.["$oid"]
                            return (
                                <React.Fragment key={reportId}>
                                    <Grid
                                        item
                                        container
                                        onClick={() => navigate(`/kampanjer/kombinera/${reportId}`)}
                                        sx={{ cursor: 'pointer', mb: i !== reports?.length - 1 ? 1 : 0 }}
                                    >
                                        <Grid item xs={12} sm={5}>
                                            <Typography noWrap>
                                                {report.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs sm={4}>
                                            <Typography noWrap>
                                                {formatDate(report?.createdAt["$date"], true)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs sm={3}>
                                            <Typography>
                                                {report?.lineitemIds?.length} {isXs && "kampanjer"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {i !== reports?.length - 1 && <Divider sx={{ mx: 1 }} />}
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>

        </Grid>
    );
};