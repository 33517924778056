import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Grid, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDate } from '../../functions/misc';
import { getBackendSubDomain } from "../../functions/subDomain";
import { DefaultBody, DefaultPage, HeaderWithText } from "../DefaultComponents";
import { SkeletonRuns } from "../Skeletons";
import { useMobile } from '../../material/theme';

export const RunsDashboard = () => {
    const [runs, setRuns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [failedRuns, setFailedRuns] = useState([]);

    useEffect(() => {
        setLoading(true);
        async function getData() {
            const data = await fetch(`${getBackendSubDomain('nadir')}/krv/admin/runs`)
                .then(e => e.json())
            const result = data.reduce(function (r, a) {
                r[a.process] = r[a.process] || [];
                r[a.process].push(a);
                return r;
            }, Object.create(null));
            setRuns(result);

            const oneDayAgo = new Date().getTime() - (24 * 60 * 60 * 1000);
            setFailedRuns(data?.filter(run => run?.timestamp["$date"] > oneDayAgo && !run?.success).map(run => run?.process));

            setLoading(false);
        }
        runs.length === 0 && getData();
    }, []);

    if (loading) return <SkeletonRuns />
    else return (
        <DefaultPage>
            <HeaderWithText text1='Hämtningsöversikt' text2='Resultat av dagliga hämtningar av data från adservers' />
            <DefaultBody>
                {failedRuns.length > 0 && (
                    <Grid item xs={12}>
                        <Alert severity='error' variant='filled'>
                            <AlertTitle>
                                {failedRuns.length > 1 ?
                                    `${failedRuns.length} misslyckade hämtningar` : '1 misslyckad hämtning'
                                } under det senaste dygnet
                            </AlertTitle>
                            <Typography variant='body2'>{failedRuns.sort().join(', ')}</Typography>
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {Object.entries(runs).sort().map(([id, array]) => {
                        const sortedRuns = array.sort((a, b) => b.timestamp["$date"] - a.timestamp["$date"])
                        return (
                            <Accordion key={id} elevation={0} disableGutters>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Run data={sortedRuns[0]} />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1}>
                                        {sortedRuns.map(run => <Run key={run?.timestamp["$date"]} data={run} />)}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Grid>
            </DefaultBody>
        </DefaultPage>
    )
}

const Run = ({ data }) => {
    const isMobile = useMobile();
    const statusIcon = (
        <Grid item container xs={2} sm={1} justifyContent='centerz' alignItems='center'>
            {data?.success ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
        </Grid>
    );
    return (
        <Grid item container direction='row' spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} sm={6} md={4}>
                <Typography>{data?.process}</Typography>
            </Grid>
            <Grid item xs={10} sm={4} md={2.5}>
                <Typography color='text.secondary'>{formatDate(data?.timestamp["$date"], true)}</Typography>
            </Grid>
            {isMobile && statusIcon}
            <Grid item xs={6} md={2.5}>
                <Typography color='text.secondary'>{data?.time_elapsed?.toFixed(2)} sekunder</Typography>
            </Grid>
            <Grid item xs={6} md={2}>
                <Typography color='text.secondary'>{data?.inserted_count?.toLocaleString('sv-SE') ?? 0} objekt</Typography>
            </Grid>
            {!isMobile && statusIcon}
        </Grid>
    )
}