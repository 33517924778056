import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getBackendSubDomain, getFrontendSubDomain } from '../../functions/subDomain';
import { DefaultTooltip } from '../DefaultComponents';
import { trackGAEvent } from '../../functions/googleAnalytics';

export const GenerateExternalLink = ({ stringToEncrypt, combinedReport = false }) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [copyIcon, setCopyIcon] = useState(false);
    const [link, setLink] = useState(null);
    const timer = useRef();

    const buttonSx = {
        ...(success &&
            !copyIcon && {
            'bgcolor': green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = () => {
        if (!loading && !success) {
            setSuccess(false);
            setLoading(true);

            trackGAEvent("Button click", "Kopiera kundlänk", location.pathname + location.search)

            let headers = new Headers();
            headers.set('content-type', 'application/json');

            const options = {
                headers: headers,
                method: 'GET',
            };

            fetch(`${getBackendSubDomain('krv')}/encrypt/${stringToEncrypt}`, options)
                .then(res => res.text())
                .then(encryptedString => {
                    if (encryptedString) {
                        let url = `https://${getFrontendSubDomain()}/${combinedReport ? 'extern/kombinera' : 'extern'}/${encryptedString}`;
                        if (url.includes('localhost')) url = url.replace("https", "http");

                        navigator.clipboard.writeText(url);
                        setLink(url);
                        setLoading(false);
                        setSuccess(true);

                        timer.current = window.setTimeout(() => {
                            setCopyIcon(true);
                        }, 5000);
                    }
                })
                .catch(err => {
                    console.warn(err);
                    setLoading(false);
                });
        } else if (!loading && success) {
            navigator.clipboard.writeText(link);
        }
    };

    return (
        <DefaultTooltip title='För att kunden ska kunna se rapporten måste du skicka en specifik länk, den kan du kopiera via den här knappen'>
            <Button
                variant="contained"
                size='small'
                color='accent'
                sx={buttonSx}
                disableElevation
                onClick={handleButtonClick}
                startIcon={
                    success ? (
                        copyIcon ? (
                            <ContentCopyIcon />
                        ) : (
                            <CheckIcon />
                        )
                    ) : (
                        <ContentCopyIcon />
                    )
                }
            >
                Kopiera kundlänk
            </Button>
        </DefaultTooltip>
    );
}
