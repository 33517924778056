import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBackendSubDomain } from '../../functions/subDomain';
import { Grid, Paper, TextField, Typography, Card, CardContent, Switch, FormControlLabel } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { doohClickFilterNames } from '../../functions/misc';
import { DefaultPage, HeaderWithText } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import LeafletMap from './LeafComponent';
import { SkeletonMediaCard } from '../Skeletons';

export const DoohScreens = props => {
    const info = flattenedLayout.doohInfo;
    const [screens, loading] = useGetScreens();
    const [search, setSearch] = useState('');
    const [showMap, setShowMap] = useState(false);

    const getMarkers = () => {
        //add local stored coordinates
        let markers = screens?.map(screen => {
            return {
                id: screen?.screenTenant?.id?.toString(),
                name: screen?.screenName,
                longitude: screen?.extra?.screen?.longitude ?? 0,
                latitude: screen?.extra?.screen?.latitude ?? 0
            }
        });
        //remove markers with coordinates 0,0
        markers = markers?.filter(marker => marker?.longitude !== 0 || marker?.latitude !== 0);
        //filter search
        if (search?.length > 0) markers = markers.filter(marker => marker.name.toLowerCase().includes(search.toLowerCase()));
        return markers;
    };

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <Grid item xs>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item xs>
                            <TextField
                                fullWidth
                                id='filter-screens'
                                label='Filtrera'
                                autoComplete='off'
                                type='search'
                                variant='outlined'
                                disabled={loading}
                                onChange={e => setSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <FormControlLabel
                                control={
                                    <Switch color="accent" checked={showMap} disabled={loading} onChange={() => setShowMap(!showMap)} inputProps={{ 'aria-label': 'controlled' }} />
                                }
                                label="Visa karta"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {/* <DoohContactDraft></DoohContactDraft> */}

            <Grid item xs>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='row' justifyContent='center' spacing={2}>
                            {showMap && (
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={0} sx={{ p: 2 }}>
                                        <LeafletMap markers={getMarkers()} />
                                    </Paper>
                                </Grid>
                            )}
                            <Grid item xs={12} md={showMap ? 6 : 12}>
                                <Grid item container justifyContent='start' spacing={2}>
                                    {loading
                                        ? [...Array(9)].map((e, i) => (
                                            <Grid item xs={12} sm={6} md={4} key={i}>
                                                <SkeletonMediaCard />
                                            </Grid>
                                        ))
                                        : screens?.map(item => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={showMap ? 12 : 6}
                                                md={showMap ? 12 : 4}
                                                xl={showMap ? 6 : 4}
                                                key={`${item?.screenTenant?.id}-${item?.screenTenant?.screenId}`}
                                                sx={{
                                                    display: item.screenName.toLowerCase().includes(search.toLowerCase()) ? 'block' : 'none',
                                                }}
                                            >
                                                <MediaCard item={item} />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DefaultPage>
    );
};

export const MediaCard = props => {
    const item = props?.item;
    const navigate = useNavigate();
    return (
        <Card
            elevation={0}
            sx={{ height: '100%', cursor: 'pointer' }}
            onClick={() =>
                navigate(`/dooh/skylt/${item?.screenTenant?.id}`, {
                    state: {
                        screen: item,
                    },
                })
            }
        >
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item>
                        {item?.screenTenant?.azureThumbnailUrl ? (
                            <img alt='Bild på skylt' src={item?.screenTenant?.azureThumbnailUrl} width={90} height={90} style={{ borderRadius: '4px' }} />
                        ) : (
                            <div style={{ height: 90 }}>
                                <ImageNotSupportedIcon sx={{ fontSize: 75, color: 'rgba(0, 0, 0, 0.2)' }} />
                            </div>
                        )}
                    </Grid>
                    <Grid item container direction='column'>
                        <Grid item>
                            <Typography gutterBottom variant='h5' component='div'>
                                {item?.screenName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2' color='text.secondary'>
                                {item?.screenTenant?.longDescription}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const useGetScreens = () => {
    const [screens, setScreens] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!screens) {
            const dcData = sessionStorage.getItem('dc');
            if (dcData) {
                setScreens(JSON.parse(dcData));
            } else {
                setLoading(true);
                fetch(`${getBackendSubDomain('nadir')}/doohclick/screens`)
                    .then(res => res.json())
                    .then(res => {
                        const filteredScreens = customFilter(res.results);
                        setScreens(filteredScreens);
                        sessionStorage.setItem('dc', JSON.stringify(filteredScreens));
                        setLoading(false);
                    })
                    .catch(err => {
                        console.warn(err);
                        setLoading(false);
                    });
            }
        }
    }, []);
    return [screens, loading];
};

export const customFilter = arr => arr?.filter(i => !doohClickFilterNames.some(word => i.screenName.toLowerCase().includes(word)));
