import { Grid, Paper, Typography, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomInfoIcon } from '../InfoIcon';

export const AdvertisersList = props => {
    const { arr, title, titleIcon, infoText } = props;
    const navigate = useNavigate();

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Grid container justifyContent="space-between">
                <Grid item xs container spacing={1}>
                    <Grid item>
                        <Typography variant='subtitle1' gutterBottom>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>{titleIcon}</Grid>
                </Grid>
                <Grid item>
                    <CustomInfoIcon text={infoText} />
                </Grid>
            </Grid>
            <List component='ul' disablePadding dense sx={{ overflow: 'auto', maxHeight: '310px' }}>
                {arr?.map(advertiser => {
                    return (
                        <ListItem disablePadding key={advertiser?.id}>
                            <ListItemButton onClick={() => navigate(`/kund/${advertiser.id}`)}>

                                <ListItemText
                                    primary={<Typography variant='subtitle2'>{advertiser.name ?? advertiser.id}</Typography>}
                                    secondary={<Typography variant='body2' color='text.secondary'>
                                        {advertiser.id}
                                    </Typography>} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            {!arr?.length && (
                <Typography variant='subtitle2' color='text.secondary'>
                    Du har inga {title.toLowerCase()}
                </Typography>
            )}
        </Paper>
    );
};
