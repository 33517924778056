import { Typography, Grid } from '@mui/material';
import { PaperBody } from '../../DefaultComponents';
import { blueGrey } from '@mui/material/colors';
import { HeaderButtons } from './HeaderButtons';

export const TitleGrid = ({ item, isInternal }) => {
    return (
        <Grid container item xs>
            <PaperBody paperSx={{ background: '#0E3C64' }}>
                <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                    <Grid item container xs={12} md={5} direction='column'>
                        <Grid item xs>
                            <Typography variant='h5' color='white'>
                                {item?.name ?? item?.id ?? 'Kampanjnamn saknas'}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant='p' sx={{ color: blueGrey[300] }}>
                                {item?.associateName?.split(' - ')?.[1]}
                            </Typography>
                        </Grid>
                    </Grid>
                    {isInternal && <HeaderButtons item={item} />}
                </Grid>
            </PaperBody>
        </Grid>
    )
}