import { Grid, Typography, Paper, Divider, Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import { grey } from '@mui/material/colors';
import { UserContext } from '../App';
import { useNavigate } from 'react-router-dom';

export const NotFound = props => {
    const user = useContext(UserContext);
    const isInternal = user?.isInternal ?? false;
    const navigate = useNavigate();

    return (
        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={2}>
            <Grid item>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item container justifyContent='space-evenly' sx={{ textAlign: 'center' }}>
                            <Grid item xs={12} md={5}>
                                <Typography variant='h1' sx={{ fontSize: 120, color: '#0E3C64' }}>
                                    404
                                </Typography>
                            </Grid>
                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                                <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                            </Box>
                            <Grid item container xs={12} md={5} alignItems='center' justifyContent='space-evenly'>
                                <Grid item>
                                    <Typography variant='h5' sx={{ textTransform: 'uppercase', color: grey[700] }}>
                                        Sidan kunde inte hittas
                                    </Typography>
                                    {!isInternal && (
                                        <Typography variant='body2' sx={{ mt: 1, color: grey[700] }}>
                                            Tänk på att du måste vara ansluten till VPN
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {isInternal && (
                            <Grid item>
                                <Button variant='contained' color='secondary' disableElevation onClick={() => navigate('/')}>
                                    Gå till startsidan
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
