const urls = {
    krv: {
        prod: 'https://krv-be.azurewebsites.net',
        // local: 'https://krv-be.azurewebsites.net',
        local: 'http://localhost:5000',
    },
    nadir: {
        prod: 'https://nadir.ntm.digital',
        // local: 'https://nadir.ntm.digital',
        local: 'http://localhost:7777',
    },
};
const type = process.env.REACT_APP_STAGE;

export const getBackendSubDomain = api => {
    if (type === 'local') return urls[api].local;
    else return urls[api].prod;
};

export const getFrontendSubDomain = () => {
    // const url = window.location.href
    // const urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(url);
    return /:\/\/([^\/]+)/.exec(window.location.href)[1];
};
