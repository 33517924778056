import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../NotFound'
import { LocalStorageContext, UserContext } from '../../App';
import { useLineitem } from '../../functions/hooks/lineitemHandler';
import { toUpperIfLongString } from './display/displayFunction';
import { Display } from './display/Display';
import { Dooh } from './dooh/Dooh';
import { SkeletonLineitem } from '../Skeletons';

export const Lineitem = () => {
    const id = toUpperIfLongString(useParams().id)

    const [forceUpdate, setForceUpdate] = useState(0);

    const user = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const { localStorage, setLocalStorage } = { ...useContext(LocalStorageContext) };
    const { item, loading } = useLineitem(id, forceUpdate);
    const isInternal = user?.isInternal ?? false;

    useEffect(() => {
        if (item?.type === 'display') {
            setLocalStorage('recentCampaigns', {
                id: item.id.toString(),
                externalId: item.externalId,
                name: item.order.name.split('_0')[0] ?? item.order.name ?? item.order.id,
                customer: item.advertiser.name,
                type: item?.type
            });
        }
        else if (item?.type === 'dooh') {
            setLocalStorage('recentCampaigns', {
                id: item?.id?.toString(),
                externalId: id,
                name: id,
                customer: item?.associateName?.split(' - ')?.[1],
                type: item?.type
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const updateLineitem = () => {
        setForceUpdate(forceUpdate+1);
    }

    if (loading) return <SkeletonLineitem />;
    else if (item?.type === 'display') return <Display item={item} updateLineitem={updateLineitem} isInternal={isInternal} />
    else if (item?.type === 'dooh') return <Dooh item={item} isInternal={isInternal} />
    else return <NotFound isInternal={isInternal} />;
};