import './styles/style.css';
import React, { createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Grid, ThemeProvider } from '@mui/material';
import { Navbar } from './components/navigation/Navbar';
import { theme } from './material/theme';
import { useCustomSettings } from './functions/hooks/customSettingsHandler';
import { useCheckinternalUser } from './functions/hooks/internalUserHandler';
import { getRoutes } from './config/routes';
import { useCustomers } from './functions/hooks/customersHandler';
import { useLineitemsX } from './functions/hooks/lineitemsHandler';
import { initializeGA, trackGAPageView } from './functions/googleAnalytics';

export const CustomersContext = createContext(null);
export const LineitemsContext = createContext(null);
export const LocalStorageContext = createContext(null);
export const UserContext = createContext(null);

if (process.env?.REACT_APP_DEPLOYMENT !== 'Local') initializeGA();

const TrackPageViews = () => {
    const location = useLocation();

    useEffect(() => {
        trackGAPageView(location.pathname + location.search);
    }, [location]);

    return null;
};

export default function App() {
    const [localStorage, setLocalStorage] = useCustomSettings();
    const [isInternal, loading] = useCheckinternalUser();
    const [customerFetch, triggerCustomersFetch] = useState(false);
    const customersHook = useCustomers(customerFetch);
    const [lineitemsFetch, triggerLineitemsFetch] = useState(false);
    const lineitemsHook = useLineitemsX(lineitemsFetch);
    const routes = getRoutes(isInternal);

    return (
        <UserContext.Provider value={{ isInternal: isInternal }}>
            {!loading && (
                <LocalStorageContext.Provider value={{ localStorage, setLocalStorage }}>
                    <CustomersContext.Provider value={{ data: customersHook, triggerCustomersFetch }}>
                        <LineitemsContext.Provider value={{ data: lineitemsHook, triggerLineitemsFetch }}>
                            <Router>
                                <ThemeProvider theme={theme}>
                                    <Navbar />
                                    <Grid container spacing={0} justifyContent="center" sx={{ p: 2 }}>
                                        <Grid item xs={12} lg={10} xl={8}>
                                            <Routes>
                                                {routes.map((route) => (
                                                    <Route exact key={route.path} path={route.path} element={route.component} />
                                                ))}
                                            </Routes>
                                            {process.env?.REACT_APP_DEPLOYMENT !== 'Local' && <TrackPageViews />} {/* Invoke the tracking component if not running locally */}
                                        </Grid>
                                    </Grid>
                                </ThemeProvider>
                            </Router>
                        </LineitemsContext.Provider>
                    </CustomersContext.Provider>
                </LocalStorageContext.Provider>
            )}
        </UserContext.Provider>
    );
}
