import { useState, useEffect } from 'react';
import { getBackendSubDomain } from '../subDomain';

export const useAdvertiserHandler = (id, forceUpdate) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        !data && setLoading(true);
        const options = {
            headers: {
                'content-type': 'application/json',
            },
            method: 'GET',
        };
        fetch(`${getBackendSubDomain('nadir')}/gam/orders/${id}`, options)
            .then(res => res.json())
            .then(data => {
                data.orders = data?.orders?.filter(order => order.lineitems.length > 0);
                setData(data);
            })
            .catch(err => {
                console.warn(err);
            })
            .finally(() => setLoading(false));
    }, [id, forceUpdate]);

    return [data, loading];
};