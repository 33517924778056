import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { DefaultCard, PaperBody } from '../../DefaultComponents';

export const TargetingGrid = ({ item }) => {
    const screens = item?.delivery?.screens;

    return <Grid container item xs>
        <PaperBody>
            <Typography variant='h5' sx={{ color: grey[600] }}>
                Skärmar
            </Typography>
            <Grid item container spacing={2} >
                {screens?.map((screen, index) => {
                    const contacts = Math.round(screen?.deliveredContacts)?.toLocaleString('sv-SE') ?? 0;
                    const views = Math.round(screen?.deliveredNumberOfViews)?.toLocaleString('sv-SE') ?? 0;
                    const sov = screen?.deliveredShareOfVoice?.toFixed(2)?.toLocaleString('sv-SE') ?? 0;

                    return (
                        <Grid key={screen?.screenName} item xs={12} sm={6} lg={6}>
                            <DefaultCard title={screen?.screenName}>
                                <TripleValueCard one={{ key: 'Kontakter', value: contacts }} two={{ key: 'Visningar', value: views }} three={{ key: 'SOV', value: sov + "%" }}></TripleValueCard>
                            </DefaultCard>
                        </Grid>
                    )
                })}
            </Grid>
        </PaperBody>
    </Grid>
}

const TripleValueCard = ({ one, two, three }) => {
    return (
        <Grid container spacing={2} justifyContent='space-evenly'>
            <Grid item xs={12} sm={6} lg={3}>
                <SmallCard title={one.key}>
                    <span><strong>{one.value}</strong></span>
                </SmallCard>
            </Grid>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
            </Box>
            <Grid item xs={12} sm={6} lg={3}>
                <SmallCard title={two.key}>
                    <span><strong>{two.value} </strong></span>
                </SmallCard>
            </Grid>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
            </Box>
            <Grid item xs={12} sm={6} lg={3}>
                <SmallCard title={three.key}>
                    <span><strong>{three.value}</strong></span>
                </SmallCard>
            </Grid>
        </Grid>
    )
}

const SmallCard = props => {
    return (
        <Card variant='outlined' sx={{ textAlign: 'center', border: 'none' }}>
            {props?.title && <CardHeader title={props.title} titleTypographyProps={{ variant: 'p' }} sx={{ textTransform: 'uppercase', color: grey[600] }} />}
            <CardContent>{props?.children}</CardContent>
        </Card>
    );
};
