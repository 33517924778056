import React, { useContext, useEffect, useState } from 'react';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Autocomplete, Divider, FormControl, FormControlLabel, FormGroup, Grid, List, ListItemText, Paper, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router';

import { CustomersContext, LineitemsContext, LocalStorageContext } from '../../App';
import { flattenedLayout } from '../../config/navigation';
import { customerIsFavorite } from '../../functions/hooks/lineitemsHandler';
import { formatDate } from '../../functions/misc';
import { DefaultPage, DefaultTooltip, HeaderWithText } from '../DefaultComponents';
import { CustomInfoIcon } from '../InfoIcon';
import { LineItemsTable } from './LineItemsTable';
import { DeliveryProgressBar } from '../DeliveryIndicators';
import { useMobile, useXsSize } from '../../material/theme';
import { formatFilterOptions } from '../../functions/placements';

const getSettings = () => {
    const settings = JSON.parse(localStorage.getItem('lineitems-table-filters'));
    const oldFilter = JSON.parse(localStorage.getItem('li-customers-table-filters')); // Uses old filter to migrate existing filters to new local storage item
    if (settings) {
        if (settings.filter) return settings;
        else return { ...settings, filter: oldFilter ?? [] }
    }
    else return {
        onlyFavorite: false,
        onlyMissingCreatives: false,
        status: 'completed',
        filter: oldFilter ?? [],
        formats: []
    };
};

export const LineItems = () => {
    const info = flattenedLayout.campaigns;
    const customersContext = useContext(CustomersContext);
    const { customers, customersLoading, customersUniqueSorts } = customersContext.data;
    const { localStorage: lsContext, setLocalStorage } = { ...useContext(LocalStorageContext) };
    const lineitemsContext = useContext(LineitemsContext);
    const { items, loading } = lineitemsContext.data;
    const [tableSettings, setTableSettings] = useState(getSettings());
    const isXs = useXsSize();

    useEffect(() => {
        if (!items) lineitemsContext.triggerLineitemsFetch(true);
        if (customers.length === 0) customersContext.triggerCustomersFetch(true);
    }, []);

    useEffect(() => {
        localStorage.setItem('lineitems-table-filters', JSON.stringify(tableSettings));
        if (localStorage.getItem('li-customers-table-filters')) localStorage.removeItem('li-customers-table-filters') // Delete old filter from local storage if it still exists
    }, [tableSettings]);

    const handleTableSettings = obj => {
        setTableSettings({
            ...tableSettings,
            ...obj,
        });
    };

    const filterItemsAc = (arr) => {
        const filter = tableSettings.filter;
        const _f = key =>
            filter.reduce(function (filtered, option) {
                if (option?.group === key) filtered.push(option.value);
                return filtered;
            }, []);

        const [spf, tf, df, lf] = ['salesRep', 'team', 'division', 'labels'].map(key => _f(key));
        const name = filter.find(o => typeof o === 'string');

        arr = arr?.filter(i => {
            //om saknas return false
            if (lf.length > 0 && !lf.includes(i?.advertiser?.labels?.[0]?.name)) return false;
            if (spf.length > 0 && !spf.includes(i?.advertiser?.salesforce?.salesRep)) return false;
            if (tf.length > 0 && !tf.includes(i?.advertiser?.salesforce?.team)) return false;
            if (df.length > 0 && !df.includes(i?.advertiser?.salesforce?.division)) return false;
            if (name) if (!i.advertiser?.name.toLowerCase()?.includes(name?.toLowerCase())) return false;
            return true;
        });
        return arr;
    };

    const filterItems = () => {
        let filtered = items?.[tableSettings?.status];
        if (tableSettings?.onlyFavorite) filtered = filtered?.filter(lineitem => customerIsFavorite(lsContext?.favoriteAdvertisers ?? [], lineitem.advertiser.id));
        if (tableSettings?.onlyMissingCreatives) filtered = filtered?.filter(lineitem => lineitem?.creatives?.length === 0);
        if (tableSettings?.formats?.length > 0) filtered = filtered?.filter(lineitem => lineitem?.placements?.map(p => p?.split(/ (.*)/s)[1]).some(p => tableSettings.formats.includes(p)));
        filtered = filterItemsAc(filtered)
        return filtered;
    }

    let groupedOptions = [];
    const translateGroupNames = {
        team: 'Team',
        division: 'Division',
        salesRep: 'Kundansvarig',
        labels: 'Bransch',
    };
    for (let [g, o] of Object.entries(customersUniqueSorts)) {
        o.sort();
        for (const v of o) {
            if (v) groupedOptions.push({ group: g, value: v });
        }
    }

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description} />
            <Grid item xs={12} sx={{ width: "100%" }}>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Grid container item alignItems='center' spacing={2} mb={1}>
                        <Grid item xs={12} md={8}>
                            <Autocomplete
                                multiple
                                fullWidth
                                freeSolo
                                limitTags={isXs ? 2 : 3}
                                value={tableSettings.filter}
                                options={groupedOptions}
                                groupBy={option => option.group}
                                onChange={(event, value) => handleTableSettings({ filter: value })}
                                getOptionLabel={option => option.value}
                                renderInput={params => <TextField {...params} label='Filtrera' variant='outlined' />}
                                renderGroup={params => [<li style={{ padding: 16, fontSize: 14, userSelect: "none", color: grey[600] }} key={params.key}>{translateGroupNames[params.group]}</li>, params.children]}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                multiple
                                fullWidth
                                limitTags={1}
                                value={tableSettings.formats}
                                options={formatFilterOptions}
                                onChange={(event, value) => handleTableSettings({ formats: value })}
                                renderInput={params => <TextField {...params} label='Format' variant='outlined' />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent='space-between' alignItems='center' mb={1}>
                        <Grid item container xs alignItems='center' spacing={2}>
                            <Grid item>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="accent"
                                                checked={tableSettings.onlyFavorite}
                                                onChange={e => handleTableSettings({ onlyFavorite: !tableSettings.onlyFavorite })}
                                            />
                                        }
                                        label='Endast favoritkunder'
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="accent"
                                                checked={tableSettings.onlyMissingCreatives}
                                                onChange={e => handleTableSettings({ onlyMissingCreatives: !tableSettings.onlyMissingCreatives })}
                                            />
                                        }
                                        label='Endast kampanjer utan material'
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item>
                                <StatusButtons status={tableSettings.status} setStatus={v => handleTableSettings({ status: v })} loading={loading} />
                            </Grid>
                            {!isXs && (
                                <Grid item>
                                    <CustomInfoIcon
                                        sx={{ mt: 0.5 }}
                                        text={
                                            <List disablePadding>
                                                <ListItemText
                                                    primary='Endast favoritkunder'
                                                    primaryTypographyProps={{ fontSize: '14px' }}
                                                    secondary={<Typography variant='caption'>visar endast dina favoritkunders kampanjer</Typography>}
                                                />
                                                <ListItemText
                                                    primary='Endast kampanjer utan material'
                                                    primaryTypographyProps={{ fontSize: '14px' }}
                                                    secondary={<Typography variant='caption'>visar endast kampanjer som saknar uppladdat material</Typography>}
                                                />
                                                <ListItemText
                                                    primary='Kommande'
                                                    primaryTypographyProps={{ fontSize: '14px' }}
                                                    secondary={<Typography variant='caption'>kampanjer som ska starta inom de kommande 7 dagarna</Typography>}
                                                />
                                                <ListItemText
                                                    primary='Pågående'
                                                    primaryTypographyProps={{ fontSize: '14px' }}
                                                    secondary={<Typography variant='caption'>kampanjer som är igång just nu</Typography>}
                                                />
                                                <ListItemText
                                                    primary='Avslutade'
                                                    primaryTypographyProps={{ fontSize: '14px' }}
                                                    secondary={<Typography variant='caption'>kampanjer som avslutades under de senaste 7 dagarna</Typography>}
                                                />
                                            </List>
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                            <LineItemsTable items={filterItems() ?? []} loading={loading} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </DefaultPage>
    );
};

export const LineItemRow = (props) => {
    const navigate = useNavigate();
    const isMobile = useMobile();
    const isDesktop = !isMobile;

    const lineitem = props?.lineitem;
    return (
        <Wrapper key={lineitem.id} onClick={() => navigate(`/kampanj/${lineitem.externalId ?? lineitem.id}`)}>
            <Field xs={4} md={2}>
                <Text value={lineitem.externalId ?? lineitem.name ?? lineitem.id}></Text>
            </Field>
            <Field xs={8} md={2}>
                <Text value={lineitem?.advertiser?.name ?? ''}></Text>
            </Field>
            {isDesktop && (
                <Grid item container md={1} justifyContent='space-evenly'>
                    <TargetingTooltip lineitem={lineitem} />
                    <MissingCreativesTooltip show={lineitem?.creatives?.length === 0} />
                </Grid>
            )}
            {isDesktop && (
                <Field md={2}>
                    <SingleDate date={lineitem?.start} />
                </Field>
            )}
            {isDesktop && (
                <Field md={2}>
                    <SingleDate date={lineitem?.end} />
                </Field>
            )}
            {isMobile && (
                <Field xs={4}>
                    <DateRange from={lineitem?.start} to={lineitem?.end} />
                </Field>
            )}
            <Field xs={8} md={1} end={' visningar'}>
                <Number value={lineitem?.stats?.impressionsDelivered} />
            </Field>
            <Grid item container xs={4} md={1} alignContent='center'>
                <DeliveryProgressBar lineitem={lineitem} size='small' />
            </Grid>
            <Field xs={8} md={1} end={' klick'}>
                <Number value={lineitem?.stats?.clicksDelivered} />
            </Field>
        </Wrapper>
    )
}

const Wrapper = props => {
    return (
        <React.Fragment>
            <Grid justifyContent='space-between' item container {...props} sx={{ cursor: 'pointer' }} my={0.5} />
            <Divider />
        </React.Fragment>
    );
};

const Field = props => {
    const isMobile = useMobile();
    const gridprops = {
        xs: props?.xs,
        container: props?.container,
        alignContent: props?.alignContent,
        md: props?.md,
    };

    return (
        <Grid item {...gridprops} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden', pr: 1 }}>
            {props?.children}
            {isMobile && props?.end && (
                <Typography variant='subtitle2' component='span' color='text.secondary' noWrap>
                    {props?.end}
                </Typography>
            )}
        </Grid>
    );
};

const SingleDate = props => (
    <Typography variant='subtitle2' component='span' noWrap>
        {formatDate(props?.date)}
    </Typography>
);

const DateRange = props => {
    const format = date => {
        date = new Date(date);
        return `${date.getDate()}/${date.getMonth() + 1}`;
    };
    // let [y, m, d] = from.split("-")
    return (
        <Typography variant='subtitle2' component='span' noWrap>
            {`${format(props?.from)} - ${format(props?.to)}`}
        </Typography>
    );
};

const Number = props => (
    <Typography variant='subtitle2' component='span' noWrap>
        {props?.value?.toLocaleString('sv-SE') ?? 0}
    </Typography>
);

const Text = props => {
    const isMobile = useMobile()

    return (
        <Typography variant='subtitle2' noWrap={!isMobile}>
            {props?.value}
        </Typography>
    )
};

export const TargetingTooltip = ({ lineitem }) => {
    const placements = lineitem?.placements;

    const uniqueSites = [...new Set(placements?.map(p => p?.split(' ')[0]))];
    const uniquePlacements = [...new Set(placements?.map(p => p?.split(/ (.*)/s)[1]))];

    const allSitesSelected = uniqueSites.length >= 19 && uniquePlacements.length === 1;

    const customTargeting = lineitem?.customTargeting;

    const tooltipGrid = (
        <Grid container direction="column" spacing={0.5}>
            <Grid item>
                <List disablePadding>
                    <ListItemText primary="Placeringar" primaryTypographyProps={{ fontSize: '14px' }} />
                    {allSitesSelected ? (
                        <ListItemText primary={uniquePlacements[0] ? `${uniquePlacements[0]} på alla sajter` : 'Alla sajter'} primaryTypographyProps={{ fontSize: '12px' }} />
                    ) : (
                        placements?.map(p => (
                            <ListItemText key={p} primary={p} primaryTypographyProps={{ fontSize: '12px' }} />
                        ))
                    )}
                </List>
            </Grid>
            {customTargeting?.length > 0 && (
                <Grid item>
                    <List disablePadding>
                        {customTargeting?.map(c => (
                            <React.Fragment key={c.key?.id}>
                                <ListItemText primary={c.key?.displayName ?? c.key?.name} primaryTypographyProps={{ fontSize: '14px' }} />
                                {c.values?.map(v => <ListItemText key={v?.id} primary={v?.displayName ?? v?.name} primaryTypographyProps={{ fontSize: '12px' }} />)}
                            </React.Fragment>
                        ))}
                    </List>
                </Grid>
            )}
        </Grid>
    )

    return (
        <Grid item container xs alignContent='center'>
            <DefaultTooltip title={tooltipGrid} placement='right'>
                <MyLocationIcon fontSize='small' />
            </DefaultTooltip>
        </Grid>
    );
};

export const MissingCreativesTooltip = props => {
    return (
        <Grid item container xs alignContent='center'>
            {props?.show && (
                <DefaultTooltip title={<Typography variant='caption'>Kampanjen saknar material</Typography>}>
                    <HideImageOutlinedIcon fontSize='small' htmlColor='#EA4335' />
                </DefaultTooltip>
            )}
        </Grid>
    );
};

const StatusButtons = props => {
    const { status, setStatus, loading } = props;
    return (
        <FormControl>
            <RadioGroup row name='filter-radio-buttons-group' value={status} onChange={event => setStatus(event.target.value)}>
                <FormControlLabel sx={{ fontSize: '0.9rem' }} disabled={loading} value='ready' control={<Radio size='small' color='accent' />} label='Kommande' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} disabled={loading} value='delivering' control={<Radio size='small' color='accent' />} label='Pågående' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} disabled={loading} value='completed' control={<Radio size='small' color='accent' />} label='Avslutade' />
            </RadioGroup>
        </FormControl>
    );
};
