import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Typography, Select, MenuItem, FormControl, InputLabel, Chip, Stack } from '@mui/material';
import { getBackendSubDomain } from '../../functions/subDomain';
import SearchIcon from '@mui/icons-material/Search';
import { getPercentColor, timeUnitConvert } from '../../functions/misc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateRangePicker, ProgressBar, SingleDatePicker } from '../forecast/components';
import LeafletMap from './LeafComponent';
import { useMobile } from '../../material/theme';
import { useDoohForecast } from '../../functions/hooks/forecast/useDoohForecast';
import { DoohTable } from '../forecast/Dooh';

export const DoohScreen = () => {
    const location = useLocation();
    const { id } = useParams();
    const isMobile = useMobile();
    const navigate = useNavigate();
    const [screen, setScreen] = useState(location.state?.screen ?? null);

    useEffect(() => {
        async function getData(id) {
            const screenData = await fetch(`${getBackendSubDomain('nadir')}/doohclick/screen/${id}`)
                .then(e => e.json())
            console.log(screenData);
            setScreen(screenData);
        }
        !screen && getData(id);
    }, []);

    return (
        <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
                {screen && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={0} sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    {screen?.screenTenant?.azureUrl && (
                                        <Grid container item xs={12} md={6} justifyContent='center'>
                                            <img
                                                src={screen?.screenTenant?.azureUrl}
                                                alt={screen?.screenName}
                                                style={{ marginBottom: 'auto', display: 'block', maxWidth: '100%', maxHeight: isMobile ? 500 : '100%' }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs>
                                        <Stack spacing={0.5}>
                                            <Typography gutterBottom variant='h5' component='div'>
                                                {screen?.screenName}
                                            </Typography>
                                            <Typography gutterBottom color='text.secondary'>
                                                {screen?.screenTenant?.longDescription}
                                            </Typography>
                                            <TitleAndText title='Ägare' text={screen?.ownerName} />
                                            <TitleAndText title='Tid' text={`${screen?.screen?.openTime} - ${screen?.screen?.closeTime}`} />
                                            <TitleAndText
                                                title='Upplösning'
                                                text={`${screen?.ruleSet?.defaultResWidth}x${screen?.ruleSet?.defaultResHeight}`}
                                            />
                                            <TitleAndText title='Storlek' text={screen?.screenTenant?.landArea} />
                                            {(screen?.extra?.screen?.latitude !== 0 && screen?.extra?.screen?.longitude !== 0) && (
                                                <LeafletMap
                                                    markers={[
                                                        {
                                                            latitude: screen?.extra?.screen?.latitude,
                                                            longitude: screen?.extra?.screen?.longitude,
                                                            name: screen?.screenName,
                                                        },
                                                    ]}
                                                    center={{ latitude: screen?.extra?.screen?.latitude, longitude: screen?.extra?.screen?.longitude }}
                                                    zoom={12}
                                                />
                                            )}
                                        </Stack>
                                        {/* <Button variant='contained' sx={{ mt: 2 }} onClick={() =>
                                            navigate(`/dooh/skylt/${screen?.screenTenant?.id}/preview`, {
                                                state: {
                                                    screen: screen,
                                                },
                                            })
                                        }>Preview</Button> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <ForecastScreen screenId={id} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const ForecastScreen = ({ screenId }) => {    
    const [settings, setSettings] = useState({
        type: '2',
        dates: null,
        ids: [screenId.toString()],
    });

    const [request, setRequest] = useState(null);

    const [data, loading, resetData] = useDoohForecast(request);
    const requestForecast = () => setRequest({ settings });

    const handleSettingsChange = (k, v) => {
        setSettings({ ...settings, [k]: v });
        resetData();
    };

    return (
        <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: 2 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container spacing={2} justifyContent='center'>
                        <Grid item xs={6} sm={3} md={4}>
                            <FormControl variant='outlined' size='medium' fullWidth>
                                <InputLabel>Tidsenhet</InputLabel>
                                <Select
                                    label='Tidsenhet'
                                    value={settings.type}
                                    onChange={value => handleSettingsChange('type', value.target.value)}
                                >
                                    <MenuItem value={'3'}>Dagar</MenuItem>
                                    <MenuItem value={'2'}>Veckor</MenuItem>
                                    <MenuItem value={'1'}>Månader</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={4}>
                            <DateRangePicker dates={settings.dates} setDates={value => handleSettingsChange('dates', value)} />
                            {/* <SingleDatePicker
                                setDate={date => {
                                    setForecastData(null);
                                    setDate(date);
                                }}
                                date={date}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                endIcon={<SearchIcon />}
                                fullWidth
                                size='large'
                                variant='contained'
                                sx={{ height: '56px' }}
                                color='primary'
                                disableElevation
                                disabled={loading}
                                onClick={() => requestForecast()}
                            >
                                {loading ? 'Laddar...' : 'Kontrollera lager'}
                            </Button>
                        </Grid>
                    </Grid>
                    {data && <DoohTable data={data} type={settings?.type}/>}
                </Grid>
            </Paper>
        </Grid>
    );
};

const TitleAndText = ({ title, text }) => {
    if (text) {
        return (
            <Typography variant='body1'>
                {title}: {text}
            </Typography>
        );
    } else return <></>;
};
