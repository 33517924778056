import { useState, useEffect } from 'react';
import { getBackendSubDomain } from '../subDomain';

export const useCustomers = (req) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uniqueSorts, setUniqueSorts] = useState([])

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${getBackendSubDomain('nadir')}/gam/customers`, {
                    headers: {
                        'content-type': 'application/json',
                    },
                    method: 'GET',
                });
                const r = await response.json();
                const uniqueValues = {
                    team: new Set(),
                    salesRep: new Set(),
                    labels: new Set(),
                    division: new Set(),
                };

                r.forEach(obj => {
                    if (obj.team !== undefined) {
                        uniqueValues.team.add(obj.team);
                    }
                    if (obj.salesRep !== undefined) {
                        uniqueValues.salesRep.add(obj.salesRep);
                    }
                    if (obj.labels !== undefined && obj.labels.length > 0) {
                        obj.labels.forEach(label => {
                            if (label.name !== undefined) {
                                uniqueValues.labels.add(label.name);
                            }
                        });
                    }
                    if (obj.division !== undefined) {
                        uniqueValues.division.add(obj.division);
                    }
                });

                const result = {
                    team: Array.from(uniqueValues.team),
                    salesRep: Array.from(uniqueValues.salesRep),
                    labels: Array.from(uniqueValues.labels),
                    division: Array.from(uniqueValues.division),
                };
                setUniqueSorts(result);

                setItems(r);
            } catch (error) {
                console.warn(error);
            } finally {
                setLoading(false);
            }
        };

        if (req) fetchCustomers();
    }, [req]);

    return { customers: items, customersLoading: loading, customersUniqueSorts: uniqueSorts };
};
