import React, { useState } from 'react';
import { Alert, Button, Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material"
import { DefaultTooltip, PaperBody } from "../DefaultComponents"
import { grey } from '@mui/material/colors';
import { useCheckinternalUser } from '../../functions/hooks/internalUserHandler';
import { getBackendSubDomain } from '../../functions/subDomain';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';

export const NadirNotes = ({ id, itemNote }) => {
    const [notes, setNotes] = useState(itemNote)
    const [viewMode, setViewMode] = useState(true);
    const [isInternal, loading] = useCheckinternalUser();

    const postNotes = async (id, html) => {
        const options = {
            headers: {
                'content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                id: id,
                text: html,
            }),
        };

        return await fetch(getBackendSubDomain('nadir') + `/notes/post`, options)
            .then(e => e.json())
            .then(e => e);
    }

    if (isInternal || notes) return (
        <Grid container item xs>
            <PaperBody>
                <Grid container spacing={1}>
                    <Grid item container alignItems="center" spacing={1}>
                        <Grid item>
                            <Typography variant='h5' sx={{ color: grey[600] }}>
                                Kommentarer
                            </Typography>
                        </Grid>
                        {isInternal && (
                            <Grid item>
                                <DefaultTooltip title={viewMode ? "Redigera kommentarer" : "Avbryt redigering"}>
                                    <IconButton size="small" color="accent" onClick={() => setViewMode(!viewMode)}>
                                        {viewMode ? <EditIcon /> : <EditOffIcon />} 
                                    </IconButton>
                                </DefaultTooltip>
                            </Grid>
                        )}
                    </Grid>
                    {(isInternal && !viewMode) && (
                        <Grid item>
                            <Typography variant="body2" color="text.secondary">
                                Kommentarer är synliga för alla, men de kan endast redigeras av interna användare.
                            </Typography>
                        </Grid>
                    )}
                    <Grid item container justifyContent="center" alignItems="center">
                        {viewMode ? (
                            notes ? <View html={notes} /> : <Typography color="text.secondary">Kommentarer saknas</Typography>
                        ) : (
                            <Edit html={notes} setHtml={setNotes} save={() => postNotes(id, notes)} switchToView={() => setViewMode(true)} />
                        )}
                    </Grid>
                </Grid>
            </PaperBody>
        </Grid>
    )
    else return <></>
}

const View = ({ html }) => <div dangerouslySetInnerHTML={{ __html: html }} />

const Edit = ({ html, setHtml, save, switchToView }) => {
    const [showAlert, setShowAlert] = useState(false)

    const onSave = async () => {
        const res = await save()
        if (res?.error) setShowAlert({ severity: 'error', text: 'Kunde inte spara' })
        else switchToView()
    }

    return (
        <>
            <Grid item container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        fullWidth
                        value={html}
                        onChange={(event) => setHtml(event.target.value)}
                        rows={12}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <Button endIcon={<SaveIcon />} onClick={() => onSave()}>
                        Spara kommentarer
                    </Button>
                </Grid>
            </Grid>
            <Snackbar open={showAlert} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
                <Alert onClose={() => setShowAlert(false)} variant='filled' severity={showAlert.severity} sx={{ width: '100%' }}>
                    {showAlert?.text}
                </Alert>
            </Snackbar>
        </>
    )
} 