import React, { useContext, useState } from 'react';

import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatDate, sortAndFilterOrders } from '../../functions/misc';
import { LineitemTable } from './LineitemTable';
import { SortingContext } from './Customer';
import { useLocation } from 'react-router-dom';
import { DefaultBodyHeader } from '../DefaultComponents';
import { grey } from '@mui/material/colors';
import { OrderCheckbox } from '../combinedReport/CombinedReportCheckboxes';

export const OrderTable = ({ orders, showColumns, combinedReport, handleCombinedReport }) => {
    const location = useLocation();
    const [openOrder, setOpenOrder] = useState(location?.state?.openOrder ?? null);
    const sortingContext = useContext(SortingContext);

    return (
        <>
            <DefaultBodyHeader>
                <Typography variant='h6' sx={{ color: grey[600] }}>
                    Display
                </Typography>
            </DefaultBodyHeader>
            {showColumns && (
                <Grid item container sx={{ pr: 3, mt: 1 }}>
                    <SortableColumnHeader size={3} name='Namn' column='name' />
                    <SortableColumnHeader size={1.5} name='Startdatum' column='start' />
                    <SortableColumnHeader size={1.5} name='Slutdatum' column='end' />
                    <SortableColumnHeader size={1.5} name='Visningar' column='totalImpressionsDelivered' />
                    <SortableColumnHeader size={1.5} name='Klick' column='totalClicksDelivered' />
                    <SortableColumnHeader size={1} name='Status' column='status' />
                    <SortableColumnHeader size={2} name='Leverans' column='delivery' />
                </Grid>
            )}
            {sortAndFilterOrders(orders, sortingContext.sorting).map((order, i) => (
                <React.Fragment key={order.id}>
                    <Grid item container>
                        <Accordion
                            expanded={openOrder === order.id}
                            elevation={0}
                            disableGutters
                            onClick={() => setOpenOrder(order.id === openOrder ? null : order.id)}
                            sx={{ width: '100%' }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${order.id}-content`} id={`${order.id}-header`} sx={{ px: 0 }}>
                                <Grid container>
                                    <Grid item xs={5} md={3} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden', pr: 1 }}>
                                        <Typography variant='subtitle2' component='span' noWrap>
                                            {order.name.split('_0')[0] ?? order.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} md={1.5} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                                        <Typography variant='subtitle2' component='span' noWrap>
                                            {formatDate(order?.start)}{!showColumns && (` - ${formatDate(order?.end) ?? ''}`)}
                                        </Typography>
                                    </Grid>
                                    {showColumns && (
                                        <Grid item md={1.5}>
                                            <Typography variant='subtitle2' component='span'>
                                                {formatDate(order?.end) ?? ''}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={5} md={1.5}>
                                        <Typography variant='subtitle2' component='span'>
                                            {order.totalImpressionsDelivered?.toLocaleString('sv-SE') ?? 0}{!showColumns && ' visningar'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={1.5}>
                                        <Typography variant='subtitle2' component='span'>
                                            {order.totalClicksDelivered?.toLocaleString('sv-SE') ?? 0}{!showColumns && ' klick'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item container xs={1} justifyContent={'flex-end'}>
                                        <OrderCheckbox lineitemIds={order.lineitems.map(li => li.id)} combinedReport={combinedReport} handleCombinedReport={handleCombinedReport} />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            {order.lineitems.length > 0 && (
                                <AccordionDetails sx={{ px: 1 }}>
                                    <LineitemTable lineitems={order.lineitems} isSelected={openOrder === order.id} showColumns={showColumns} combinedReport={combinedReport} handleCombinedReport={handleCombinedReport} />
                                    <Typography variant='caption' color='text.secondary'>
                                        Senast uppdaterad {new Date(order.timestamp)?.toLocaleString('sv-SE')}
                                    </Typography>
                                </AccordionDetails>
                            )}
                        </Accordion>
                    </Grid>
                    {i !== orders?.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </>
    );
};

const SortableColumnHeader = props => {
    const sc = useContext(SortingContext);
    const { size, name, column } = props;
    const isSelected = sc.sorting.column === column;
    return (
        <Grid
            item
            container
            spacing={1}
            md={size}
            sx={{ cursor: 'pointer' }}
            onClick={() => sc.setSorting({ ...sc.sorting, column: column, ascending: isSelected ? !sc.sorting.ascending : true })}
        >
            <Grid item>
                <Typography variant='subtitle2'>{name}</Typography>
            </Grid>
            {isSelected && (
                <Grid item>
                    {sc.sorting.ascending ? <ArrowUpwardIcon sx={{ fontSize: 16, mt: '3px' }} /> : <ArrowDownwardIcon sx={{ fontSize: 16, mt: '3px' }} />}
                </Grid>
            )}
        </Grid>
    );
};