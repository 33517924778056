import { Grid, Paper, Typography, Alert } from '@mui/material';
import React, { useContext } from 'react';
import { LocalStorageContext } from '../../App';
import { FavoriteButtonIconOnly } from '../customer/Customer';
import { CustomerSearch } from './CustomerSearch';
import { GoToLineItem } from './GoToLineItem';
import { CampaignsList } from './CampaignsList';
import { AdvertisersList } from './AdvertisersList';

export const Home = () => {
    // eslint-disable-next-line no-unused-vars
    const { localStorage, setLocalStorage } = { ...useContext(LocalStorageContext) };

    return (
        <Grid container alignItems='flex-start' justifyContent='space-evenly' spacing={2}>
            {process.env?.REACT_APP_DEPLOYMENT !== 'Master' && (
                <Grid item xs={12} md={12}>
                    <Alert severity='info'>Nuvarande miljö: {process.env?.REACT_APP_DEPLOYMENT}</Alert>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Typography gutterBottom>Sök efter en kund och visa dess ordrar</Typography>
                    <CustomerSearch />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Typography gutterBottom>Gå direkt till en kampanj</Typography>
                    <GoToLineItem />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AdvertisersList
                    arr={localStorage?.favoriteAdvertisers}
                    title='Favoritkunder'
                    titleIcon={<FavoriteButtonIconOnly />}
                    infoText="Favoritmarkerade kunder sparas endast lokalt i din webbläsare, de följer inte med om du byter enhet"
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AdvertisersList
                    arr={localStorage?.recentAdvertisers}
                    title='Senast besökta kunder'
                    infoText="Senast besökta kunder sparas endast lokalt i din webbläsare, de följer inte med om du byter enhet"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CampaignsList
                    arr={localStorage?.recentCampaigns}
                    title='Senast besökta kampanjer'
                    infoText="Senast besökta kampanjer sparas endast lokalt i din webbläsare, de följer inte med om du byter enhet"
                />
            </Grid>
        </Grid>
    );
};