import ReactGA from 'react-ga4';

export const initializeGA = () => {
    // console.log("ga-init");
    ReactGA.initialize("G-0J9YW0MTM3");
};

export const trackGAPageView = (page) => {
    // console.log("ga-pv", page)
    ReactGA.send({
        hitType: "pageview",
        page: page
    });
};

export const trackGAEvent = (category, action, label) => {
    // console.log("ga-ev", category, action, label)
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};