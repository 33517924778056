import React, { useState } from "react";
import { Typography, Grid, Box, Card, CardHeader, CardContent, Divider, Tooltip } from "@mui/material";
import { DefaultCard, PaperBody } from "../../DefaultComponents";
import { grey } from "@mui/material/colors";
import { formatDate } from "../../../functions/misc";
import { sumArrayClicks } from "./displayFunction";
import { lineItemStatus } from "../../../functions/translate";
import { DeliveryProgressBar } from "../../DeliveryIndicators";
import { DailyBreakDown } from "./DailyBreakDown";
import { ArrowExpander } from "../../forecast/components";
import { ClickDistribution } from "./ClickDistribution";
import { useMobile } from "../../../material/theme";
import { CustomInfoIcon } from "../../InfoIcon";

export const AdNumberGrid = ({ item, combinedReport = false }) => {
    const isStandard = item?.lineItemType === "STANDARD";
    const isMobile = useMobile();
    return (
        <PaperBody>
            {!combinedReport && (
                <>
                    <Typography variant="h5" sx={{ color: grey[600] }}>
                        {item.externalId ?? "Kampanjnamn saknas"}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        {formatDate(item.start)} - {formatDate(item.end)}
                    </Typography>
                </>
            )}
            <Grid container spacing={isMobile ? 2 : 0} justifyContent="space-evenly">
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard title="Visningar" infoText="Antalet gånger som annonsen har visats för användare. Detta mäter exponeringen av annonsen.">
                        <Typography variant="h3">{item.stats.impressionsDelivered?.toLocaleString("sv-SE") ?? 0}</Typography>
                        {isStandard && <Typography sx={{ color: grey[600] }}>av {item?.primaryGoal?.units?.toLocaleString("sv-SE") ?? 0}</Typography>}
                    </DefaultCard>
                </Grid>
                <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                    <Divider orientation="vertical" variant="middle" sx={{ height: "95%" }} />
                </Box>
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard
                        title="CTR"
                        infoText="Click-through rate (CTR) är procentandelen användare som klickade på annonsen i förhållande till antalet visningar."
                    >
                        <Typography variant="h3">
                            {item?.stats?.clicksDelivered && item?.stats?.impressionsDelivered
                                ? ((item?.stats?.clicksDelivered / item?.stats?.impressionsDelivered) * 100).toFixed(2)
                                : 0}
                            %
                        </Typography>
                        <Typography sx={{ color: grey[600] }}>{item.stats.clicksDelivered?.toLocaleString("sv-SE") ?? "0"} klick</Typography>
                        {sumArrayClicks(item) !== null && <ClickDistribution clicks={sumArrayClicks(item)} />}
                    </DefaultCard>
                </Grid>
                {isStandard && (
                    <>
                        <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <Divider orientation="vertical" variant="middle" sx={{ height: "95%" }} />
                        </Box>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Card variant="outlined" sx={{ border: "none" }}>
                                <CardHeader
                                    title={
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <span style={{ textTransform: "uppercase", color: grey[600] }}>Leverans</span>
                                            <div style={{ marginLeft: "8px", display: "flex", alignItems: "center" }}>
                                                <CustomInfoIcon text="Visar leveransstatus för annonsen enligt Google Ad Manager. Anger om leveransen är aktiv, pausad eller slutförd." />
                                            </div>
                                        </div>
                                    }
                                    sx={{ textAlign: "center", color: grey[600] }}
                                />
                                <CardContent>
                                    <Grid container item justifyContent="center" alignContent="center">
                                        <Typography sx={{ color: grey[600] }}>{lineItemStatus[item.status]}</Typography>
                                    </Grid>
                                    <DeliveryProgressBar lineitem={item} size="large" />
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}
                {!combinedReport && (
                    <Grid item xs={12} mt={isMobile ? 0 : 2}>
                        <ArrowExpander tooltipTitle="daglig statistik">
                            <DailyBreakDown data={item?.report_daily ?? []} />
                        </ArrowExpander>
                    </Grid>
                )}
            </Grid>
        </PaperBody>
    );
};
