import { Autocomplete, debounce, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getBackendSubDomain } from '../../functions/subDomain';
import { useNavigate } from 'react-router-dom';
import { getRandomCustomerName } from '../../functions/misc';

export const CustomerSearch = props => {
    const [search, setSearch] = useState(null);
    const [loading, setLoading] = useState(null);
    const [customers, setCustomers] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [placeholderName, setPlaceholderName] = useState(getRandomCustomerName());
    const setSearchQueryDebounced = debounce(v => setSearch(v), 400);
    const navigate = useNavigate();

    useEffect(() => {
        let headers = new Headers();
        headers.set('content-type', 'application/json');
        const options = {
            headers: headers,
            method: 'GET',
        };
        if (search?.length > 2) {
            setLoading(true);
            fetch(`${getBackendSubDomain('nadir')}/gam/advertisers/${search}`, options)
                .then(res => res.json())
                .then(data => {
                    setCustomers(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.warn(err);
                    setLoading(false);
                });
        }
    }, [search]);

    return (
        <Autocomplete
            id='customer-search'
            options={customers}
            loading={loading}
            noOptionsText='Inga resultat'
            loadingText='Laddar...'
            renderInput={params => <TextField {...params} placeholder={`t.ex. ${placeholderName}`} />}
            onInputChange={(_event, value) => setSearchQueryDebounced(value)}
            getOptionLabel={option => option.name}
            renderOption={(_, option) => {
                const boldParts = option?.name?.split(new RegExp(`(${search})`, 'gi'));
                const boldName = boldParts?.map((part, i) => {
                    if (part.toLowerCase() === search?.toLowerCase()) {
                        return (
                            <Typography key={i} component='span' fontWeight='bold'>
                                {part}
                            </Typography>
                        );
                    } else {
                        return part;
                    }
                });

                return (
                    <Typography
                        variant='body1'
                        component='p'
                        key={option.id}
                        sx={{ padding: 1, cursor: 'pointer' }}
                        onClick={() => navigate(`/kund/${option.id}`, { state: { customer: option } })}
                    >
                        {boldName} ({option?.orderCount})
                    </Typography>
                );
            }}
        />
    );
};
