import React, { useState } from 'react';
import { Grid, Typography, IconButton, Paper } from '@mui/material';
import { getPercentColor, monthTranslate } from '../../functions/misc';
import { SiteImage } from '../SiteImage';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useMobile } from '../../material/theme';
import { ProgressBar } from './components';

export const BreakdownTable = ({ data, request, dateType, breakdownType }) => {
    const [page, setPage] = useState(0);
    const isMobile = useMobile();

    return (
        <Grid item xs>
            <Paper elevation={0} sx={{ p: 2 }}>
                <Grid container direction='row'>
                    {/* First Column */}
                    <Column>
                        <Title text={request.settings.placement ?? request.settings.site} />
                        <RowTitle data={data} breakdownType={breakdownType} />
                    </Column>

                    {/* All other columns */}
                    {data.time_breakdowns.map((column, i) => {
                        return !(isMobile && page !== i) && (
                            <Column key={column.start_date}>
                                {/* CellHeader */}
                                <ColumnHeader data={column} page={page} setPage={setPage} lastPage={data.time_breakdowns?.length - 1} current={i === 0} timeUnit={dateType} />
                                {/* Cells */}
                                {column.placement_breakdowns.map(entry => (
                                    <DataCell key={`${entry.name} ${entry.id} v${column.week}`} data={entry} />
                                ))}
                            </Column>
                        )
                    })}
                </Grid>
            </Paper>
        </Grid>
    );
};

const Title = props => (
    <Grid item sx={{ height: 55 }}>
        <Typography>Ledigt lager: {props?.text}</Typography>
    </Grid>
);

const RowTitle = props => {
    const { data, breakdownType } = props;
    const names = data?.time_breakdowns?.[0]?.placement_breakdowns?.map(entry => entry[breakdownType] ?? entry.name);

    return (
        <>
            {names?.map(name => (
                <Grid item container key={name} sx={{ height: 55 }} alignItems='center'>
                    {breakdownType === "site" &&
                        <Grid item xs={2}>
                            <SiteImage site={name} size={24} />
                        </Grid>
                    }

                    <Grid item>
                        <Typography noWrap>{name}</Typography>
                    </Grid>
                    {name === 'Preroll' && <VideocamIcon color='secondary' sx={{ ml: "10px" }} />}
                </Grid>
            ))}
        </>
    );
};

const DataCell = props => {
    const { data } = props;
    let percentage = (data.available / data.matched) * 100;
    percentage = percentage > 100 ? 100 : percentage;

    return (
        <Grid item sx={{ height: 55 }}>
            <ProgressBar
                value={percentage}
                color={getPercentColor(percentage)}
                tooltipText={
                    <Typography variant='caption'>
                        {data.available?.toLocaleString('sv-SE')} ledigt av {data.matched?.toLocaleString('sv-SE')}
                    </Typography>
                }
            />
        </Grid>
    );
};

const ColumnHeader = props => {
    const { data, page, setPage, lastPage, current, timeUnit } = props;

    const start = new Date(data.start_date);
    const end = new Date(data.end_date);

    const startDateString = `${start.getDate()}/${start.getMonth() + 1}`;
    const endDateString = `${end.getDate()}/${end.getMonth() + 1}`;
    const dateString = `${startDateString}-${endDateString}`;

    const timeUnitText = timeUnit === 'weeks' ? `v. ${data.week}` : monthTranslate[start.getMonth()]

    return (
        <Grid item container sx={{ height: 55 }} justifyContent='center' alignItems='center'>
            <Grid item xs={2} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    onClick={() => {
                        if (page > 0) setPage(page - 1);
                    }}
                    disabled={page === 0}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
            </Grid>
            <Grid item xs={8} md={12} sx={{ textAlign: 'center' }}>
                <Typography>{timeUnitText}</Typography>
                <Typography variant='caption'>{dateString}</Typography>
            </Grid>
            <Grid item container xs={2} justifyContent='flex-end' sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    onClick={() => {
                        if (page <= lastPage) setPage(page + 1);
                    }}
                    disabled={page === lastPage}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
};

const Column = props => {
    return (
        <Grid item xs>
            <Grid container direction='column'>
                {props?.children}
            </Grid>
        </Grid>
    );
};