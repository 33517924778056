import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PreviewIcon from '@mui/icons-material/Preview';
import { getBackendSubDomain } from '../../../functions/subDomain';
import { headers } from './Creative';
import { DefaultTooltip } from '../../DefaultComponents';
import { trackGAEvent } from '../../../functions/googleAnalytics';

export const SitePreviewDialog = props => {
    const { lineItemId, id, sites } = props;
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState(null);
    const [link, setLink] = useState(null);

    const generateLink = () => {
        setLoading(true);

        trackGAEvent("Button click", "Förhandsgranska på site", location.pathname + location.search)

        let tld = 'se';
        if (site === 'affarsliv') tld = 'com';
        if (site === 'kuriren') tld = 'nu';
        const siteUrl = `https://${site}.${tld}`;
        const options = {
            headers: headers,
            method: 'POST',
            body: JSON.stringify({
                lineItemId: lineItemId,
                creativeId: id,
                site: siteUrl,
            }),
        };
        fetch(`${getBackendSubDomain('krv')}/gam/site_preview`, options)
            .then(res => res.text())
            .then(data => {
                setLink(data);
                setLoading(false);
            })
            .catch(err => {
                console.warn(err);
                setLoading(false);
            });
    };

    const handleOpen = () => {
        setOpen(true);
        if (sites.length === 1) {
            setSite(sites[0].toLowerCase());
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <DefaultTooltip title='Förhandsgranska på site'>
                <Fab size='small' onClick={handleOpen} sx={{ boxShadow: "none", backgroundColor: "accent.main", "&:hover": { backgroundColor: "accent.dark" }, color: "#fff" }}>
                    <PreviewIcon />
                </Fab>
            </DefaultTooltip>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>Förhandsgranska på site</Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={2} pt={1} pb={link ? 0 : 2}>
                        <Grid item container spacing={2} alignItems='center' justifyContent='space-between'>
                            <Grid item xs={12} sm={8}>
                                <FormControl variant='outlined' size='small' fullWidth>
                                    <InputLabel>Välj site</InputLabel>
                                    <Select label='Välj site' value={site} onChange={value => setSite(value.target.value)}>
                                        {sites.sort().map(s => {
                                            return (
                                                <MenuItem key={s} value={s?.toLowerCase()}>
                                                    {s}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant='contained' sx={{ height: 40 }} disableElevation fullWidth disabled={!site} onClick={generateLink}>
                                    Generera länk
                                </Button>
                            </Grid>
                        </Grid>
                        {loading && (
                            <Grid item container xs={12} mt={2} mb={1} justifyContent='center'>
                                <CircularProgress size={50} color='primary' />
                            </Grid>
                        )}
                        {link && !loading && (
                            <>
                                <Grid item xs={12}>
                                    <TextField value={link} multiline fullWidth maxRows={10} variant='outlined' />
                                </Grid>
                                <Grid item container justifyContent='flex-end' spacing={1}>
                                    <Grid item>
                                        <Button onClick={() => navigator.clipboard.writeText(link)}>Kopiera länk</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => window.open(link, '_blank')}>Öppna länk</Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};
