import React from "react";
import { FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DefaultBody, DefaultPage, HeaderWithText } from "../DefaultComponents";
import { flattenedLayout } from "../../config/navigation";

export const CpmTool = props => {
    const info = flattenedLayout.toolCpm;

    const [data, setData] = React.useState({ cost: null, cpm: null, impressions: null });
    const [mode, setMode] = React.useState("impressions");

    const switchMode = v => {
        setMode(v);
        setData({ cost: null, cpm: null, impressions: null });
    };

    const handleInputChange = (key, v) => {
        let cost = data.cost;
        let cpm = data.cpm;
        let impressions = data.impressions;

        const parseValue = value => {
            const num = parseFloat(value);
            return isNaN(num) ? null : num;
        };

        if (key === "cost") cost = parseValue(v);
        else if (key === "cpm") cpm = parseValue(v);
        else if (key === "impressions") impressions = parseValue(v);

        switch (mode) {
            case "cost":
                if (cpm && impressions) cost = (impressions * cpm) / 1000;
                break;
            case "cpm":
                if (cost && impressions) cpm = (cost / impressions) * 1000;
                break;
            case "impressions":
                if (cpm && cost) impressions = (cost / cpm) * 1000;
                break;
            default:
                void 0;
                break;
        }

        setData({ cost, cpm, impressions });
    };

    return (
        <>
            <DefaultPage>
                <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
                <DefaultBody>
                    <Grid item xs={12} sm={12} md={12}>
                        <SelectModeRadio value={mode} setValue={switchMode} />
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" p={2}>
                        <Grid item xs={12} sm={2} md={2}>
                            <NumberField
                                label="Budget"
                                value={data.cost}
                                setValue={value => handleInputChange("cost", value)}
                                disabled={mode === "cost"}
                            ></NumberField>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <NumberField
                                label="CPM"
                                value={data.cpm}
                                setValue={value => handleInputChange("cpm", value)}
                                disabled={mode === "cpm"}
                            ></NumberField>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <NumberField
                                label="Visningar"
                                value={data.impressions}
                                setValue={value => handleInputChange("impressions", value)}
                                disabled={mode === "impressions"}
                            ></NumberField>
                        </Grid>
                    </Grid>
                </DefaultBody>
            </DefaultPage>
            <DefaultPage pt={2}>
                <HeaderWithText text1="Beräkna Rabatt (%) från visningar" text2="Beräkna Netto-CPM och Rabatt"></HeaderWithText>
                <CpmDiscountCalculator />
            </DefaultPage>
            <DefaultPage pt={2}>
                <HeaderWithText text1="Beräkna Rabatt (%) från netto-cpm" text2="Beräkna Visningar och Rabatt"></HeaderWithText>
                <CpmImpressionsLockedCalculator />
            </DefaultPage>
        </>
    );
};
export const NumberField = (props) => {
    const { label, value, setValue, disabled } = props;

    const formatNumber = (value) => {
        if (value === null || value === undefined || value === "") return "";
        const num = parseFloat(value);
        if (isNaN(num)) return "";
        return num.toFixed(4).replace(/\.?0+$/, "");
    };

    return (
        <TextField
            fullWidth
            label={label}
            type="number"
            disabled={disabled}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                sx: disabled
                    ? {
                          backgroundColor: "rgba(255, 165, 0, 0.1)", // Light orange background
                          color: "orange", // Text color inside
                          "& .Mui-disabled": {
                              color: "orange", // Text color for disabled state
                          },
                      }
                    : {},
            }}
            sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                    color: "orange", // Text color for disabled fields
                },
            }}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            variant="outlined"
            value={formatNumber(value)}
        />
    );
};


const SelectModeRadio = props => {
    const { value, setValue } = props;
    return (
        <FormControl>
            <FormLabel id="radio">Beräkna fält:</FormLabel>
            <RadioGroup row name="filter-radio-buttons-group" value={value} onChange={event => setValue(event.target.value)}>
                <FormControlLabel sx={{ fontSize: "0.9rem" }} value="cost" control={<Radio size="small" color="accent" />} label="Budget" />
                <FormControlLabel sx={{ fontSize: "0.9rem" }} value="cpm" control={<Radio size="small" color="accent" />} label="CPM" />
                <FormControlLabel sx={{ fontSize: "0.9rem" }} value="impressions" control={<Radio size="small" color="accent" />} label="Visningar" />
            </RadioGroup>
        </FormControl>
    );
};

export const CpmDiscountCalculator = props => {
    const [data, setData] = React.useState({
        budget: null,
        bruttoCpm: null,
        impressions: null,
        nettoCpm: null,
        rabatt: null,
    });

    const parseValue = value => {
        const num = parseFloat(value);
        return isNaN(num) ? null : num;
    };

    const handleInputChange = (key, value) => {
        let budget = data.budget;
        let bruttoCpm = data.bruttoCpm;
        let impressions = data.impressions;
        let nettoCpm = null;
        let rabatt = null;

        if (key === "budget") budget = parseValue(value);
        else if (key === "bruttoCpm") bruttoCpm = parseValue(value);
        else if (key === "impressions") impressions = parseValue(value);

        if (budget !== null && impressions !== null && impressions !== 0) {
            nettoCpm = (budget / impressions) * 1000;
        }

        if (bruttoCpm !== null && nettoCpm !== null && bruttoCpm !== 0) {
            rabatt = ((bruttoCpm - nettoCpm) / bruttoCpm) * 100;
        }

        setData({ budget, bruttoCpm, impressions, nettoCpm, rabatt });
    };

    const copyRabattToClipboard = () => {
        if (data.rabatt !== null) {
            const rabattText = data.rabatt.toFixed(4).replace('.', ',');
            navigator.clipboard.writeText(rabattText);
        }
    };

    return (
        <DefaultBody>
            <Grid item xs={12} container spacing={2}>
                {/* "Beräkna fält:" label */}
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel> </FormLabel>
                    </FormControl>
                </Grid>
                {/* Adjusted Grid items to fit fields next to each other */}
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Budget" value={data.budget} setValue={value => handleInputChange("budget", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Brutto-CPM" value={data.bruttoCpm} setValue={value => handleInputChange("bruttoCpm", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Netto-CPM" value={data.nettoCpm} disabled={true} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Visningar" value={data.impressions} setValue={value => handleInputChange("impressions", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    {/* Added copy icon to Rabatt (%) field */}
                    <TextField
                        fullWidth
                        label="Rabatt (%)"
                        value={data.rabatt !== null ? data.rabatt.toFixed(4).replace(/\.?0+$/, "") : ""}
                        disabled={true}
                        InputProps={{
                            
                            sx: true
                    ? {
                          backgroundColor: "rgba(255, 165, 0, 0.1)", // Light orange background
                          color: "orange", // Text color inside
                          "& .Mui-disabled": {
                              color: "orange", // Text color for disabled state
                          },
                      }
                    : {},
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="copy rabatt" onClick={copyRabattToClipboard} edge="end">
                                        <ContentCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </DefaultBody>
    );
};

export const CpmImpressionsLockedCalculator = (props) => {
    const [data, setData] = React.useState({
        budget: null,
        bruttoCpm: null,
        impressions: null,
        nettoCpm: null,
        rabatt: null,
    });

    const parseValue = (value) => {
        const num = parseFloat(value);
        return isNaN(num) ? null : num;
    };

    const handleInputChange = (key, value) => {
        let budget = data.budget;
        let bruttoCpm = data.bruttoCpm;
        let nettoCpm = data.nettoCpm;
        let impressions = null; // Locked, so calculated based on other values
        let rabatt = null;

        if (key === "budget") budget = parseValue(value);
        else if (key === "bruttoCpm") bruttoCpm = parseValue(value);
        else if (key === "nettoCpm") nettoCpm = parseValue(value);

        if (budget !== null && nettoCpm !== null && nettoCpm !== 0) {
            impressions = (budget / nettoCpm) * 1000; // Calculate impressions
        }

        if (bruttoCpm !== null && nettoCpm !== null && bruttoCpm !== 0) {
            rabatt = ((bruttoCpm - nettoCpm) / bruttoCpm) * 100; // Calculate rabatt
        }

        setData({ budget, bruttoCpm, impressions, nettoCpm, rabatt });
    };

    const copyRabattToClipboard = () => {
        if (data.rabatt !== null) {
            navigator.clipboard.writeText(data.rabatt.toFixed(4));
        }
    };

    return (
        <DefaultBody>
            <Grid  item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel></FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Budget" value={data.budget} setValue={(value) => handleInputChange("budget", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Brutto-CPM" value={data.bruttoCpm} setValue={(value) => handleInputChange("bruttoCpm", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField label="Netto-CPM" value={data.nettoCpm} setValue={(value) => handleInputChange("nettoCpm", value)} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <NumberField
                        label="Visningar"
                        value={data.impressions !== null ? data.impressions.toFixed(0) : ""}
                        disabled={true}
                        sx={{
                            "& .MuiInputBase-root.Mui-disabled": {
                                backgroundColor: "rgba(255, 165, 0, 0.1)", // Light orange background
                                border: "1px solid orange", // Orange border
                                color: "orange", // Orange text
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <TextField
                        fullWidth
                        label="Rabatt (%)"
                        value={data.rabatt !== null ? data.rabatt.toFixed(4).replace(/\.?0+$/, "") : ""}
                        disabled={true}
                        InputProps={{
                            sx: {
                                backgroundColor: "rgba(255, 165, 0, 0.1)", // Light orange background
                                color: "orange", // Orange text
                                "& .Mui-disabled": {
                                    color: "orange", // Orange text for disabled state
                                },
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="copy rabatt" onClick={copyRabattToClipboard} edge="end">
                                        <ContentCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </DefaultBody>
    );
};
