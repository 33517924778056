import React, { useEffect, useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { getBackendSubDomain } from '../../../functions/subDomain';
import { SiteImage } from '../../SiteImage';
import { DefaultTooltip } from '../../DefaultComponents';

export const SiteBreakDown = (props) => {
    const { item } = props;
    const [data, setData] = useState(initData(item));
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setData(initData(item))
    }, [item])

    const getBreakdown = async () => {
        setLoading(true);
        const res = await getBreakdownKVSaveNadir(item);
        setData(res);
        setLoading(false);
    }

    return (
        <>
            <DefaultTooltip title={`Visa fördelning av visningar och klick per site`}>
                <IconButton onClick={() => setOpen(true)}>
                    <FormatListNumberedIcon />
                </IconButton>
            </DefaultTooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item xs container alignItems="center">
                            Fördelning per site
                        </Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={() => setOpen(false)} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item container xs={12} spacing={2} alignItems="center">
                            <Grid item>
                                <Button
                                    color="accent"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    disabled={loading}
                                    onClick={() => getBreakdown()}
                                    startIcon={
                                        <AutorenewIcon
                                            sx={loading ? {
                                                'animation': 'spin 2s linear infinite',
                                                '@keyframes spin': {
                                                    '0%': {
                                                        transform: 'rotate(0deg)',
                                                    },
                                                    '100%': {
                                                        transform: 'rotate(360deg)',
                                                    },
                                                },
                                            } : {}}
                                        />
                                    }
                                >
                                    {data?.arr?.length > 0 ? "Uppdatera statistik" : "Hämta statistik"}
                                </Button>
                            </Grid>
                            <Grid item>
                                {data?.date && (
                                    <Typography variant='caption'>
                                        Senast uppdaterad {new Date(data?.date)?.toLocaleString('sv-SE')}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {data?.arr?.length > 0 ? (
                                <List>
                                    {data?.arr?.sort((a, b) => b["impressions"] - a["impressions"])?.map((s) => {
                                        return (
                                            <ListItem key={s.site} disableGutters>
                                                <ListItemAvatar>
                                                    <SiteImage site={s.site} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Grid item xs={12} sm={5}>
                                                                {capitalize(s.site)}
                                                            </Grid>
                                                            <Grid item xs={8} sm={4}>
                                                                {s.impressions?.toLocaleString("sv-SE")} visningar
                                                            </Grid>
                                                            <Grid item xs={4} sm={3}>
                                                                {s.clicks?.toLocaleString("sv-SE")} klick
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            ) : (
                                <>
                                    <Typography variant='body2'>Statistik saknas, tryck på knappen för att hämta.</Typography>
                                    <Typography variant='caption' fontStyle='italic'>Observera att det ibland kan ta ett par minuter att hämta statistiken.</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

const capitalize = (string) => {
    if (string.length > 3) return string.charAt(0).toUpperCase() + string.slice(1);
    else return string.toUpperCase();
}

export const getBreakdownKVSaveNadir = async (item) => {
    if (!item?.id || !item?.start || !item?.end) return

    const id = item.id

    //cant get breakdown in fture
    const startDate = new Date(item?.start)
    const today = new Date()
    if (startDate > today) return

    //set endDate to today if end is in future 
    let endDate = new Date(item?.end)
    if (endDate > today) endDate = today

    const stringStartDate = startDate.toISOString().slice(0, 10)
    const stringEndDate = endDate.toISOString().slice(0, 10)

    const data = {
      start_date: stringStartDate,
      end_date: stringEndDate,
      lineitem_id: id,
      key: "AMNYMFeh4ab97UqvNK7YU22j8sG5ftpfVCrQ53Ygs5YZQ2S9Zj"
    };
    const res = await fetch(`${getBackendSubDomain('krv')}/gam/reports/keyvalues`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(e => e.json());

    //remove first row, cast to ints 
    if (res && res && res?.publisher?.length > 1) {
        const arr = res.publisher.map((i) => {
            return { site: i[0], impressions: +i[1], clicks: +i[2] }
        })
        return { arr: arr, date: new Date() }
    }
}

export const initData = (item) => {
    if (item?.kv?.data?.publisher && item?.kv?.date) {
        const obj = {}
        obj['arr'] = item?.kv?.data?.publisher.map((i) => {
            return { site: i[0], impressions: +i[1], clicks: +i[2] }
        })
        obj['date'] = new Date(item?.kv?.date?.['$date'])
        return obj
    }
    return null
}