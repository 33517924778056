import React, { useState, createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Grid,
    IconButton,
    Collapse,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    useTheme,
    useMediaQuery,
    Chip,
    Link,
} from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAdvertiserHandler } from '../../functions/hooks/advertiserHandler';
import { DefaultPage, PaperBody, DefaultBodyHeader, DefaultTooltip } from '../DefaultComponents';
import { RefreshButton } from '../RefreshButton';
import { OrderTable } from './OrderTable';
import { LocalStorageContext } from '../../App';
import { SkeletonCustomer } from '../Skeletons';
import { DoohCampaignTable } from './DoohCampaignTable';
import { CreateCombinedReport } from '../combinedReport/CreateCombinedReport';
import { ViewCombinedReports } from '../combinedReport/ViewCombinedReports';

export const SortingContext = createContext(null);

export const Customer = () => {
    const { id } = useParams();
    const [forceUpdate, setForceUpdate] = useState(false);

    const [advertiser, loading] = useAdvertiserHandler(id, forceUpdate);

    const [openSort, setOpenSort] = useState(false);
    const [sorting, setSorting] = useState({ column: 'start', ascending: false, filter: 'all' });

    const [combinedReport, setCombinedReport] = useState([]);
    const handleCombinedReport = (lineitemIds, add) => {
        if (add) {
            const newIds = lineitemIds.filter(id => !combinedReport.includes(id));
            setCombinedReport([...combinedReport, ...newIds]);
        }
        else {
            const newArr = combinedReport.filter(id => !lineitemIds.includes(id));
            setCombinedReport(newArr);
        }
    }

    const showColumns = useMediaQuery(useTheme().breakpoints.up('md'));

    // eslint-disable-next-line no-unused-vars
    const { localStorage, setLocalStorage } = { ...useContext(LocalStorageContext) };
    useEffect(() => {
        if (advertiser?.name) setLocalStorage('recentAdvertisers', { id: advertiser?.id, name: advertiser?.name });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertiser]);

    if (loading) return <SkeletonCustomer />
    else return (
        advertiser && (
            <SortingContext.Provider value={{ sorting: sorting, setSorting: setSorting }}>
                <DefaultPage>
                    <PaperBody paperSx={{ background: '#0E3C64' }}>
                        <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                            <Grid item container xs direction='column'>
                                <Grid item container xs spacing={2}>
                                    <Grid item>
                                        <Typography variant='h5' color='white'>
                                            {advertiser?.name ?? id}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {advertiser.labels.map(label => (
                                            <Chip variant='outlined' color={'secondary'} key={label.id} label={label?.name} />
                                        ))}
                                    </Grid>
                                </Grid>
                                {advertiser?.salesRep && (
                                    <Grid item xs>
                                        <Typography color='white'>
                                            {advertiser?.salesRep}, {advertiser?.team ?? 'Okänt team'}
                                        </Typography>
                                    </Grid>
                                )}
                                {advertiser?.externalId && (
                                    <Grid item xs>
                                        <Link
                                            variant='p'
                                            color={blueGrey[300]}
                                            sx={{ '&:hover': { color: blueGrey[400] } }}
                                            underline='none'
                                            href={`https://astracrm.lightning.force.com/lightning/r/Account/${advertiser.externalId}/view`}
                                            target='blank'
                                            rel='noreferrer'
                                        >
                                            Gå till kunden i Salesforce
                                        </Link>
                                    </Grid>
                                )}
                            </Grid>
                            <HeaderButtons advertiser={advertiser} updateData={() => setForceUpdate(!forceUpdate)} />
                        </Grid>
                    </PaperBody>
                    <PaperBody>
                        <Grid container direction='column'>
                            <DefaultBodyHeader>
                                <Typography variant='h5' sx={{ color: grey[700] }}>
                                    Ordrar
                                </Typography>
                                {showColumns ? (
                                    <FilterRadioGroup />
                                ) : (
                                    <IconButton onClick={() => setOpenSort(!openSort)}>
                                        <SortIcon sx={{ transform: 'scaleX(-1)' }} />
                                    </IconButton>
                                )}
                            </DefaultBodyHeader>
                            <Collapse in={openSort} timeout='auto'>
                                <Grid item container spacing={2} sx={{ py: 1 }}>
                                    <Grid item xs>
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id='sort-by-select-label'>Sortera på</InputLabel>
                                            <Select
                                                labelId='sort-by-select-label'
                                                id='sort-by-select'
                                                value={sorting.column}
                                                label='Sortera på'
                                                onChange={e => setSorting({ ...sorting, column: e.target.value })}
                                            >
                                                <MenuItem value={'name'}>Namn</MenuItem>
                                                <MenuItem value={'start'}>Startdatum</MenuItem>
                                                <MenuItem value={'end'}>Slutdatum</MenuItem>
                                                <MenuItem value={'totalImpressionsDelivered'}>Visningar</MenuItem>
                                                <MenuItem value={'totalClicksDelivered'}>Klick</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => setSorting({ ...sorting, ascending: !sorting.ascending })}>
                                            {sorting.ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                        </IconButton>
                                    </Grid>
                                    <Grid item sx={{ ml: 0.5 }}>
                                        <FilterRadioGroup />
                                    </Grid>
                                </Grid>
                            </Collapse>
                            <Grid container justifyContent='flex-end' spacing={1} mb={1}>
                                <ViewCombinedReports reports={advertiser?.combinedReports} />
                                <CreateCombinedReport lineitemIds={combinedReport} />
                            </Grid>
                            {advertiser?.orders?.length > 0 && <OrderTable orders={advertiser?.orders} showColumns={showColumns} combinedReport={combinedReport} handleCombinedReport={handleCombinedReport} />}
                            {advertiser?.doohCampaigns?.length > 0 && <DoohCampaignTable campaigns={advertiser?.doohCampaigns} showColumns={showColumns} />}
                        </Grid>
                    </PaperBody>
                </DefaultPage>
            </SortingContext.Provider>
        )
    );
};

const FilterRadioGroup = () => {
    const sc = useContext(SortingContext);

    return (
        <FormControl>
            <RadioGroup
                row
                name='filter-radio-buttons-group'
                value={sc.sorting.filter}
                onChange={event => sc.setSorting({ ...sc.sorting, filter: event.target.value })}
            >
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='all' control={<Radio size='small' color='accent' />} label='Alla' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='upcoming' control={<Radio size='small' color='accent' />} label='Kommande' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='ongoing' control={<Radio size='small' color='accent' />} label='Pågående' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='finished' control={<Radio size='small' color='accent' />} label='Avslutade' />
            </RadioGroup>
        </FormControl>
    );
};

export const FavoriteButton = ({ advertiser }) => {
    const { localStorage, setLocalStorage } = { ...useContext(LocalStorageContext) };
    const isFavorite = localStorage?.favoriteAdvertisers?.find(a => a.id === advertiser.id) ? true : false;

    return (
        <DefaultTooltip title={isFavorite ? 'Ta bort favoritmarkering' : 'Favoritmarkera kund'}>
            <IconButton onClick={() => setLocalStorage('favoriteAdvertisers', { id: advertiser?.id, name: advertiser?.name })}>
                {isFavorite ? <FavoriteIcon color='error' /> : <FavoriteBorderIcon color='error' />}
            </IconButton>
        </DefaultTooltip>
    );
};

export const FavoriteButtonIconOnly = () => <FavoriteIcon color='error' />;

const HeaderButtons = ({ advertiser, updateData }) => {
    const theme = useTheme();
    const isXsSize = useMediaQuery(theme.breakpoints.down('sm'));

    if (isXsSize)
        return (
            <>
                <Grid item>
                    <Grid container justifyContent='flex-end'>
                        <FavoriteButton advertiser={advertiser} />
                    </Grid>
                </Grid>
                <Grid item>
                    <RefreshButton
                        collection='orders'
                        collectionKey='advertiserId'
                        id={advertiser?.id}
                        updateData={updateData}
                        lastUpdated={advertiser?.orders?.[0]?.timestamp ?? advertiser?.timestamp}
                    />
                </Grid>
            </>
        );
    else
        return (
            <>
                <Grid item>
                    <RefreshButton
                        collection='orders'
                        collectionKey='advertiserId'
                        id={advertiser?.id}
                        updateData={updateData}
                        lastUpdated={advertiser?.orders?.[0]?.timestamp ?? advertiser?.timestamp}
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent='flex-end'>
                        <FavoriteButton advertiser={advertiser} />
                    </Grid>
                </Grid>
            </>
        );
};