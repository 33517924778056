import React, { useEffect, useState } from 'react';
import { DataGrid, svSE } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { formatDate, caluclateLineitemCTR } from '../../functions/misc';
import { DeliveryProgressBar, getDeliveryDifference } from '../DeliveryIndicators';
import { LineItemRow, MissingCreativesTooltip, TargetingTooltip } from './LineItems';
import { useXsSize } from '../../material/theme';

const columns = [
    { field: 'name', headerName: 'AD-nummer', width: 150, valueGetter: params => params.row?.externalId ?? params.row?.name ?? params.row?.id },
    { field: 'advertiser', headerName: 'Kund', width: 300, valueGetter: params => params.row?.advertiser?.name, },
    { field: 'start', headerName: 'Startdatum', width: 100, valueGetter: params => formatDate(params.row?.start), },
    { field: 'end', headerName: 'Slutdatum', width: 100, valueGetter: params => formatDate(params.row?.end), },
    { field: 'delivery', headerName: 'Leverans', width: 150, renderCell: params => <DeliveryProgressBar lineitem={params.row} size='small' />, valueGetter: params => getDeliveryDifference(params.row) },
    { field: 'impressionsDelivered', headerName: 'Visningar', width: 100, valueGetter: params => params.row?.stats?.impressionsDelivered ?? 0, renderCell: params => params.row?.stats?.impressionsDelivered?.toLocaleString('sv-SE') ?? 0 },
    { field: 'clicksDelivered', headerName: 'Klick', width: 80, valueGetter: params => params.row?.stats?.clicksDelivered ?? 0, renderCell: params => params.row?.stats?.clicksDelivered?.toLocaleString('sv-SE') ?? 0 },
    { field: 'ctr', headerName: 'CTR', width: 80, valueGetter: params => caluclateLineitemCTR(params.row), renderCell: params => `${caluclateLineitemCTR(params.row).toFixed(2)}%` },
    { field: 'targeting', headerName: 'Styrning', width: 100, sortable: false, renderCell: params => <TargetingTooltip lineitem={params.row} /> },
    { field: 'missingCreatives', headerName: 'Material', width: 100, sortable: false, renderCell: params => <MissingCreativesTooltip show={params.row?.creatives?.length === 0} /> },
    { field: 'branch', headerName: 'Bransch', width: 150, valueGetter: params => params.row?.advertiser?.labels?.[0]?.name.replace('Bransch: ', '') },
    { field: 'salesRep', headerName: 'Kundansvarig', width: 150, valueGetter: params => params.row?.advertiser?.salesforce?.salesRep },
    { field: 'team', headerName: 'Team', width: 150, valueGetter: params => params.row?.advertiser?.salesforce?.team },
    { field: 'division', headerName: 'Division', width: 150, valueGetter: params => params.row?.advertiser?.salesforce?.division },
    { field: 'mobileView', headerName: 'Kampanjer', width: 500, sortable: false, renderCell: params => <LineItemRow lineitem={params.row} /> },
];

const defaultTableSettings = {
    sort: [
        {
            field: 'name',
            sort: 'asc',
        },
    ],
    pagination: {
        page: 0,
        pageSize: 10,
    },
};

const getTableSettings = () => {
    const lts = sessionStorage.getItem('lineitems-table-settings');
    return lts ? JSON.parse(lts) : defaultTableSettings;
};
const usePagination = () => {
    const [tableSettings, setTableSettings] = useState(getTableSettings());

    useEffect(() => {
        sessionStorage.setItem('lineitems-table-settings', JSON.stringify(tableSettings));
    }, [tableSettings]);

    return { tableSettings, setTableSettings };
};

export const LineItemsTable = (props) => {
    const { items, loading } = props;
    const { tableSettings, setTableSettings } = usePagination();
    const isMobile = useXsSize();
    const navigate = useNavigate();

    const handleTableSettings = (key, value) => {
        setTableSettings({
            ...tableSettings,
            [key]: value,
        });
    };

    const handleSortChange = value => {
        setTableSettings({
            ...tableSettings,
            sort: value,
            pagination: {
                ...tableSettings.pagination,
                page: 0,
            },
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                localeText={{ ...svSE.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: "Inga kampanjer" }}
                loading={loading}
                sortModel={tableSettings.sort}
                onSortModelChange={handleSortChange}
                paginationModel={tableSettings.pagination}
                onPaginationModelChange={value => handleTableSettings('pagination', value)}
                rows={items}
                columns={columns.filter(c => isMobile ? c?.field === "mobileView" : c?.field !== "mobileView")}
                onRowClick={params => navigate(`/kampanj/${params.row.externalId ?? params.id}`)}
                pageSizeOptions={[10, 25, 50]}
                rowHeight={isMobile ? 90 : 52}
                autoHeight
            // disableColumnMenu
            />
        </Box>
    );
}