import React, { useContext } from 'react';
import PublicIcon from '@mui/icons-material/Public';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { UserContext } from '../../App';
import { Tooltip } from '@mui/material';

export const InternalUserIcon = props => {
    const user = useContext(UserContext);
    const isInternal = user?.isInternal;

    const str = isInternal ? 'Ansluten till det interna nätverket' : 'Ej ansluten till det interna nätverket';

    return (
        <Tooltip
            title={str}
            arrow
            placement='left'
            disableInteractive
            enterTouchDelay={0}
        >
            {isInternal ? <VpnLockIcon color='secondary' /> : <PublicIcon color='secondary' />}
        </Tooltip>
    );
};
