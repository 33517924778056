import React from "react";
import { Chip, Typography, Grid, Box, Card, CardHeader, CardContent, Divider, Stack } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import { PaperBody, DefaultCard } from "../../DefaultComponents";
import { grey, red } from "@mui/material/colors";
import { SiteImage } from "../../SiteImage";
import { SiteBreakDown } from "./SiteBreakDown";
import { useMobile } from "../../../material/theme";
import { CustomInfoIcon } from "../../InfoIcon";

export const TargetingGrid = ({ item, combinedReport }) => {
    const isMobile = useMobile();
    const ctArray = item?.customTargeting?.filter(ct => ct?.key?.name !== "up_recovery"); 

    return (
        <PaperBody>
            <Typography variant="h5" sx={{ color: grey[600] }}>
                Styrning
            </Typography>
            <Grid container spacing={isMobile ? 2 : 0} justifyContent="space-evenly">
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard title="Format" infoText="Visar annonsens format, till exempel storlek eller typ (t.ex. Native, Display).">
                        <Format placements={item?.placements} adunits={item?.adunits} />
                    </DefaultCard>
                </Grid>
                <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                    <Divider orientation="vertical" variant="middle" sx={{ height: "95%" }} />
                </Box>
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard title="Siter" infoText="Anger vilka sajter annonsen är riktad mot. Detta hjälper till att identifiera publiceringsmiljön.">
                        <SiteChips placements={item?.placements} adunits={item?.adunits} />
                        {!combinedReport && (
                            <Box sx={{ textAlign: "center" }}>
                                <SiteBreakDown item={item} />
                            </Box>
                        )}
                    </DefaultCard>
                </Grid>
                {item?.placements?.length > 0 && ctArray?.length > 0 && (
                    <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                        <Divider orientation="vertical" variant="middle" sx={{ height: "95%" }} />
                    </Box>
                )}
                {ctArray?.map((ct, i) => {
                    const isNot = ct?.operator === "IS_NOT";

                    if (ct?.key?.name !== "up_recovery")
                        return (
                            <React.Fragment key={ct.key?.id}>
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Card variant="outlined" sx={{ textAlign: "center", border: "none" }}>
                                        <CardHeader
                                            title={
                                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                    <span>{ct?.key?.displayName ?? ct?.key?.name ?? ct?.key?.id}</span>
                                                    {ct?.key?.displayName === "Geo Kommun" && (
                                                        <CustomInfoIcon
                                                            text="Geo kommun avser geografisk inriktning baserat på användarens postkod. Detta gör det möjligt att rikta annonser till specifika kommuner eller områden."
                                                            sx={{ pb: 0.25 }}
                                                        />
                                                    )}
                                                    {ct?.key?.displayName === "Geo Glimr" && (
                                                        <CustomInfoIcon
                                                            text="Geo Glimr använder Glimrs spårningslösning för att bestämma användarens geografiska område. Detta ger mer exakt inriktning baserat på användarens beteende och platsdata."
                                                            sx={{ pb: 0.25 }}
                                                        />
                                                    )}
                                                </Stack>
                                            }
                                            sx={{ textTransform: "uppercase", color: grey[600] }}
                                        />
                                        <CardContent>
                                            {isNot && (
                                                <CustomInfoIcon
                                                    isWarning={true}
                                                    text="Kampanjen styrs mot besökare som inte täcks av något av följande värden"
                                                    sx={{ pb: 0.25 }}
                                                />
                                            )}
                                            {ct.values.map(v => (
                                                <Chip
                                                    key={v?.id}
                                                    icon={isNot ? <BlockIcon /> : null}
                                                    label={v?.displayName ?? v?.name}
                                                    color={isNot ? "secondary" : "primary"}
                                                    variant={isNot ? "outlined" : "default"}
                                                    sx={{ "mx": 0.25, "mb": 0.5, "& .MuiChip-icon": { color: isNot ? red[700] : "inherit" } }}
                                                />
                                            ))}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {i !== ctArray?.length - 1 && (
                                    <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                        <Divider orientation="vertical" variant="middle" sx={{ height: "95%" }} />
                                    </Box>
                                )}
                            </React.Fragment>
                        );
                })}
            </Grid>
        </PaperBody>
    );
};

const Format = ({ placements, adunits }) => {
    if (placements?.length === 0 && adunits?.length === 0) {
        return (
            <Typography variant="h5" gutterBottom>
                Inget format
            </Typography>
        );
    }

    const uniqueFormats = [...new Set(placements?.map(p => p?.split(/ (.*)/s)[1])), ...new Set(adunits?.map(a => a?.split("-")?.slice(1, 3)?.join(" ")))];
    return uniqueFormats?.map(format => <Chip key={format} label={format} sx={{ mx: 0.25, mb: 0.5 }} color="primary" variant="outlined" />);
};

const SiteChips = ({ placements, adunits }) => {
    if (placements?.length === 0 && adunits?.length === 0) {
        return <Chip label="Ingen site" sx={{ mx: 0.25, mb: 0.5 }} color="secondary" variant="outlined" />;
    }

    const uniqueSites = [...new Set(placements?.map(p => p?.split(" ")[0])), ...new Set(adunits?.map(a => a?.split("-")[0]))];

    if (uniqueSites.length >= 19) {
        return <Chip label="Alla sajter" sx={{ mx: 0.25, mb: 0.5 }} avatar={<SiteImage site="ntm" />} variant="outlined" color="secondary" />;
    }

    return uniqueSites?.map(site => {
        return <Chip key={site} label={site} sx={{ mx: 0.25, mb: 0.5 }} avatar={<SiteImage site={site} />} color="secondary" variant="outlined" />;
    });
};
