import { useLocation, useParams } from "react-router-dom"
import { ConfigTools } from "./ConfigTools"
import { useEffect, useState } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { DefaultBody, DefaultPage, HeaderWithText } from "../../DefaultComponents"
import { flattenedLayout } from "../../../config/navigation"
import Upload from "./FileUpload"
import { getBackendSubDomain } from "../../../functions/subDomain"


//This will replace a fetch on ea screen
const defaultConfig = {
    "left": 47,
    "top": 31,
    "width": 23,
    "height": 15,
    "rotateX": 10,
    "rotateY": -49,
    "rotateZ": 0,
    "perspective": 1122,
    "opacity": 1
}

export const ScreenPreview = ({ }) => {
    const { screenId } = useParams()
    const info = {
        id: "doohpreview",
        text: 'Dooh förhandsvisning',
        route: '/verktyg/doohfhv',
        public: false,
        description: 'Dooh förhandsvisning',
    }
    const [material, setMaterial] = useState(null)
    const [background, setBackground] = useState(null)
    // const [position, setPosition] = useState(null)
    const [generatedLink, setGeneratedLink] = useState('');
    const location = useLocation();

    const [data, setData] = useState({screen: location?.state?.screen, config: defaultConfig});

    const fetchMaterial = async (id) => {
        try {
            const response = await fetch(`${getBackendSubDomain('nadir')}/files/download/${id}`);
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setMaterial({id:id, url:url});
            } else {
                console.error('Failed to fetch image:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    useEffect(() => {
        async function getData(id) {
            const screenData = await fetch(`${getBackendSubDomain('nadir')}/doohclick/screen/${id}`)
                .then(e => e.json())
            setData({screen: screenData, config: defaultConfig});
        }
        getData(screenId);
    }, []);


    //3 routes 
    // (fhv/ -välj skylt )
    // fhv/screenId/ ladda upp material -> skapa länk till ...
    // fhv/screenId/materialId bara visa, visa lite skylt info i feta vita bokstäver. 

    // const load = async () => {
    //     const screen = screens['default']
    //     setBackground(screen.url)
    //     setPosition(screen.config)

    //     // await fetchMaterial(materialId)

    // }

    const generateLink = () => {
        if (screenId && material?.id) {
            const link = `${window.location.origin}/dooh/skylt/${screenId}/preview/${material.id}`;
            setGeneratedLink(link);
        }
    };

    console.log(data?.screen?.screenTenant?.azureUrl);
    // useEffect(() => {
    //     screenId && load()
    // }, [screenId])

    return <DefaultPage>
        <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
        <DefaultBody>
            {data?.screen && data?.config && <Grid container item xs={12}><Grid item xs={12}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: 'auto',
                    perspective: `${data?.config?.perspective}px`
                }}>
                    <img src={data?.screen?.screenTenant?.azureUrl} style={{ width: '100%', display: 'block' }} />
                    {material && <img src={material.url} style={{
                        position: 'absolute',
                        left: `${data?.config?.left}%`,
                        top: `${data?.config?.top}%`,
                        width: `${data?.config?.width}%`,
                        height: `${data?.config?.height}%`,
                        opacity: data?.config?.opacity,
                        transform: `rotateX(${data?.config?.rotateX}deg) rotateY(${data?.config?.rotateY}deg) rotateZ(${data?.config?.rotateZ}deg)`,
                        transformStyle: 'preserve-3d'
                    }} />}
                </div>
            </Grid></Grid>}
            <Upload update={(id) => fetchMaterial(id)} />
            {/* create a btn generate link which crea  */}
            <Grid item xs={1}>
                    <Button variant='contained' sx={{ height: 40 }} disableElevation fullWidth onClick={generateLink} disabled={!material}>
                        Generera länk
                    </Button>
                </Grid>
                {generatedLink && (
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography variant="body1">Generated Link: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a></Typography>
                    </Grid>
                )}
            <ConfigTools position={data?.config} setPosition={(p) => setData({...data, config: p})} />
        </DefaultBody>
    </DefaultPage>
}

//replace with req to a db or a table with this information on every screen