import React, { useEffect, useState } from 'react';
import { DataGrid, svSE } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const columns = [
    // { field: 'id', headerName: 'gamid', width: 140 },
    { field: 'name', headerName: 'Kundnamn', width: 200 },
    { field: 'salesRep', headerName: 'Kundansvarig', width: 150 },
    { field: 'team', headerName: 'Team', width: 150 },
    { field: 'division', headerName: 'Division', width: 150 },
    {
        field: 'bransch',
        headerName: 'Bransch',
        sortable: false,
        width: 400,
        valueGetter: params => `${params.row.labels.map(l => l.name.replace('Bransch: ', '')).join(',') || ''}`,
    },
];

const defaultTableSettings = {
    sort: [
        {
            field: 'name',
            sort: 'asc',
        },
    ],
    pagination: {
        page: 0,
        pageSize: 10,
    },
};

const getTableSettings = () => {
    const cts = sessionStorage.getItem('customers-table-settings');
    return cts ? JSON.parse(cts) : defaultTableSettings;
};

const usePagination = () => {
    const [tableSettings, setTableSettings] = useState(getTableSettings());

    useEffect(() => {
        sessionStorage.setItem('customers-table-settings', JSON.stringify(tableSettings));
    }, [tableSettings]);

    return { tableSettings, setTableSettings };
};

export const CustomersTable = (props) => {
    const { items, loading } = props;
    const { tableSettings, setTableSettings } = usePagination();
    const navigate = useNavigate();

    const handleTableSettings = (key, value) => {
        setTableSettings({
            ...tableSettings,
            [key]: value,
        });
    };

    const handleSortChange = value => {
        setTableSettings({
            ...tableSettings,
            sort: value,
            pagination: {
                ...tableSettings.pagination,
                page: 0,
            },
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                localeText={{ ...svSE.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: "Inga kunder" }}
                loading={loading}
                sortModel={tableSettings.sort}
                onSortModelChange={handleSortChange}
                paginationModel={tableSettings.pagination}
                onPaginationModelChange={value => handleTableSettings('pagination', value)}
                rows={items}
                columns={columns}
                onRowClick={params => navigate(`/kund/${params.id}`)}
                pageSizeOptions={[10, 25, 50]}
                autoHeight
                disableColumnMenu
            />
        </Box>
    );
}
