import { Customer } from "../components/customer/Customer";
import { Home } from "../components/home/Home";
import { Lineitem } from "../components/lineitem/LineItem";
import { NotFound } from "../components/NotFound";
import { DoohScreens } from "../components/dooh/DoohScreens";
import { DoohScreen } from "../components/dooh/DoohScreen";
import { GroupParentPage } from "../components/DefaultComponents";
import { Dooh } from "../components/forecast/Dooh";
import { CpmTool } from "../components/tools/CpmTool";
import { flattenedLayout } from "./navigation";
import { Standard } from "../components/forecast/Standard";
import { Customers } from "../components/customer/Customers";
import { LineItems } from "../components/campaigns/LineItems";
import { AiAdGenerator } from "../components/tools/AiAdGenerator";
import { TimeOverview } from "../components/forecast/TimeOverview";
import { CombinedReport } from "../components/combinedReport/CombinedReport";
import { RunsDashboard } from "../components/admin/RunsDashboard";
import { AverageStats } from "../components/tools/AverageStats";
import { DoohPreview } from "../components/dooh/DoohPreview";
import { ScreenPreview } from "../components/dooh/preview/ScreenPreview";
import { ScreenMaterialPreview } from "../components/dooh/preview/ScreenMaterialPreview";
import DoohCalc from "../components/tools/DoohCalc";
import Dashboard from "../components/dashboard/Dashboard";

const routesArr = [
    { path: "/", public: false, component: <Home /> },
    { path: "/admin/runs", public: false, component: <RunsDashboard /> },
    { path: "/kund/:id", public: false, component: <Customer /> },
    { path: "/kampanj/:id", public: false, component: <Lineitem /> },
    { path: "/kampanjer", public: false, component: <LineItems /> },
    { path: "/kampanjer/kombinera/:reportId", public: false, component: <CombinedReport /> },
    { path: "/extern/kombinera/:reportId", public: true, component: <CombinedReport /> },
    { path: "/kunder", public: false, component: <Customers /> },
    { path: "/lagerkoll/", public: true, component: <GroupParentPage info={flattenedLayout.forecast} /> },
    // { path: '/lagerkoll/sajt', public: true, component: <TimeOverview forecastType='site' /> },
    { path: "/lagerkoll/produkt", public: true, component: <TimeOverview forecastType="product" /> },
    {
        path: "/lagerkoll/totallager-produkt",
        public: true,
        component: (
            <TimeOverview
                forecastType="product"
                headerInfo={{
                    text: "Totallager produkt",
                    description: "Visar totalt lager för en viss produkt på samtliga sajter, utan att ta höjd för antal creatives",
                }}
                useSizes={false}
            />
        ),
    },
    { path: "/lagerkoll/standard", public: true, component: <Standard /> },
    { path: "/lagerkoll/dooh", public: true, component: <Dooh /> },
    { path: "/verktyg/", public: true, component: <GroupParentPage info={flattenedLayout.tools} /> },
    { path: "/verktyg/cpm", public: true, component: <CpmTool /> },
    { path: "/verktyg/snittstatistik", public: false, component: <AverageStats /> },
    { path: "/verktyg/annons-ai", public: false, component: <AiAdGenerator /> },
    { path: "/verktyg/dooh", public: true, component: <DoohCalc /> },
    { path: "/extern/:id", public: true, component: <Lineitem /> },
    { path: "/dooh/skyltar", public: true, component: <DoohScreens /> },
    { path: "/dooh/skylt/:id", public: true, component: <DoohScreen /> },
    { path: "/dooh/skylt/:screenId/preview", public: true, component: <ScreenPreview /> },
    { path: "/dooh/skylt/:screenId/preview/:materialId", public: true, component: <ScreenMaterialPreview /> },
    { path: "/dashboard", public: true, component: <Dashboard/> },
    { path: "*", public: true, component: <NotFound /> },
];

export const getRoutes = isInternal => {
    if (isInternal) return routesArr;
    else return routesArr.filter(r => r.public);
};
