import React from 'react';
import { Checkbox } from '@mui/material';
import { DefaultTooltip } from '../DefaultComponents';

export const OrderCheckbox = ({ lineitemIds, combinedReport, handleCombinedReport }) => {
    const someSelected = lineitemIds.some(id => !combinedReport.includes(id)) && lineitemIds.some(id => combinedReport.includes(id));
    const allSelected = lineitemIds.every(id => combinedReport.includes(id));

    return (
        <DefaultTooltip title={`${allSelected ? 'Ta bort alla orderns kampanjer från' : 'Lägg till alla orderns kampanjer i'} kombinerad rapport`}>
            <Checkbox
                indeterminate={someSelected}
                checked={allSelected}
                onClick={(e) => {
                    handleCombinedReport(lineitemIds, !allSelected);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                disableRipple
                size='small'
                sx={{ p: 0, mr: 0.75 }}
                color="accent" />
        </DefaultTooltip>
    );
};

export const LineitemCheckbox = ({ lineitemId, combinedReport, handleCombinedReport }) => {
    const isSelected = combinedReport.includes(lineitemId);

    return (
        <DefaultTooltip title={`${isSelected ? 'Ta bort från' : 'Lägg till i'} kombinerad rapport`}>
            <Checkbox
                checked={isSelected}
                onClick={(e) => {
                    handleCombinedReport([lineitemId], !isSelected)
                    e.stopPropagation();
                    e.preventDefault();
                }}
                disableRipple
                size='small'
                sx={{ p: 0, mr: 0.75 }}
                color="accent"
            />
        </DefaultTooltip>
    );
}