//type timeInScreen eller interactions
export const sumArrayInteraction = (type, lineitem, creativeId = null) => {
    if (!['timeInScreen', 'interactions'].includes(type)) return null;
    if (!('interactions' in lineitem)) return null;
    let interactions = lineitem?.interactions;
    if (creativeId) interactions = interactions.filter(a => a?.creativeId === creativeId.toString());
    const result = interactions.reduce((a, b) => a + (b?.[type] ?? 0), 0);
    return result === 0 ? null : result;
};

export const sumArrayClicks = (lineitem, creativeId = null) => {
    if (!('interactions' in lineitem)) return null;
    let interactions = lineitem?.interactions;
    if (creativeId) interactions = interactions.filter(a => a?.creativeId === creativeId.toString());
    if (!(interactions.some((interaction) => interaction.hasOwnProperty("clicks")))) return null;
    const clicks = interactions.filter(i => i?.clicks).flatMap(iwc => iwc.clicks).reverse();
    const totalClicksPerImage = {}
    clicks.forEach(click => {
        if (totalClicksPerImage.hasOwnProperty(click.id)) totalClicksPerImage[click.id].value += click.value;
        else {
            const newClick = { ...click };
            delete newClick.id;
            totalClicksPerImage[click.id] = newClick;
        }
    });
    const totalClicks = clicks.reduce((a, b) => a + (b?.value ?? 0), 0);
    return { perImage: totalClicksPerImage, total: totalClicks };
};

export const toUpperIfLongString = (str) => str?.length > 20 ? str : str.toUpperCase()

export const sortByExternalIdOrDate = (arr) => {
    const sortKey = arr.every(item => item.externalId) ? "externalId" : "start";
    arr.sort((a, b) => {
        if (a[sortKey] > b[sortKey]) return 1;
        if (a[sortKey] < b[sortKey]) return -1;
        return 0;
    });
    return arr;
};