import { TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

export const GoToLineItem = () => {
    const [lineItem, setLineItem] = useState(null);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();
        setError(false);

        const sfRegex = /AD-\d{6}-\d{4}/gi;
        const adbRegex = /000\d{7}-\d{2}/gi;
        if ((sfRegex.test(lineItem.trim()) && lineItem.length === 14) || (adbRegex.test(lineItem.trim()) && lineItem.length === 13)) {
            navigate(`/kampanj/${lineItem.trim()}`)
        } else if (lineItem.length === 10 && typeof parseInt(lineItem) === 'number') {
            navigate(`/kampanj/${lineItem.trim()}`)
        } else {
            setError(true);
        }
    };

    const randomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    return (
        <form onSubmit={handleSubmit}>
            <Tooltip placement='top' arrow open={error} title='Felaktigt kampanjnummer' disableInteractive>
                <TextField
                    fullWidth
                    error={error}
                    placeholder={`t.ex. AD-00${randomInt(1000, 9999)}-000${randomInt(1, 5)}`}
                    onInput={e => {
                        if (error) setError(false);
                        setLineItem(e.target.value);
                    }}
                    variant='outlined'
                    InputProps={{
                        autoComplete: 'ad-campaign-number',
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton type='submit'>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Tooltip>
        </form>
    );
};
