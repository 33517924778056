import { useState, useEffect, useContext } from 'react';
import { getBackendSubDomain } from '../subDomain';

export const useLineitem = (id, forceUpdate) => {
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchLineItem = async (load = true) => {
        const isADLink = !/==$/.test(id)
        return fetch(`${getBackendSubDomain('nadir')}/gam/${isADLink ? 'lineitem' : 'external'}/${id}`, {
            headers: {
                'content-type': 'application/json',
            },
            method: 'GET',
        })
            .then(res => res.json())
            .catch(err => console.warn(err));
    };

    const f = async () => {
        !item && setLoading(true);
        //looking for id in nadir
        const lineitem = await fetchLineItem();
        if (lineitem) {
            setItem(lineitem);
            setLoading(false);
            return
        }
        setLoading(false);
    };

    useEffect(() => {
        f();
    }, [id, forceUpdate]);

    return { item, loading };
};
