import { useState, useEffect } from 'react';
import { getBackendSubDomain } from '../subDomain';

export const useCombinedReport = (reportId, lineitemIds, isPublic) => {
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        const isNewReport = lineitemIds && reportId === "ny";
        if (isNewReport) {
            return fetch(`${getBackendSubDomain('nadir')}/gam/combined/new`, {
                headers: { 'content-type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({
                    lineitemIds: lineitemIds
                }),
            })
                .then(res => res.json())
                .catch(err => console.warn(err));
        }
        else {
            return fetch(`${getBackendSubDomain('nadir')}/gam/combined/get/${isPublic ? "external/" : ""}${reportId}`, {
                headers: { 'content-type': 'application/json' },
                method: 'GET',
            })
                .then(res => res.json())
                .catch(err => console.warn(err));
        }
    };

    const f = async () => {
        !report && setLoading(true);
        const data = await fetchData();
        if (data) {
            setReport(data);
            setLoading(false);
            return
        }
        setLoading(false);
    };

    useEffect(() => {
        f();
    }, []);

    return { report, loading };
};
