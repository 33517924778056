import React, { useContext, useState } from 'react';
import { Box, IconButton, Typography, Grid, SwipeableDrawer, List, ListItem, ListItemButton, ListItemText, Collapse, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { InternalUserIcon } from './InternalUserIcon';
import { UserContext } from '../../App';
import { NTMMediaIcon, NTMMediaLogo } from './Navbar';

export const MobileMenu = props => {
    const { layout } = props;

    const [openDrawer, setOpenDrawer] = useState(false);

    const navigate = useNavigate();

    const goToPage = route => {
        navigate(route);
        setOpenDrawer(false);
    };

    return (
        <Grid container alignItems='center'>
            <Grid item xs={2}>
                <IconButton size='large' aria-controls='mobile-drawer' aria-haspopup='true' onClick={() => setOpenDrawer(true)} color='inherit'>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer id='mobile-drawer' anchor={'left'} open={openDrawer} onClose={() => setOpenDrawer(false)} onOpen={() => setOpenDrawer(true)}>
                    <Box sx={{ width: "75vw" }}>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item sx={{ mt: 1, mb: 0.5, ml: 1.5 }}>
                                <Box sx={{ cursor: 'pointer' }} onClick={() => goToPage('/')}>
                                    <NTMMediaIcon />
                                </Box>
                            </Grid>
                            <Grid item sx={{ mt: 0.5, mr: 1.5 }}>
                                <IconButton
                                    size='large'
                                    aria-controls='mobile-drawer'
                                    aria-haspopup='true'
                                    onClick={() => setOpenDrawer(false)}
                                    sx={{ float: 'right' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} />
                        <List disablePadding>
                            {layout.map(l => (
                                <NavbarNavigation key={l.text} data={l} goToPage={goToPage} />
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Grid>
            <Grid item container xs={8} alignItems='center' justifyContent='center'>
                <Grid item>
                    <Box sx={{ cursor: 'pointer' }} p={0.5} mt={0.5} onClick={() => navigate('/')}>
                        <NTMMediaLogo />
                    </Box>
                </Grid>
            </Grid>
            <Grid item container xs justifyContent='flex-end' pr={1.5}>
                <InternalUserIcon />
            </Grid>
        </Grid>
    );
};

const NavbarNavigation = props => {
    return props.data.subRoutes ? <NavbarButtonGroup {...props} /> : <NavbarButton {...props} />;
};

const NavbarButton = props => {
    const { data, goToPage } = props;

    const location = useLocation();
    const isSelected = location.pathname === data.route;

    const user = useContext(UserContext);
    const userIsInternal = user?.isInternal;

    const showButton = data.public || userIsInternal;

    return (
        <>
            {showButton && (
                <ListItem disablePadding sx={{ backgroundColor: isSelected ? 'rgba(0,0,0,0.1)' : 'none' }}>
                    <ListItemButton onClick={() => goToPage(data.route)}>
                        <ListItemText primary={<Typography fontWeight='bold'>{data.text}</Typography>} />
                    </ListItemButton>
                </ListItem>
            )}
        </>
    );
};

const NavbarButtonGroup = props => {
    const { data, goToPage } = props;

    const location = useLocation();
    const isSelected = location.pathname === data.route;

    const [openCollapse, setOpenCollapse] = useState(true);

    const user = useContext(UserContext);
    const userIsInternal = user?.isInternal;

    const showButton = data.public || userIsInternal;

    return (
        <>
            {showButton && (
                <>
                    <ListItem
                        disablePadding
                        sx={{ backgroundColor: isSelected ? 'rgba(0,0,0,0.1)' : 'none' }}
                        secondaryAction={
                            <IconButton onClick={() => setOpenCollapse(!openCollapse)}>{openCollapse ? <ExpandLess /> : <ExpandMore />}</IconButton>
                        }
                    >
                        <ListItemButton>
                            <ListItemText onClick={() => goToPage(data.route)} primary={<Typography fontWeight='bold'>{data.text}</Typography>} />
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openCollapse} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {data.subRoutes.map(sr => {
                                const showSubButton = sr.public || userIsInternal;
                                const srIsSelected = location.pathname === sr.route;
                                if (showSubButton)
                                    return (
                                        <ListItem disablePadding key={sr.route} sx={{ backgroundColor: srIsSelected ? 'rgba(0,0,0,0.1)' : 'none' }}>
                                            <ListItemButton onClick={() => goToPage(sr.route)}>
                                                <ListItemText primary={
                                                    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                                                        <Grid item>
                                                            <Typography marginLeft={2}>{sr.text}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="caption" color="text.secondary">{sr.group}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                } />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                            })}
                        </List>
                    </Collapse>
                </>
            )}
        </>
    );
};
