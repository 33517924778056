import React, { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { getBackendSubDomain } from '../../../functions/subDomain';

const Upload = ({update}) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${getBackendSubDomain('nadir')}/files/upload`, {
        method: 'POST',
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        update(data.file_id)
      } else {
        const errorData = await response.json();
      }
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button variant="contained" component="label">
            Choose File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <TextField
            value={file ? file.name : ''}
            variant="outlined"
            placeholder="No file chosen"
            InputProps={{
              readOnly: true,
            }}
            sx={{ flexGrow: 1, fontSize: "8px" }}
          />
          <Button type="submit" variant="contained" color="primary">
            Upload
          </Button>
        </Box>
      </form>
      {message && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Upload;
