import React, { useState } from 'react';
import { Grid, Slider as MuiSlider, Typography, Box, TextField, Switch, FormControlLabel } from '@mui/material';

export const ConfigTools = ({position, setPosition}) => {

    const [configOpen, setConfigOpen] = useState(false)

    // State to manage JSON input
    const [jsonInput, setJsonInput] = useState(JSON.stringify(position, null, 2));

    // Update rotation, position or opacity based on the slider
    const handleSliderChange = (name, value) => {
        const updatedPosition = { ...position, [name]: value };
        setPosition(updatedPosition);
        setJsonInput(JSON.stringify(updatedPosition, null, 2));
    };

    // Handle JSON input change
    const handleJsonInputChange = (event) => {
        const newJson = event.target.value;
        setJsonInput(newJson);
        try {
            const newPosition = JSON.parse(newJson);
            setPosition(newPosition);
        } catch (error) {
            // Ignore parse errors
        }
    };


    return <> 
    <FormControlLabel
      control={
        <Switch
          checked={configOpen}
          onChange={() => setConfigOpen(!configOpen)}
          name="configOpen"
          color="primary"
        />
      }
      label="Positional Settings"
    />
    { configOpen && <Grid item xs={12}>
            <Typography>Adjust Position and Rotation</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Sliders to adjust position, size, and rotation */}
                <Grid container direction="row">

                    <Grid container item xs={4}>
                        <Typography>Rotation</Typography>
                        <Slider min={-180} max={180} label={'rotateX'} value={position.rotateX} func={(v) => handleSliderChange('rotateX', v)} />
                        <Slider min={-180} max={180} label={'rotateY'} value={position.rotateY} func={(v) => handleSliderChange('rotateY', v)} />
                        <Slider min={-180} max={180} label={'rotateZ'} value={position.rotateZ} func={(v) => handleSliderChange('rotateZ', v)} />
                    </Grid>

                    <Grid container item xs={4}>
                        <Typography>Position</Typography>
                        <Slider min={0} max={100} label={'left'} value={position.left} func={(v) => handleSliderChange('left', v)} />  {/* Change max to 100 */}
                        <Slider min={0} max={100} label={'top'} value={position.top} func={(v) => handleSliderChange('top', v)} />   {/* Change max to 100 */}
                    </Grid>

                    <Grid container item xs={4}>
                        <Typography variant='h5'>Size</Typography>
                        <Slider min={5} max={100} label={'width'} value={position.width} func={(v) => handleSliderChange('width', v)} />  {/* Adjust min and max */}
                        <Slider min={5} max={100} label={'height'} value={position.height} func={(v) => handleSliderChange('height', v)} /> {/* Adjust min and max */}
                    </Grid>

                    <Grid container item xs={4}>
                        <Typography>Other</Typography>
                        <Slider min={200} max={2000} label={'perspective'} value={position.perspective} func={(v) => handleSliderChange('perspective', v)} />
                        <Slider min={0} max={1} step={0.01} label={'opacity'} value={position.opacity} func={(v) => handleSliderChange('opacity', v)} />
                    </Grid>

                    <Grid container item xs={8}>
                        <Typography>JSON</Typography>
                        {/* Create a TextField to display and edit the object position */}
                        <TextField
                            multiline
                            rows={10}
                            value={jsonInput}
                            onChange={handleJsonInputChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    }
    </>
}

const Slider = ({ min = 0, max = 1000, label, value, func, step = 1 }) => {
    return (
        <Grid container>
            <Typography variant='button'>{label}</Typography>
            <MuiSlider
                min={min} max={max} step={step}
                valueLabelDisplay="auto"
                value={value}
                onChange={(e, v) => func(v)}
                label={label}
            />
        </Grid>
    );
};
