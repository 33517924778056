export const screenData = [
    {
      name: "Bodentravet",
      city: "Boden",
      active: true,
      contacts: 5418,
      price: 0.56
    },
    {
      name: "SAAB Arena",
      city: "Linköping",
      active: true,
      contacts: 35152,
      price: 0.3
    },
    {
      name: "Alcatraz",
      city: "Luleå",
      active: true,
      contacts: 19125,
      price: 0.56
    },
    {
      name: "Bergnäsbron",
      city: "Luleå",
      active: true,
      contacts: 11835,
      price: 0.56
    },
    {
      name: "Luleå Energi Arena",
      city: "Luleå",
      active: true,
      contacts: 17810,
      price: 0.56
    },
    {
      name: "Skutviken",
      city: "Luleå",
      active: true,
      contacts: 19724,
      price: 0.56
    },
    {
      name: "Storheden",
      city: "Luleå",
      active: true,
      contacts: 21084,
      price: 0.56
    },
    {
      name: "Hamnbron",
      city: "Norrköping",
      active: true,
      contacts: 129420,
      price: 0.3
    },
    {
      name: "Sjötullsgatan",
      city: "Norrköping",
      active: true,
      contacts: 12312,
      price: 0.56
    },
    {
      name: "Söderköpingsvägen",
      city: "Norrköping",
      active: true,
      contacts: 50007,
      price: 0.3
    },
    {
      name: "Ståthögaleden",
      city: "Norrköping",
      active: true,
      contacts: 49671,
      price: 0.3
    },
    {
      name: "Gallerian",
      city: "Piteå",
      active: true,
      contacts: 24000,
      price: 0.56
    },
    {
      name: "Backcity",
      city: "Piteå",
      active: true,
      contacts: 19642,
      price: 0.56
    },
    {
      name: "Småstaden BV",
      city: "Piteå",
      active: true,
      contacts: 5000,
      price: 0.56
    },
    {
      name: "Småstaden GP",
      city: "Piteå",
      active: true,
      contacts: 5000,
      price: 0.56
    },
    {
      name: "Norranhuset",
      city: "Skellefteå",
      active: true,
      contacts: 46021,
      price: 0.3
    },
    {
      name: "Folkparken",
      city: "Skellefteå",
      active: true,
      contacts: 21525,
      price: 0.56
    },
    {
      name: "Möbeln",
      city: "Tierp",
      active: true,
      contacts: 23500,
      price: 0.56
    },
    {
      name: "Burger King",
      city: "Uppsala",
      active: true,
      contacts: 97350,
      price: 0.3
    },
    {
      name: "IFU Arena",
      city: "Uppsala",
      active: true,
      contacts: 40712,
      price: 0.3
    },
    {
      name: "Vimmerby allé",
      city: "Vimmerby",
      active: true,
      contacts: 4173,
      price: 0.56
    },
    {
      name: "Folkparksvägen",
      city: "Västervik",
      active: true,
      contacts: 5142,
      price: 0.56
    },
    {
      name: "Lucernavägen",
      city: "Västervik",
      active: true,
      contacts: 7351,
      price: 0.56
    },
    {
      name: "Stora infartsvägen",
      city: "Västervik",
      active: true,
      contacts: 32004,
      price: 0.3
    }
  ];
  