import React, { useState } from "react";
import {
    Autocomplete,
    Button,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    IconButton,
    Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { placements, sites } from "../../functions/placements";
import { sortByName } from "../../functions/misc";
import SearchIcon from "@mui/icons-material/Search";
import { DayPicker } from "react-day-picker";
import sv from "date-fns/locale/sv";
import "react-day-picker/dist/style.css";
import { CustomInfoIcon } from "../InfoIcon";
import VideocamIcon from "@mui/icons-material/Videocam";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClearIcon from "@mui/icons-material/Clear";
import { DefaultTooltip } from "../DefaultComponents";
import { useMobile } from "../../material/theme";

export const SitePlacementAuto = props => {
    const { setValue, optionType } = props;

    return (
        <Autocomplete
            id="site-placement-search"
            options={optionType === "site" ? sites : placements}
            renderInput={params => <TextField {...params} label={optionType === "site" ? "Sajt" : "Format"} />}
            onChange={(event, option) => setValue(option)}
        />
    );
};

export const CustomTargeting = ({ options, data, setData }) => {
    const isMobile = useMobile();

    const selectedKey = data.key;
    const selectedValues = data.values;

    const ntmAgeKey = "ntm_age";
    const genderKey = "gender";

    const ages = [
        { key: "65+", id: 448926311346 },
        { key: "55-64", id: 448925957680 },
        { key: "45-54", id: 448925950636 },
        { key: "35-44", id: 448925957677 },
        { key: "25-34", id: 448926311343 },
        { key: "18-24", id: 448926305436 },
        { key: "0-17", id: 448925957674 }
    ];

    const genders = [
        { key: "Män", id: 448916803254, displayName: "Män" },
        { key: "Kvinnor", id: 448916803278, displayName: "Kvinnor" }
    ];

    // Add ntm_age and gender to options.keys dynamically
    const enhancedOptions = {
        ...options,
        keys: [
            ...options.keys,
            { name: ntmAgeKey, displayName: "Ålderssegment", id: "13967798" },
            { name: genderKey, displayName: "Kön", id: "13967192" },
        ],
    };

    const valueOptions =
        selectedKey?.name === ntmAgeKey
            ? ages
            : selectedKey?.name === genderKey
            ? genders
            : options.values.filter(v => v.customTargetingKeyId === selectedKey?.id);

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography>Målgruppsstyrning</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Autocomplete
                    id="autocomplete-ct-keys"
                    options={enhancedOptions.keys}
                    autoHighlight
                    value={selectedKey}
                    onChange={(event, newValue) => {
                        setData({ key: newValue, values: [] });
                    }}
                    getOptionLabel={option => option?.displayName ?? option.name}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <Typography variant="body1" component="p" sx={{ cursor: "pointer" }}>
                                {option?.displayName ?? option.name}
                            </Typography>
                        </li>
                    )}
                    renderInput={params => <TextField {...params} label="Styrning" />}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
                <Autocomplete
                    id="autocomplete-ct-values"
                    multiple
                    disableCloseOnSelect
                    limitTags={isMobile ? 2 : -1}
                    options={valueOptions}
                    autoHighlight
                    disabled={valueOptions?.length === 0}
                    value={selectedValues}
                    onChange={(event, newValue) => {
                        setData({ key: selectedKey, values: newValue });
                    }}
                    getOptionLabel={option => option?.key ?? option?.displayName ?? option?.name}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.id}>
                            <Typography variant="body1" component="p" sx={{ cursor: "pointer" }}>
                                {option?.key ?? option?.displayName ?? option?.name}
                            </Typography>
                        </li>
                    )}
                    renderInput={params => <TextField {...params} label="Styrningsvärden" />}
                />
            </Grid>
        </Grid>
    );
};


export const AudienceTargeting = ({ options, data, setData }) => {
    const isMobile = useMobile();

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Typography>Intressestyrning</Typography>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="autocomplete-audiences"
                    multiple
                    disableCloseOnSelect
                    limitTags={isMobile ? 2 : -1}
                    options={sortByName(options)}
                    autoHighlight
                    disabled={options?.length === 0}
                    value={data}
                    onChange={(event, newValue) => setData(newValue)}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option?.id}>
                                <Typography variant="body1" component="p" sx={{ cursor: "pointer" }}>
                                    {option?.name}
                                </Typography>
                            </li>
                        );
                    }}
                    renderInput={params => <TextField {...params} label="Målgrupp" />}
                />
            </Grid>
        </Grid>
    );
};

export const FrequencyCap = props => {
    const { data, setData } = props;

    const availableTimeUnits = [
        { swedish: "dag", english: "DAY" },
        { swedish: "vecka", english: "WEEK" },
        { swedish: "livstid", english: "LIFETIME" },
    ];

    return (
        <Grid item container spacing={1.25}>
            <Grid item container xs={12} spacing={1}>
                <Grid item>
                    <Typography>Frekvensstyrning</Typography>
                </Grid>
                <Grid item>
                    <CustomInfoIcon text="Antal visningar per antalet av vald tidsenhet, t.ex. 2 visningar per 1 dag eller 5 visningar per 1 vecka" />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        fullWidth
                        value={data.maxImpressions}
                        label="Antal visningar"
                        type="number"
                        onChange={value => setData({ ...data, maxImpressions: value.target.value })}
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                    <TextField
                        fullWidth
                        value={data.numTimeUnits}
                        label="Tidsenhetsantal"
                        type="number"
                        onChange={value => setData({ ...data, numTimeUnits: value.target.value })}
                    />
                </Grid>
                <Grid item xs={10} sm={4} md={5}>
                    <FormControl variant="outlined" size="medium" fullWidth>
                        <InputLabel>Tidsenhet</InputLabel>
                        <Select label="Tidsenhet" value={data.timeUnit} onChange={value => setData({ ...data, timeUnit: value.target.value })}>
                            {availableTimeUnits.map(tu => (
                                <MenuItem key={tu.english} value={tu.english}>
                                    {tu.swedish}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} sm={2} md={1} container justifyContent="center" alignItems="center">
                    <IconButton onClick={() => setData({ timeUnit: "", numTimeUnits: "", maxImpressions: "" })}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption">För takeovers används som standard 2 visningar per (1) dag</Typography>
            </Grid>
        </Grid>
    );
};

const optionDisabled = (option, value, string) => {
    if (value?.some(p => p.includes(string))) return !option.includes(string);
    else if (value?.length > 0) return option.includes(string);
};

export const MultipleSites = props => {
    const { value, setValue } = props;

    return (
        <Autocomplete
            multiple
            limitTags={3}
            id="multiple-sites"
            options={[...sites]}
            getOptionLabel={option => option}
            autoHighlight
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            renderInput={params => <TextField placeholder={value?.length ? "" : "Alla sajter om inget val görs"} {...params} label="Sajter" />}
            getOptionDisabled={option => optionDisabled(option, value, "Megafonen")}
        />
    );
};

export const MultiplePlacements = props => {
    const { value, setValue, isMegafonen } = props;

    const filteredPlacements = isMegafonen ? placements.filter(p => p === "Display") : placements.filter(p => p !== "Display");

    return (
        <Autocomplete
            multiple
            limitTags={4}
            id="multiple-placements"
            options={filteredPlacements}
            autoHighlight
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            getOptionLabel={option => option}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option}>
                        <Typography variant="body1" component="p" sx={{ cursor: "pointer", mr: "10px" }}>
                            {option}
                        </Typography>
                        {option === "Preroll" && <VideocamIcon color="secondary" />}
                    </li>
                );
            }}
            defaultValue={[]}
            renderInput={params => <TextField {...params} label="Format" />}
            getOptionDisabled={option => optionDisabled(option, value, "Takeover")}
        />
    );
};

export const SinglePlacement = props => {
    const { value, setValue, isMegafonen } = props;

    const filteredPlacements = isMegafonen ? placements.filter(p => p === "Display") : placements.filter(p => p !== "Display");

    return (
        <Autocomplete
            id="single-placement"
            options={filteredPlacements}
            autoHighlight
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            getOptionLabel={option => option}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option}>
                        <Typography variant="body1" component="p" sx={{ cursor: "pointer", mr: "10px" }}>
                            {option}
                        </Typography>
                        {option === "Preroll" && <VideocamIcon color="secondary" />}
                    </li>
                );
            }}
            defaultValue={null}
            renderInput={params => <TextField {...params} label="Format" placeholder="Välj ett format" />}
        />
    );
};

export const TimeUnitSelect = ({ value, setValue, label = "Tidsenhet", isDooh = false }) => {
    return (
        <FormControl variant="outlined" size="medium" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={value} onChange={value => setValue(value.target.value)}>
                {isDooh && <MenuItem value="3">dagar</MenuItem>}
                <MenuItem value={isDooh ? "2" : "weeks"}>veckor</MenuItem>
                <MenuItem value={isDooh ? "1" : "months"}>månader</MenuItem>
            </Select>
        </FormControl>
    );
};

export const CountSelect = props => {
    const { value, setValue } = props;

    return (
        <FormControl variant="outlined" size="medium" fullWidth>
            <InputLabel>Antal</InputLabel>
            <Select label="Antal" value={value} onChange={value => setValue(value.target.value)}>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
            </Select>
        </FormControl>
    );
};

export const RequestButton = ({ loading, disabled, click }) => {
    return (
        <Button
            endIcon={<SearchIcon />}
            fullWidth
            size="large"
            variant="contained"
            sx={{ height: "56px" }}
            color="primary"
            disableElevation
            disabled={disabled || loading}
            onClick={() => click()}
        >
            {loading ? "Laddar..." : "Kontrollera lager"}
        </Button>
    );
};

export const DateRangePicker = props => {
    const [open, setOpen] = useState(false);
    const dates = props?.dates;
    const setDates = props?.setDates;

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const getStringDate = date => date.toISOString().slice(0, 10);

    const getBtnText = () => {
        let dateText = "";
        if (!dates?.from && !dates?.to) return "Datum";
        if (dates?.from) dateText += getStringDate(dates.from) + " - ";
        if (dates?.to) dateText += getStringDate(dates.to);
        return dateText;
    };

    const changeTimeAndSave = (e, v) => {
        e?.to?.setHours(12);
        e?.from?.setHours(12);
        setDates(e);
    };

    return (
        <>
            <style>
                {`
                    .rdp {
                        --rdp-cell-size: 38px;
                        --rdp-accent-color: #0E3C64;
                        --rdp-background-color: #0E3C6433;
                    }
                `}
            </style>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogTitle>Välj datumintervall</DialogTitle>
                <DialogContent sx={{ p: 1 }}>
                    <DayPicker locale={sv} showOutsideDays disabled={{ before: tomorrow }} mode="range" selected={dates} onSelect={changeTimeAndSave} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" disableElevation onClick={() => setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Button disableElevation fullWidth size="large" color="primary" variant="outlined" onClick={() => setOpen(!open)} sx={{ height: "56px" }}>
                {getBtnText()}
            </Button>
        </>
    );
};

export const SingleDatePicker = props => {
    const [open, setOpen] = useState(false);
    const date = props?.date;
    const setDate = props?.setDate;

    const getStringDate = date => date.toISOString().slice(0, 10);

    const changeTimeAndSave = (e, v) => {
        v?.setHours(12);
        setDate(v);
    };

    return (
        <>
            <style>
                {`
                    .rdp {
                        --rdp-cell-size: 38px;
                        --rdp-accent-color: #7ba7ae;
                        --rdp-background-color: rgba(123, 167, 174, 0.2);
                    }
                `}
            </style>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogTitle>Välj startdatum</DialogTitle>
                <DialogContent sx={{ p: 1 }}>
                    <DayPicker locale={sv} showOutsideDays disabled={{ before: new Date() }} mode="single" selected={date} onSelect={changeTimeAndSave} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Button disableElevation fullWidth size="large" color="primary" variant="outlined" onClick={() => setOpen(!open)} sx={{ height: "56px" }}>
                {getStringDate(date)}
            </Button>
        </>
    );
};

export const ArrowExpander = props => {
    const [open, setOpen] = useState(false);

    return (
        <Grid item xs={12}>
            <Grid item container justifyContent="center">
                <Grid item>
                    <DefaultTooltip title={`${open ? "Dölj" : "Visa"} ${props?.tooltipTitle}`}>
                        <IconButton onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                    </DefaultTooltip>
                </Grid>
            </Grid>
            <Grid item>
                <Collapse in={open} timeout="auto">
                    <Grid container spacing={2} pt={1}>
                        {props?.children}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};

export const ProgressBar = props => {
    let { value, color, tooltipText } = props;
    const barWidth = props?.width ?? "50%";

    value = isNaN(value) ? 0 : value;

    const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 20,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#9AA0A6",
        },
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: color ?? "#388E3C",
        },
    }));

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2, mb: 2 }}>
            <Box sx={{ width: barWidth, mr: 1 }}>
                {tooltipText ? (
                    <DefaultTooltip title={tooltipText}>
                        <CustomLinearProgress variant="determinate" value={value} />
                    </DefaultTooltip>
                ) : (
                    <CustomLinearProgress variant="determinate" value={value} />
                )}
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {value?.toFixed(0)}%
                </Typography>
            </Box>
        </Box>
    );
};
