import { Grid } from '@mui/material';
import { GenerateExternalLink } from '../GenerateExternalLink';
import { useMobile } from '../../../material/theme';

export const HeaderButtons = ({ item }) => {
    const isMobile = useMobile();

    return (
        <Grid item container xs={12} md={7} spacing={2} justifyContent={isMobile ? 'space-between' : 'flex-end'} alignItems='center'>
            <Grid item container justifyContent='flex-end'>
                <GenerateExternalLink stringToEncrypt={item?.name} />
            </Grid>
        </Grid>
    );
};
