import { Box, Typography } from '@mui/material';
import { DefaultTooltip } from './DefaultComponents';

export const getDeliveryDifference = (lineitem) => {
    const actual = lineitem?.deliveryIndicator?.actualDeliveryPercentage ?? 0;
    const expected = lineitem?.deliveryIndicator?.expectedDeliveryPercentage ?? 0;
    return actual === 0 || expected === 0 ? 0 : (actual / expected) * 100;
}

export const DeliveryProgressBar = props => {
    const { lineitem, size } = props;

    const largeSettings = {
        barHeight: 25,
        showPercentRightSide: true,
        margin: 2,
        justifyContent: 'center',
        tooltipPlacement: 'bottom'
    };
    const smallSettings = {
        barHeight: 15,
        showPercentRightSide: false,
        margin: 0,
        justifyContent: 'normal',
        tooltipPlacement: 'top'
    };

    const settings = size === 'small' ? smallSettings : largeSettings;

    const deliveryDiff = getDeliveryDifference(lineitem);
    const actual = lineitem?.deliveryIndicator?.actualDeliveryPercentage ?? 0;
    const expected = lineitem?.deliveryIndicator?.expectedDeliveryPercentage ?? 0;

    const setColors = () => {
        if (deliveryDiff >= 95) return { progress: '#34a853', background: '#e6f4ea', border: '#0d652d' };
        else if (deliveryDiff < 80) return { progress: '#ea4335', background: '#fce8e6', border: '#a50e0e' };
        return { progress: '#fbbc04', background: '#fef7e0', border: '#e37400' };
    };

    const colors = setColors();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: settings.justifyContent, my: settings.margin, width: "100%" }}>
            <Box sx={{ width: '75%', mr: 1 }}>
                <DefaultTooltip
                    title={
                        <Typography variant='caption'>
                            Kampanjen levererar {deliveryDiff?.toFixed(0)}% i förhållande till hur den förväntas ha levererat hittills
                        </Typography>
                    }
                    placement={settings.tooltipPlacement}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: settings.barHeight,
                            background: colors.background,
                            border: `1px solid ${colors.border}`,
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                width: `${actual > 100 ? 100 : actual}%`,
                                height: settings.barHeight,
                                background: colors.progress,
                            }}
                        ></div>
                        <div
                            style={{
                                position: 'absolute',
                                width: `${expected > 100 ? 100 : expected}%`,
                                height: settings.barHeight,
                                zIndex: 10,
                                borderRight: '2px solid #5F6368',
                            }}
                        ></div>
                    </div>
                </DefaultTooltip>
            </Box>
            {settings.showPercentRightSide && (
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant='body1' color='text.secondary'>
                        {deliveryDiff?.toFixed(0)}%
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
