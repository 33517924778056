import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Button, Badge } from '@mui/material';
import { DefaultTooltip } from '../DefaultComponents';
import { trackGAEvent } from '../../functions/googleAnalytics';

export const CreateCombinedReport = ({ lineitemIds }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Grid item>
            <DefaultTooltip title={lineitemIds.length < 2 ? 'Välj minst två kampanjer' : 'Skapa en kombinerad rapport med de kampanjer du har valt'}>
                <span>
                    <Badge badgeContent={lineitemIds.length} color="accent">
                        <Button
                            color='secondary'
                            size='small'
                            disableElevation
                            variant='outlined'
                            disabled={lineitemIds.length < 2}
                            onClick={() => {
                                trackGAEvent("Button click", "Skapa kombinerad rapport", location.pathname + location.search);
                                navigate('/kampanjer/kombinera/ny', {
                                    state: {
                                        lineitemIds: lineitemIds,
                                    },
                                });
                            }}
                        >
                            Skapa kombinerad rapport
                        </Button>
                    </Badge>
                </span>
            </DefaultTooltip>
        </Grid>
    );
};