export const lineItemStatus = {
    DELIVERY_EXTENDED: 'Live',
    DELIVERING: 'Live',
    READY: 'Redo',
    PAUSED: 'Pausad',
    INACTIVE: 'Kommande',
    PAUSED_INVENTORY_RELEASED: 'Pausad',
    PENDING_APPROVAL: 'Väntar',
    COMPLETED: 'Klar',
    DISAPPROVED: 'Nekad',
    DRAFT: 'Utkast',
    CANCELED: 'Avbruten',
};

export const orderStatus = {
    PAUSED: 'Pausad',
    PENDING_APPROVAL: 'Väntar',
    APPROVED: 'Godkänd',
    DISAPPROVED: 'Nekad',
    DRAFT: 'Utkast',
    CANCELED: 'Avbruten',
    DELETED: 'Borttagen',
    UNKNOWN: 'Okänd',
};

export const creativeStatus = {
    ACTIVE: "Aktiv",
    NOT_SERVING: "Levereras ej",
    INACTIVE: 'Inaktiv',
    UNKNOWN: 'Okänd',
};
