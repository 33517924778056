// DashboardDaily.jsx

import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { ntmtypecolors } from "./Dashboard";

// Utility function for formatting currency in SEK with no decimals
const formatCurrency = value => {
    return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
};

// Distribution component to render a pie chart with percentage labels
const Distribution = ({ data }) => {
    const chartData = Object.keys(data).map(key => ({
        id: key,
        value: data[key].distribution,
        label: `${data[key].distribution}%`,
        color: ntmtypecolors[key],
    }));

    return (
        <PieChart
            series={[
                {
                    data: chartData,
                    innerRadius: 20,
                    outerRadius: 60,
                    paddingAngle: 2,
                    cornerRadius: 4,
                    startAngle: 0,
                    endAngle: 360,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                },
            ]}
            width={200}
            height={200}
            slotProps={{
                legend: {
                    hidden: true,
                },
            }}
        />
    );
};

// LargeCard component for displaying Total Revenue with a Distribution pie chart side-by-side
const LargeCard = ({ revenue, diffPercent, distributionData, weekday, yesterdayDateYYYYMMDD }) => {
    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: "#1e1e1e", color: "#ffffff" }}>
            <Grid container alignItems="center">
                <Grid item xs={12} mb={3}>
                    <Typography variant="h6" gutterBottom>
                        Gårdagens intäkter - {weekday} - {yesterdayDateYYYYMMDD}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                        Jämförelse mot det genomsnittliga utfallet för de senaste 10 {weekday.toLowerCase()}arna.
                    </Typography>
                </Grid>
                <Grid item xs={8} pl={3}>
                    <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                        {formatCurrency(revenue)}
                    </Typography>
                    <Grid item xs={6} mb={3} display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: "bold",
                                color: diffPercent >= 0 ? "green" : "red",
                                mt: 1,
                            }}
                        >
                            {diffPercent >= 0 ? "+" : ""}
                            {diffPercent.toFixed(1)}%
                        </Typography>
                    </Grid>
                </Grid>

                {/* Distribution Pie Chart */}
                <Grid item xs={4} display="flex" justifyContent="center">
                    <Distribution data={distributionData} />
                </Grid>
            </Grid>
        </Paper>
    );
};

// SmallCard component for displaying individual subtypes (ADX, DIREKT, LW) with matching color titles
const SmallCard = ({ title, revenue, diffPercent }) => {
    return (
        <Paper elevation={2} sx={{ p: 2, backgroundColor: "#1e1e1e", color: "#ffffff", textAlign: "center" }}>
            <Typography variant="h6" gutterBottom color={ntmtypecolors[title]}>
                {title}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {formatCurrency(revenue)}
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bold",
                    color: diffPercent >= 0 ? "green" : "red",
                    mt: 1,
                }}
            >
                {diffPercent >= 0 ? "+" : ""}
                {diffPercent.toFixed(1)}%
            </Typography>
        </Paper>
    );
};

// Main component for DashboardDaily view
const DashboardDaily = ({ data }) => {
    const { weekday, total, ADX, DIREKT, LW } = data;
    const distributionData = { ADX, DIREKT, LW };

    // const yesterdayDateYYYYMMDD = new Date().toISOString().split("T")[0];
    const yesterdayDateYYYYMMDD = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];

    return (
        <Box p={4}>
            <Grid container spacing={4} justifyContent="center">
                {/* LargeCard for Total Revenue with Distribution pie chart */}
                <Grid item xs={12} md={11}>
                    <LargeCard
                        revenue={total.yesterday}
                        weekday={weekday}
                        diffPercent={total.compared_weekday.diff_percent}
                        distributionData={distributionData}
                        yesterdayDateYYYYMMDD={yesterdayDateYYYYMMDD}
                    />
                </Grid>

                {/* SmallCards for Revenue Breakdown */}
                <Grid container item xs={12} md={11} spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="DIREKT" revenue={DIREKT.yesterday} diffPercent={DIREKT.compared_weekday.diff_percent} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="PROGRAMMATISK (LW)" revenue={LW.yesterday} diffPercent={LW.compared_weekday.diff_percent} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SmallCard title="PROGRAMMATISK (ADX)" revenue={ADX.yesterday} diffPercent={ADX.compared_weekday.diff_percent} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardDaily;
