import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { formatDate } from '../../functions/misc';
import { CustomInfoIcon } from '../InfoIcon';
import { getBackendSubDomain, getFrontendSubDomain } from '../../functions/subDomain';
import { trackGAEvent } from '../../functions/googleAnalytics';

export const SaveCombinedReport = ({ report }) => {
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveResponse, setSaveResponse] = useState(null);

    const lineitemIds = report?.lineitems?.map(item => item?.id);

    const [settings, setSettings] = useState({
        name: "",
        description: "",
        includeIds: true,
        includeDates: true,
        includeLineitems: true,
    });

    const handleSettings = (key, value) => {
        setSettings({
            ...settings,
            [key]: value
        });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveReport = () => {
        trackGAEvent("Button click", "Spara kombinerad rapport", location.pathname + location.search);
        setLoading(true);
        return fetch(`${getBackendSubDomain('nadir')}/gam/combined/save`, {
            headers: { 'content-type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                ...settings,
                advertiserId: report?.advertiser?.id,
                lineitemIds: lineitemIds
            }),
        })
            .then(res => res.json())
            .then(res => setSaveResponse(res))
            .catch(err => console.warn(err))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Button
                variant="contained"
                size='small'
                color='accent'
                disableElevation
                startIcon={<SaveIcon />}
                onClick={handleOpen}
            >
                Spara kombinerad rapport
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>Spara kombinerad rapport</Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={2} py={1}>
                        <Grid item container spacing={2} alignItems='center' justifyContent='space-between'>
                            <Grid item xs={12}>
                                <Typography gutterBottom>För att kunna dela en kombinerad rapport internt eller med en kund måste den först sparas. När du sparar en rapport kan du komma åt den från kundens sida efteråt, utan att behöva skapa en ny.</Typography>
                                <Typography>I fälten nedan anges namn för den kombinerade rapporten samt eventuell beskrivning, det går även att till viss del välja vad som ska visas i rapporten.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label='Namn'
                                    placeholder='Kombinerad rapport'
                                    fullWidth
                                    required
                                    value={settings.name}
                                    onChange={(event) => handleSettings("name", event.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label='Beskrivning'
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={settings.description}
                                    onChange={(event) => handleSettings("description", event.target.value)}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion elevation={0} sx={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Välj vad som ska visas i rapporten</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup>
                                            <Stack direction='row' alignItems='center'>
                                                <FormControlLabel control={<Checkbox checked={settings.includeIds} onChange={(event) => handleSettings("includeIds", event.target.checked)} />} label="AD-nummer" />
                                                <CustomInfoIcon text={new Intl.ListFormat('se').format(report?.lineitems?.map(item => item?.externalId ?? item?.id?.toString()).sort())} />
                                            </Stack>
                                            <Stack direction='row' alignItems='center'>
                                                <FormControlLabel control={<Checkbox checked={settings.includeDates} onChange={(event) => handleSettings("includeDates", event.target.checked)} />} label="Totalt datumintervall" />
                                                <CustomInfoIcon text={`Första kampanjens startdatum och sista kampanjens slutdatum, med nuvarande valda kampanjer blir det totala datumintervallet ${formatDate(report?.start)} - ${formatDate(report?.end)}`} />
                                            </Stack>
                                            <Stack direction='row' alignItems='center'>
                                                <FormControlLabel control={<Checkbox checked={settings.includeLineitems} onChange={(event) => handleSettings("includeLineitems", event.target.checked)} />} label="Individuella kampanjer" />
                                                <CustomInfoIcon text='Inkludera individuella kampanjer längst ned i rapporten' />
                                            </Stack>
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontStyle='italic'>Det är inte möjligt att modifiera en kombinerad rapport efter att den har sparats, om du vill göra ändringar måste du skapa en ny rapport.</Typography>
                            </Grid>
                            {saveResponse && (
                                <Grid item xs={12}>
                                    {saveResponse?.success ? (
                                        <Stack spacing={2}>
                                            <Alert variant='outlined' severity='success'>
                                                <AlertTitle>Kombinerad rapport sparad!</AlertTitle>
                                                Nedan finns länkar till rapporten som kan delas internt och med kunden. Rapporten finns även tillgänglig på kundens sida tillsammans med tidigare sparade rapporter.
                                            </Alert>
                                            <TextField value={`https://${getFrontendSubDomain()}/kampanjer/kombinera/${saveResponse?.id}`} multiline fullWidth variant='outlined' label="Intern länk" />
                                            <TextField value={`https://${getFrontendSubDomain()}/extern/kombinera/${saveResponse?.externalId}`} multiline fullWidth variant='outlined' label="Kundlänk" />
                                        </Stack>
                                    ) : (
                                        <Alert variant='outlined' severity='error'>Kunde inte spara kombinerad rapport.</Alert>
                                    )}
                                </Grid>
                            )}
                            {!saveResponse?.success && (
                                <Grid item xs={12}>
                                    <Stack direction='row' justifyContent='flex-end' spacing={1}>
                                        <Button
                                            variant="outlined"
                                            color='secondary'
                                            disableElevation
                                            disabled={loading}
                                            onClick={handleClose}
                                        >
                                            Avbryt
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color='secondary'
                                            disableElevation
                                            disabled={!settings.name || loading}
                                            onClick={handleSaveReport}
                                        >
                                            Spara
                                        </Button>
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};