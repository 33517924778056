import React, { useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";

import { DefaultBody, DefaultPage, HeaderWithText, DefaultCard, DefaultTooltip } from "../DefaultComponents";
import { flattenedLayout } from "../../config/navigation";
import { RequestButton, MultipleSites, CustomTargeting, DateRangePicker, ArrowExpander, FrequencyCap, AudienceTargeting, SinglePlacement } from "./components";
import { useStandardForecast } from "../../functions/hooks/forecast/useStandardForecast";
import { useAudiences, useKeyValues } from "../../functions/hooks/forecast/useTargeting";
import { grey } from "@mui/material/colors";
import { SiteImage } from "../SiteImage";

export const Standard = () => {
    const info = flattenedLayout.standardForecast;

    const [settings, setSettings] = useState({
        sites: [],
        placement: null,
        customTargeting: { key: null, values: [] },
        audienceTargeting: [],
        frequencyCap: { maxImpressions: "", numTimeUnits: "", timeUnit: "" },
        dates: null,
        useSizes: true
    });

    const [request, setRequest] = useState(null);
    const [takeoverSelected, setTakeoverSelected] = useState(false);

    const [data, loading, resetData] = useStandardForecast(request);
    const requestForecast = () => setRequest({ settings });

    const [ctOptions, kvLoading] = useKeyValues();
    const [audiences, audienceLoading] = useAudiences();

    const handleSettingsChange = (k, v) => {
        setSettings({ ...settings, [k]: v });
        resetData();
    };

    const handlePlacementChange = placement => {
        let frequencyCap = {};
        if (placement?.includes("Takeover")) {
            frequencyCap = { maxImpressions: "2", numTimeUnits: "1", timeUnit: "DAY" };
            setTakeoverSelected(true);
        } else {
            frequencyCap = { maxImpressions: "", numTimeUnits: "", timeUnit: "" };
            setTakeoverSelected(false);
        }

        setSettings({
            ...settings,
            placement: placement,
            frequencyCap: frequencyCap,
        });
        resetData();
    };

    const disableForecast = !settings.dates?.from || !settings.dates?.to || !settings.placement;

    // välja siter  (+ 1 option alla siter )
    // välja placements (+ 1 option alla placements)
    // välja key -> välja values
    // välja datum period
    // optional antal imps

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description} />
            <DefaultBody>
                <Grid item xs={12} sm={6} md={3}>
                    <MultipleSites value={settings.sites} setValue={value => handleSettingsChange("sites", value)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <SinglePlacement
                        value={settings.placement}
                        setValue={value => handlePlacementChange(value)}
                        isMegafonen={settings.sites.some(s => s.includes("Megafonen"))}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <DateRangePicker dates={settings.dates} setDates={value => handleSettingsChange("dates", value)} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <RequestButton disabled={disableForecast} loading={loading} click={requestForecast} />
                </Grid>
                {takeoverSelected && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Du har valt Takeover som format, på grund av detta har frekvensstyrningen ändrats till <strong>2 visningar per (1) dag</strong> för
                            att visa korrekt lager.
                        </Alert>
                    </Grid>
                )}
                <ArrowExpander tooltipTitle="styrningsalternativ">
                    <CustomTargeting options={ctOptions} data={settings.customTargeting} setData={value => handleSettingsChange("customTargeting", value)} />
                    <AudienceTargeting
                        options={audiences}
                        data={settings.audienceTargeting}
                        setData={value => handleSettingsChange("audienceTargeting", value)}
                    />
                    <FrequencyCap data={settings.frequencyCap} setData={value => handleSettingsChange("frequencyCap", value)} />
                </ArrowExpander>
            </DefaultBody>
            {data && <ForecastResult data={data} />}
        </DefaultPage>
    );
};

const ForecastResult = ({ data }) => {
    return (
        <DefaultBody>
            <Grid item xs={12} sm={6} md={4}>
                <DefaultCard>
                    <Typography variant="h5" sx={{ textTransform: "uppercase", color: grey[600], mb: 1 }}>
                        Tillgängliga visningar
                    </Typography>
                    <Typography variant="h4">{data?.available?.toLocaleString("sv-SE")}</Typography>
                </DefaultCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DefaultCard>
                    <Typography variant="h5" sx={{ textTransform: "uppercase", color: grey[600], mb: 1 }}>
                        Totalt lager
                    </Typography>
                    <Typography variant="h4">{data?.matched?.toLocaleString("sv-SE")}</Typography>
                </DefaultCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <DefaultCard>
                    <DefaultTooltip title="Andel av totala lagret som är tillgängligt">
                        <Typography variant="h5" sx={{ textTransform: "uppercase", color: grey[600], mb: 1 }}>
                            Tillgänglig SOV
                        </Typography>
                    </DefaultTooltip>
                    <Typography variant="h4">{((data?.available / data?.matched) * 100).toFixed(2)}%</Typography>
                </DefaultCard>
            </Grid>

            <ArrowExpander tooltipTitle="fördelning">
                <Grid item container direction="column" spacing={2}>
                    <Grid item container direction="row" alignItems="center">
                        <Grid item xs={1}>
                            <Typography></Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography fontWeight="bold">Placering</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography fontWeight="bold">Tillgängligt</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography fontWeight="bold">Totalt</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <DefaultTooltip title="Andel av placeringens lager som är tillgängligt">
                                <Typography fontWeight="bold">Tillgänglig SOV</Typography>
                            </DefaultTooltip>
                        </Grid>
                    </Grid>
                    {data?.breakdowns.map(b => {
                        const percent = ((b.available / (b.matched ? b.matched : 1)) * 100).toFixed(0);
                        return (
                            <Grid item container direction="row" alignItems="center" key={b.id}>
                                <Grid item xs={1}>
                                    <SiteImage site={b.site} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>{b.name}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{b.available?.toLocaleString("sv-SE")}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>{b.matched?.toLocaleString("sv-SE")}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{percent}%</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </ArrowExpander>
        </DefaultBody>
    );
};
