import { Typography, Grid, Box, Divider } from '@mui/material';
import React from 'react';
import { grey } from '@mui/material/colors';
import { DefaultCard, PaperBody } from '../../DefaultComponents';
import { sumArrayInteraction } from './displayFunction';
import { useMobile } from '../../../material/theme';

export const InteractionsGrid = ({ item }) => {
    const isMobile = useMobile();

    if (item?.interactions && item?.interactions?.length > 0) return (
        <PaperBody>
            <Typography variant='h5' sx={{ color: grey[600] }}>
                Övrig information
            </Typography>
            <Grid container spacing={isMobile ? 2 : 0} justifyContent='space-evenly'>
                {sumArrayInteraction('interactions', item) !== null && (
                    <>
                        <Grid item xs={12} sm={6} lg>
                        <DefaultCard title="Interaktioner" infoText="Antalet interaktioner som användare har haft med annonsen, till exempel klick på pilarna eller svep.">
                                <Typography variant='h3'>{sumArrayInteraction('interactions', item)?.toLocaleString('sv-SE') ?? 0}</Typography>
                                <Typography sx={{ color: grey[600] }}>
                                    {(sumArrayInteraction('interactions', item) / (item?.stats?.impressionsDelivered ?? item?.impressionsDelivered)).toFixed(2) ?? 0} per visning
                                </Typography>
                            </DefaultCard>
                        </Grid>
                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                            <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                        </Box>
                    </>
                )}
                {sumArrayInteraction('timeInScreen', item) !== null && (
                    <Grid item xs={12} sm={6} lg>
                        <DefaultCard title="Visningstid" infoText="Den genomsnittliga tid som annonsen har varit synlig för användare under visningar.">
                            <Typography variant='h3'>
                                {(sumArrayInteraction('timeInScreen', item) / (item?.stats?.impressionsDelivered ?? item?.impressionsDelivered) / 1000).toFixed(2)}
                            </Typography>
                            <Typography sx={{ color: grey[600] }}>sekunder per visning</Typography>
                        </DefaultCard>
                    </Grid>
                )}
            </Grid>
        </PaperBody>
    )
    else return <></>
}