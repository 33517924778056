import { useEffect, useState } from 'react';
import { getBackendSubDomain } from '../../subDomain';

export const useKeyValues = () => {
    //Nya värden med "_"
    //[13967498, 13967735] [ntm_geo, glimr_tags] gamla
    const includedKeys = [13746755, 13212276];

    //Gamla värden med "-"
    //[13746755, 13212276] [ntm-geo, glimr-tags] gamla
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        keys: [],
        values: [],
    });

    const fetchKeyValues = async () => {
        try {
            const response = await fetch(`${getBackendSubDomain('nadir')}/gam/keyvalues`, {
                headers: { 'content-type': 'application/json' },
                method: 'GET',
            });
            const res = await response.json();

            res.keys = res.keys.filter(k => includedKeys.includes(k.id));
            //TODO ersätta namn på keys för att matcha salesforce
            res.values = sortValues(res.values.filter(k => includedKeys.includes(k.customTargetingKeyId)));

            //Alla values ligger i en array, filterara glimr_tags men inte geo.
            res.values = res.values.filter(v => v.name.startsWith('tag_') || v.customTargetingKeyId === 13746755);
            setData(res);
        } catch (err) {
            console.warn(err);
            return [];
        }
    };

    useEffect(() => {
        //Check ok values
        fetchKeyValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, loading];
};

export const useAudiences = () => {
    const excludedIds = [7637708819];
    const excludedWords = ["native"];
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchAudiences = async () => {
        try {
            const response = await fetch(`${getBackendSubDomain('nadir')}/gam/audiences`, {
                headers: { 'content-type': 'application/json' },
                method: 'GET',
            });
            const res = await response.json();

            const audiences = res.filter(a =>
                !excludedIds.includes(a.id) &&
                excludedWords.every(word => !a.name.toLowerCase().includes(word))
            );

            setData(audiences);
        } catch (err) {
            console.warn(err);
            return [];
        }
    };

    useEffect(() => {
        fetchAudiences();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, loading];
};

const sortValues = arr => {
    arr.sort((a, b) => {
        a = a?.displayName ?? a?.name;
        b = b?.displayName ?? b?.name;

        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    });
    return arr;
};