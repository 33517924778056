import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import myMarkerIcon from './mymarker.png';

const defaultSwedenCoordinates = { latitude: 62.38583179, longitude: 16.321998712 }
const defaultZoom = 5
const defaultMarkerIcon = {
    iconUrl: myMarkerIcon,
    iconSize: [24, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
}
const defaultMapSize = { width: '100%', height: '600px' }

const LeafletMap = ({ markers, center = defaultSwedenCoordinates, zoom = defaultZoom, mapSize = defaultMapSize }) => {
    const mapRef = useRef(null);
    const customIcon = L.icon(defaultMarkerIcon);

    const addMarker = ({ latitude, longitude, name = 'noname' }, L) => {
        const newMarker = L.marker([latitude, longitude], { icon: customIcon })
            .addTo(mapRef.current)
            .bindPopup(name);
        return newMarker;
    };

    // Render map
    useEffect(() => {
        mapRef.current = L.map('leaflet-map').setView([center.latitude, center.longitude], zoom);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors'
        }).addTo(mapRef.current);
    }, []);

    // Render markers
    useEffect(() => {
        // Remove all existing markers
        mapRef.current.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                mapRef.current.removeLayer(layer);
            }
        });

        // Add new markers
        markers?.forEach(marker => {
            addMarker(marker, L);
        });
    }, [markers]);

    return <div id="leaflet-map" style={mapSize} />;
};

export default LeafletMap;
