import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { formatDate } from "../../../functions/misc";
import { creativeStatus } from "../../../functions/translate";
import { ClickDistribution } from "./ClickDistribution";
import { SitePreviewDialog } from "./SitePreviewDialog";
import { sumArrayClicks, sumArrayInteraction } from "./displayFunction";
import { DefaultCard, DefaultTooltip } from "../../DefaultComponents";

export let headers = new Headers();
headers.set("content-type", "application/json");

export const Creative = (props) => {
    const { creative, lineitem, isInternal } = props;

    let placements = lineitem?.placements;
    if (placements.some((p) => typeof p !== "string")) placements = placements.map((p) => p?.name);

    const isNative = placements.some((p) => p?.includes("Native"));
    const views = lineitem?.stats.impressionsDelivered;
    const previewInNewWindow = placements.some((p) => ["Topscroll", "Midscroll", "Takeover"].some((w) => p?.includes(w)));
    const sites = [...new Set(placements?.map((p) => p?.split(" ")[0]))];

    const sumInteractions = sumArrayInteraction("interactions", lineitem, creative.id);
    const sumTime = sumArrayInteraction("timeInScreen", lineitem, creative.id);
    const sumClicks = sumArrayClicks(lineitem, creative.id);

    let renderCreative;
    if (isNative || !creative.previewUrl) {
        renderCreative = (
            <DefaultCard>
                <Typography variant="body1" gutterBottom>
                    {creative?.creativeName}
                </Typography>
                <Typography variant="body2" sx={{ color: grey[600] }}>
                    Annonsmaterialet kan inte visas
                </Typography>
            </DefaultCard>
        );
    } else if (creative.size.width < window.innerWidth && !previewInNewWindow) {
        renderCreative = (
            <iframe
                style={{ border: "none" }}
                width={creative?.size?.width}
                height={creative?.size?.height}
                src={creative?.previewUrl}
                title="creative"
            />
        );
    } else {
        renderCreative = (
            <DefaultCard>
                <Typography variant="body1" gutterBottom>
                    Annonsmaterialet är för stort för att visas i rapporten
                </Typography>
                <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={() => window.open(creative?.previewUrl, "_blank")}
                >
                    Länk till annonsmaterial
                </Button>
            </DefaultCard>
        );
    }

    return (
        <Grid item container alignItems="center">
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                xs={12}
                lg={creative.size.width > window.innerWidth / 3.5 && !previewInNewWindow ? 12 : 6}
            >
                {/* Creative Header */}
                <Grid item container spacing={2} justifyContent="center" alignItems="center" mb={1}>
                    <Grid item>
                        <Typography variant="h5" sx={{ color: grey[600] }}>
                            {creative?.size?.width}x{creative?.size?.height}
                        </Typography>
                    </Grid>
                    {creative.previewUrl && (
                        <Grid item>
                            <SitePreviewDialog lineItemId={lineitem?.id} id={creative?.id} sites={sites} />
                        </Grid>
                    )}
                </Grid>
                <Grid item container direction="column" alignItems="center" mb={1}>
                    <Grid item>
                        <Typography sx={{ color: grey[600] }}>{creativeStatus[creative?.status]}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" sx={{ color: grey[600] }}>
                            {formatDate(creative?.start ?? lineitem?.start, true)} -{" "}
                            {formatDate(creative?.end ?? lineitem?.end, true)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>{renderCreative}</Grid>
            </Grid>

            {/* Metrics */}
            <Grid item xs={12} lg={creative.size.width > window.innerWidth / 3.5 && !previewInNewWindow ? 12 : 6}>
                <Grid container spacing={2} justifyContent="space-evenly">
                    <Grid item xs={12} sm={6} lg={5}>
                        <DefaultCard
                            title="Visningar"
                            infoText="Antalet gånger som annonsen har visats för användare. Detta mäter exponeringen av annonsen."
                        >
                            <Typography variant="h4">{creative.stats.impressionsDelivered?.toLocaleString("sv-SE") ?? 0}</Typography>
                            <DefaultTooltip title="Andel av kampanjens levererade visningar" placement="bottom">
                                <Typography sx={{ color: grey[600] }}>
                                    {((creative.stats.impressionsDelivered / views) * 100).toFixed(2)}%
                                </Typography>
                            </DefaultTooltip>
                        </DefaultCard>
                    </Grid>
                    <Box sx={{ mt: 2, display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                        <Divider orientation="vertical" variant="middle" sx={{ height: "90%" }} />
                    </Box>
                    <Grid item xs={12} sm={6} lg={5}>
                        <DefaultCard
                            title="CTR"
                            infoText="Click-through rate (CTR) är procentandelen användare som klickade på annonsen i förhållande till antalet visningar."
                        >
                            <Typography variant="h4">
                                {creative?.stats?.clicksDelivered && creative?.stats?.impressionsDelivered
                                    ? ((creative?.stats?.clicksDelivered / creative?.stats?.impressionsDelivered) * 100).toFixed(2)
                                    : 0}
                                %
                            </Typography>
                            <Typography sx={{ color: grey[600] }}>{creative.stats.clicksDelivered?.toLocaleString("sv-SE") ?? "0"} klick</Typography>
                            {sumClicks !== null && (
                                <ClickDistribution clicks={sumClicks} size={`${creative?.size?.width}x${creative?.size?.height}`} />
                            )}
                        </DefaultCard>
                    </Grid>
                    {sumInteractions !== null && (
                        <>
                            <Grid item xs={12} sm={6} lg={5}>
                                <DefaultCard
                                    title="Interaktioner"
                                    infoText="Antalet interaktioner som användare har haft med annonsen, till exempel klick på pilarna eller svep."
                                >
                                    <Typography variant="h4">{sumInteractions?.toLocaleString("sv-SE") ?? 0}</Typography>
                                    <Typography sx={{ color: grey[600] }}>
                                        {(sumInteractions / creative?.stats?.impressionsDelivered).toFixed(2) ?? 0} per visning
                                    </Typography>
                                </DefaultCard>
                            </Grid>
                            <Box sx={{ mt: 2, display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                <Divider orientation="vertical" variant="middle" sx={{ height: "90%" }} />
                            </Box>
                        </>
                    )}
                    {sumTime !== null && (
                        <Grid item xs={12} sm={6} lg={5}>
                            <DefaultCard
                                title="Visningstid"
                                infoText="Den genomsnittliga tid som annonsen har varit synlig för användare under visningar."
                            >
                                <Typography variant="h4">
                                    {(sumTime / creative?.stats?.impressionsDelivered / 1000).toFixed(2)}
                                    <Typography sx={{ color: grey[600] }}>sekunder per visning</Typography>
                                </Typography>
                            </DefaultCard>
                        </Grid>
                    )}
                    {/* {isInternal && (
                        <>
                            {creative?.viewability && (
                                <Grid item xs={12} sm={6} lg={5}>
                                    <DefaultCard
                                        title="Viewability"
                                        infoText="Procentandelen av annonsens visningar där minst 50% av annonsen var synlig i minst 1 sekund (enligt standarden för viewability). Viewability syns inte för externa användare och bara på avslutade kampanjer"
                                    >
                                        <Typography variant="h4">{Number(creative?.viewability * 100).toFixed(2)}%</Typography>
                                    </DefaultCard>
                                </Grid>
                            )}
                        </>
                    )} */}
                </Grid>
            </Grid>
        </Grid>
    );
};
