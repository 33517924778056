import { Card, CardContent, Grid, Paper, Skeleton } from '@mui/material';
import { PaperBody, DefaultPage } from './DefaultComponents';

export const SkeletonLineitem = () => {
    return (
        <DefaultPage>
            <PaperBody paperSx={{ background: '#0E3C64' }}>
                <Skeleton animation='wave' variant='rect' height={32} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={20} />
            </PaperBody>
            <PaperBody>
                <Skeleton animation='wave' variant='rect' height={32} width={180} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={28} width={200} sx={{ mb: 2 }} />
                <Grid container spacing={2} justifyContent='space-evenly'>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Skeleton animation='wave' variant='rect' height={200} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Skeleton animation='wave' variant='rect' height={200} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Skeleton animation='wave' variant='rect' height={200} />
                    </Grid>
                </Grid>
            </PaperBody>
            <PaperBody>
                <Skeleton animation='wave' variant='rect' height={32} width={140} sx={{ mb: 2 }} />
                <Grid container spacing={2} justifyContent='space-evenly'>
                    <Grid item xs={12} sm={6}>
                        <Skeleton animation='wave' variant='rect' height={160} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton animation='wave' variant='rect' height={160} />
                    </Grid>
                </Grid>
            </PaperBody>
        </DefaultPage>
    );
};

export const SkeletonCustomer = () => {
    return (
        <DefaultPage>
            <PaperBody paperSx={{ background: '#0E3C64' }}>
                <Skeleton animation='wave' variant='rect' height={40} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={32} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={32} sx={{ mb: 0.5 }} />
            </PaperBody>
            <PaperBody>
                <Skeleton animation='wave' variant='rect' height={32} width={200} sx={{ mb: 2 }} />
                <Skeleton animation='wave' variant='rect' height={32} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={45} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={45} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={45} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={45} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={45} sx={{ mb: 0.5 }} />
            </PaperBody>
        </DefaultPage>
    );
};

export const SkeletonRuns = () => {
    return (
        <DefaultPage>
            <PaperBody paperSx={{ background: '#0E3C64' }}>
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={32} />
            </PaperBody>
            <PaperBody>
                <Skeleton animation='wave' variant='rect' height={40} sx={{ mb: 2 }} />
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
                <Skeleton animation='wave' variant='rect' height={36} sx={{ mb: 0.5 }} />
            </PaperBody>
        </DefaultPage>
    );
};

export const SkeletonMediaCard = () => {
    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={90} sx={{ ml: 2, mt: 2, borderRadius: '4px' }} />
            <CardContent>
                <Skeleton animation='wave' variant='rect' height={32} width='100%' sx={{ mb: 1 }} />
                <Skeleton animation='wave' variant='rect' height={96} width='100%' />
            </CardContent>
        </Card>
    );
};