import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Divider } from '@mui/material';
import { formatDate, sortAndFilterOrders } from '../../functions/misc';
import { DefaultBodyHeader } from '../DefaultComponents';
import { grey } from '@mui/material/colors';
import { SortingContext } from './Customer';

export const DoohCampaignTable = ({ campaigns, showColumns }) => {
    const navigate = useNavigate();
    const sortingContext = useContext(SortingContext);

    return (
        <Grid item container mt={2}>
            <DefaultBodyHeader>
                <Typography variant='h6' sx={{ color: grey[600] }}>
                    DOOH
                </Typography>
            </DefaultBodyHeader>
            <Grid item container direction='column' spacing={1}>
                {sortAndFilterOrders(campaigns, sortingContext.sorting)?.map((campaign, i) => (
                    <React.Fragment key={campaign.id}>
                        <Grid
                            item
                            container
                            onClick={() => navigate(`/kampanj/${campaign.name}`)}
                            sx={{ cursor: 'pointer', mb: i !== campaigns?.length - 1 ? 1 : 0 }}
                        >
                            <Grid item xs={5} md={3} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden', pr: 1 }}>
                                <Typography variant='subtitle2' component='span' noWrap>
                                    {campaign.name ?? campaign.id}
                                </Typography>
                            </Grid>
                            <Grid item xs={7} md={1.5} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                                <Typography variant='subtitle2' component='span' noWrap>
                                    {formatDate(campaign.start)}{!showColumns && (` - ${formatDate(campaign.end) ?? ''}`)}
                                </Typography>
                            </Grid>
                            {showColumns && (
                                <Grid item md={1.5}>
                                    <Typography variant='subtitle2' component='span'>
                                        {formatDate(campaign.end) ?? ''}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        {i !== campaigns?.length - 1 && <Divider sx={{ mx: 1 }} />}
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
};