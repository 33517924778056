import React, { useContext, useState } from 'react';
import { DefaultCard, DefaultPage, PaperBody } from '../DefaultComponents';
import { AdNumberGrid } from '../lineitem/display/AdNumberGrid';
import { TargetingGrid } from '../lineitem/display/TargetingGrid';
import { InteractionsGrid } from '../lineitem/display/InteractionsGrid';
import { MaterialGrid } from '../lineitem/display/MaterialGrid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCombinedReport } from '../../functions/hooks/combinedReportHandler';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, FormControlLabel, Grid, Stack, Switch, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { sortByExternalIdOrDate, sumArrayClicks } from '../lineitem/display/displayFunction';
import { ClickDistribution } from '../lineitem/display/ClickDistribution';
import { UserContext } from '../../App';
import { useMobile } from '../../material/theme';
import { formatDate } from '../../functions/misc';
import { SkeletonLineitem } from '../Skeletons';
import { SaveCombinedReport } from './SaveCombinedReport';
import { GenerateExternalLink } from '../lineitem/GenerateExternalLink';

export const CombinedReport = () => {
    const isMobile = useMobile();
    const location = useLocation();
    const navigate = useNavigate();

    const { reportId } = useParams();
    const lineitemIds = location.state?.lineitemIds ?? null;
    const isPublic = location.pathname.startsWith("/extern/kombinera");

    const { report, loading } = useCombinedReport(reportId, lineitemIds, isPublic);

    const user = useContext(UserContext);
    const isInternal = user?.isInternal ?? false;

    if (loading) return <SkeletonLineitem />;
    else if (report) return (
        <DefaultPage>
            <PaperBody paperSx={{ background: '#0E3C64' }}>
                <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                    <Grid item container xs={12} md direction='column'>
                        <Grid item xs>
                            <Typography variant='h5' color='white'>
                                {report?.name ?? "Kombinerad rapport"}
                            </Typography>
                        </Grid>
                        {report?.description && (
                            <Grid item xs>
                                <Typography variant='p' sx={{ color: grey[200] }}>
                                    {report?.description}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs>
                            {(isInternal && !isPublic) ? (
                                <Typography
                                    variant='p'
                                    sx={{
                                        'color': blueGrey[300],
                                        'cursor': 'pointer',
                                        '&:hover': { color: blueGrey[400] },
                                    }}
                                    onClick={() => navigate(`/kund/${report?.advertiser?.id}`)}
                                >
                                    {report?.advertiser?.name}
                                </Typography>
                            ) : (
                                <Typography variant='p' sx={{ color: blueGrey[300] }}>
                                    {report?.advertiser?.name}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {(isInternal && !isPublic) && (
                        <Grid item container xs={12} md={3} spacing={0.5} alignItems='center' justifyContent={isMobile ? 'space-between' : 'flex-end'}>
                            {reportId === "ny" ? (
                                <SaveCombinedReport report={report} />
                            ) : (
                                <GenerateExternalLink stringToEncrypt={reportId} combinedReport />
                            )}
                        </Grid>
                    )}
                </Grid>
            </PaperBody>
            {/* TODO pie chart för fördelning av visningar och klick? */}
            <PaperBody>
                {report?.lineitemIds && (
                    <Typography variant='body1' sx={{ color: grey[600] }}>
                        {new Intl.ListFormat('se').format(report?.lineitemIds.sort())}
                    </Typography>
                )}
                {(report?.start && report?.end) && (
                    <Typography variant='subtitle1' sx={{ mb: 2 }}>
                        {formatDate(report?.start)} - {formatDate(report?.end)}
                    </Typography>
                )}
                <Grid container spacing={isMobile ? 2 : 0} justifyContent='space-evenly' mt={1}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DefaultCard title="Visningar" infoText="Antalet gånger som annonsen har visats för användare. Detta mäter exponeringen av annonsen.">
                            <Typography variant='h3'>{report?.combined?.impressionsDelivered?.toLocaleString('sv-SE') ?? 0}</Typography>
                            <Typography sx={{ color: grey[600] }}>av {report?.combined?.impressionsGoal.toLocaleString('sv-SE') ?? 0}</Typography>
                        </DefaultCard>
                    </Grid>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                        <Divider orientation='vertical' variant='middle' />
                    </Box>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DefaultCard title='CTR' infoText="Click-through rate (CTR) är procentandelen användare som klickade på annonsen i förhållande till antalet visningar.">
                            <Typography variant='h3'>
                                {report?.combined?.ctr.toFixed(2) ?? 0}%
                            </Typography>
                            <Typography sx={{ color: grey[600] }}>{report?.combined?.clicksDelivered?.toLocaleString('sv-SE') ?? '0'} klick</Typography>
                            {sumArrayClicks(report?.combined) !== null && <ClickDistribution clicks={sumArrayClicks(report?.combined)} />}
                        </DefaultCard>
                    </Grid>
                </Grid>
            </PaperBody>
            <TargetingGrid item={report?.combined} combinedReport />
            <InteractionsGrid item={report?.combined} />
            {report?.lineitems && (
                <Grid item>
                    {sortByExternalIdOrDate(report?.lineitems)?.map(item => <LineitemAccordion key={item?.id} item={item} />)}
                </Grid>
            )}
        </DefaultPage>
    );
    else return <></>;
};

const LineitemAccordion = ({ item }) => {
    const [showMaterial, setShowMaterial] = useState(false);

    return (
        <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 2 }} divider={<Divider orientation="vertical" flexItem />}>
                    <Typography>{item?.externalId ?? item?.id}</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{formatDate(item?.start)} - {formatDate(item?.end)}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <AdNumberGrid item={item} combinedReport />
                <InteractionsGrid item={item} />
                <TargetingGrid item={item} combinedReport />
                <PaperBody>
                    <FormControlLabel
                        control={
                            <Switch
                                color="accent"
                                checked={showMaterial}
                                onChange={() => setShowMaterial(!showMaterial)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Visa annonsmaterial"
                    />
                </PaperBody>
                {showMaterial && <MaterialGrid item={item} />}
            </AccordionDetails>
        </Accordion>
    )
}