import React, { useContext, useState } from 'react';
import { Box, Typography, Menu, Button, MenuItem, Grid, ButtonGroup, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InternalUserIcon } from './InternalUserIcon';
import { UserContext } from '../../App';
import { NTMMediaLogo } from './Navbar';

export const DesktopMenu = props => {
    const { layout } = props;
    const navigate = useNavigate();

    return (
        <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item container xs alignItems='center'>
                <Grid item>
                    <Box sx={{ mr: 2.5, display: 'flex', cursor: 'pointer' }} onClick={() => navigate('/')}>
                        <NTMMediaLogo />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ display: 'flex' }}>
                        {layout.map(l => (
                            <NavbarNavigation key={l.text} data={l} />
                        ))}
                    </Box>
                </Grid>
            </Grid>
            <Grid item>
                <InternalUserIcon />
            </Grid>
        </Grid>
    );
};

const NavbarNavigation = props => {
    return props.data.subRoutes ? <NavbarButtonGroup {...props} /> : <NavbarButton {...props} />;
};

const NavbarButton = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = props;
    const isSelected = location.pathname === data.route;
    const user = useContext(UserContext);
    const userIsInternal = user?.isInternal;
    const showButton = data.public || userIsInternal;

    return (
        <>
            {showButton && (
                <Button
                    variant='text'
                    color='primary'
                    disableElevation
                    sx={{ mx: 0.5, backgroundColor: isSelected ? 'primary.light' : null, display: 'block', }}
                    onClick={() => navigate(data.route)}
                >
                    {data.text}
                </Button>
            )}
        </>
    );
};

const NavbarButtonGroup = props => {
    const location = useLocation();
    const navigate = useNavigate();

    const { data } = props;
    const isSelected = location.pathname === data.route;

    const user = useContext(UserContext);
    const userIsInternal = user?.isInternal;

    const showButton = data.public || userIsInternal;

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = event => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = () => setAnchorElNav(null);

    return (
        <>
            {showButton && (
                <>
                    <ButtonGroup disableElevation variant='text' sx={{ mx: 0.5 }}>
                        <Button
                            variant='text'
                            color='primary'
                            disableElevation
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={() => navigate(data.route)}
                            sx={{ backgroundColor: isSelected ? 'primary.light' : null, borderRight: "none !important" }}
                        >
                            {data.text}
                        </Button>
                        <Button
                            variant='text'
                            color='primary'
                            disableElevation
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            sx={{ padding: '0px' }}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        id='menu-appbar'
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                    >
                        <SubRoutes routes={data.subRoutes} userIsInternal={userIsInternal} closeNav={handleCloseNavMenu} />
                    </Menu>
                </>
            )}
        </>
    );
};

const SubRoutes = props => {
    const { routes, closeNav } = props;
    const navigate = useNavigate();
    const location = useLocation();

    let list = [];
    let prevGroup = null;

    routes.forEach((sr) => {
        const isSelected = location.pathname === sr.route;
        if (prevGroup !== sr.group) {
            sr.group && list.push(<Typography key={`title-${sr.group}`} variant="subtitle1" color="text.secondary" sx={{ px: 1, userSelect: "none" }}>{sr.group}</Typography>)
            sr.group && list.push(<Divider key={`divider-${sr.group}`} />);
            prevGroup = sr.group;
        }
        list.push(
            <MenuItem
                key={sr.route}
                sx={{
                    minWidth: 136,
                    backgroundColor: isSelected ? "rgba(0,0,0,0.1)" : "none",
                }}
                onClick={() => {
                    navigate(sr.route);
                    closeNav();
                }}
            >
                {sr.text}
            </MenuItem>
        );
    });

    return <React.Fragment>{list}</React.Fragment>;
};
