import React, { useState } from "react";
import { PaperBody, DefaultPage, HeaderWithText, DefaultCard } from "../DefaultComponents";
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { flattenedLayout } from "../../config/navigation";
import { getBackendSubDomain } from "../../functions/subDomain";
import { useMobile } from "../../material/theme";

export const AverageStats = () => {
    const isMobile = useMobile();
    const info = flattenedLayout.averageStats;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({
        type: "",
        months: "",
    });

    const isAura = () => !settings?.type?.includes("x");

    const fetchData = () => {
        setLoading(true);
        return fetch(`${getBackendSubDomain("nadir")}/gam/average/${isAura() ? "aura" : "nonaura"}`, {
            headers: { "content-type": "application/json" },
            method: "POST",
            body: JSON.stringify({
                type: settings.type,
                months: settings.months,
            }),
        })
            .then(res => res.json())
            .then(res => setData(res))
            .catch(err => console.warn(err))
            .finally(() => setLoading(false));
    };

    const handleSettings = (key, value) => {
        if (data) setData(null);
        setSettings({
            ...settings,
            [key]: value,
        });
    };

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <PaperBody>
                <Grid container direction="column" spacing={2}>
                    <Grid item container spacing={1.5} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm="auto">
                            <Typography>Visa statistik för</Typography>
                        </Grid>
                        <Grid item xs={12} sm>
                            <FormControl variant="outlined" size="medium" fullWidth>
                                <InputLabel>Format</InputLabel>
                                <Select label="Format" value={settings.type} onChange={value => handleSettings("type", value.target.value)}>
                                    <MenuItem value="broker">Aura Bostad</MenuItem>
                                    <MenuItem value="event">Aura Event</MenuItem>
                                    <MenuItem value="jobs">Aura Jobb</MenuItem>
                                    <MenuItem value="grocery">Aura Livs</MenuItem>
                                    <MenuItem value="auto">Aura Motor</MenuItem>
                                    <MenuItem value="news">Aura Nyheter/Kommunwidget</MenuItem>
                                    <MenuItem value="300x250">300x250</MenuItem>
                                    <MenuItem value="300x600">300x600</MenuItem>
                                    <MenuItem value="320x320">320x320</MenuItem>
                                    <MenuItem value="320x480">320x480</MenuItem>
                                    <MenuItem value="640x320">640x320</MenuItem>
                                    <MenuItem value="640x960">640x960 (Mobile Takeover)</MenuItem>
                                    <MenuItem value="980x240">980x240</MenuItem>
                                    <MenuItem value="1920x1080">1920x1080 (Desktop Takeover)</MenuItem>
                                    <MenuItem value="640x360">640x360v (Preroll)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Typography>sedan</Typography>
                        </Grid>
                        <Grid item xs={12} sm>
                            <FormControl variant="outlined" size="medium" fullWidth>
                                <InputLabel>Tidsperiod</InputLabel>
                                <Select label="Tidsperiod" value={settings.months} onChange={value => handleSettings("months", value.target.value)}>
                                    <MenuItem value={1}>1 månad tillbaka</MenuItem>
                                    <MenuItem value={3}>3 månader tillbaka</MenuItem>
                                    <MenuItem value={6}>6 månader tillbaka</MenuItem>
                                    <MenuItem value={12}>1 år tillbaka</MenuItem>
                                    <MenuItem value={24}>2 år tillbaka</MenuItem>
                                    <MenuItem value={999}>mer än 2 år tillbaka</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-end" alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{ height: "56px" }}
                                color="primary"
                                disableElevation
                                disabled={loading || !settings.type || !settings.months}
                                onClick={() => fetchData()}
                            >
                                {loading ? "Laddar..." : "Hämta statistik"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperBody>
            {data && (
                <PaperBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {isAura() ? (
                                <Typography variant="h6">
                                    Genomsnittlig statistik baserad på{" "}
                                    <strong>
                                        {data?.numLineitems} {data?.numLineitems > 1 ? "kampanjer" : "kampanj"}
                                    </strong>{" "}
                                    från{" "}
                                    <strong>
                                        {data?.numAdvertisers} {data?.numAdvertisers > 1 ? "kunder" : "kund"}
                                    </strong>
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    Statistik för <strong>{settings?.type}</strong>
                                </Typography>
                            )}
                        </Grid>
                        <Grid item container spacing={isMobile ? 2 : 0} justifyContent="space-evenly">
                            <Grid item xs={12} sm={6} lg={3}>
                                <DefaultCard title="Visningar">
                                    <Typography variant="h4">{data?.averageStats?.impressionsDelivered?.toLocaleString("sv-SE") ?? 0}</Typography>
                                </DefaultCard>
                            </Grid>
                            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                <Divider orientation="vertical" variant="middle" />
                            </Box>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DefaultCard title="CTR">
                                    <Typography variant="h4">{data?.averageStats?.ctr?.toFixed(2) ?? 0}%</Typography>
                                    <Typography sx={{ color: grey[600] }}>{data?.averageStats?.clicksDelivered?.toLocaleString("sv-SE") ?? 0} klick</Typography>
                                </DefaultCard>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={isMobile ? 2 : 0} justifyContent="space-evenly">
                            {isAura() && data?.averageStats?.interactions !== 0 && (
                                <>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <DefaultCard title="Interaktioner">
                                            <Typography variant="h3">{data?.averageStats?.interactions?.toLocaleString("sv-SE") ?? 0}</Typography>
                                            <Typography sx={{ color: grey[600] }}>
                                                {data?.averageStats?.interactionsPerImpression?.toFixed(2) ?? 0} per visning
                                            </Typography>
                                        </DefaultCard>
                                    </Grid>
                                    <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                        <Divider orientation="vertical" variant="middle" />
                                    </Box>
                                </>
                            )}
                            {isAura() && (
                                <Grid item xs={12} sm={6} lg={3}>
                                    <DefaultCard title="Visningstid">
                                        <Typography variant="h3">{(data?.averageStats?.timeInScreen / 1000)?.toFixed(2) ?? 0}</Typography>
                                        <Typography sx={{ color: grey[600] }}>sekunder per visning</Typography>
                                    </DefaultCard>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </PaperBody>
            )}
        </DefaultPage>
    );
};
