import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Grid, Dialog, IconButton, DialogTitle, DialogContent, Link, Switch, FormControlLabel } from '@mui/material';
import { grey } from '@mui/material/colors';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloseIcon from '@mui/icons-material/Close';
import { CustomInfoIcon } from '../../InfoIcon';
import { UserContext } from '../../../App';
import { DefaultTooltip } from '../../DefaultComponents';
import { trackGAEvent } from '../../../functions/googleAnalytics';

export const ClickDistribution = props => {
    const { clicks, size } = props;
    const location = useLocation();

    const user = useContext(UserContext);
    const isInternal = user?.isInternal ?? false;

    const [open, setOpen] = useState(false);
    const [showAsPercentage, setShowAsPercentage] = useState(true);

    return (
        <>
            <DefaultTooltip title={`Visa fördelning av klick för ${size ?? "kampanjen"}`}>
                <IconButton
                    onClick={() => {
                        trackGAEvent("Button click", "Visa fördelning av klick", location.pathname + location.search);
                        setOpen(true);
                    }}
                >
                    <FormatListNumberedIcon />
                </IconButton>
            </DefaultTooltip>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item xs container alignItems="center">
                            Fördelning av klick för {size ?? "kampanjen"}
                            {isInternal && (
                                <CustomInfoIcon
                                    sx={{ ml: 1 }}
                                    text="Observera att den totala mängden klick kan skilja mellan adservern och klickfördelningen"
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={() => setOpen(false)} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {isInternal && (
                            <Grid item container>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="accent"
                                                checked={showAsPercentage}
                                                onChange={() => setShowAsPercentage(!showAsPercentage)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label="Visa fördelning i procent"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption" fontStyle="italic">Externa användare kan endast se fördelning i procent</Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item container direction="column" spacing={2}>
                            <Grid item container spacing={3} alignItems="center">
                                {Object.entries(clicks.perImage).sort((a, b) => b[1]["value"] - a[1]["value"]).map(([id, data]) => {
                                    const percentage = ((data.value / clicks.total) * 100).toFixed(2);
                                    const imageTitle = data.text ?? (id.includes("klick_") ? `Bild ${id.split("_")[1]}` : null);
                                    return (
                                        <Grid key={id} item container xs={12} sm={6} md={4} spacing={1} sx={{ textAlign: "center" }}>
                                            {data.image && (
                                                <Grid item xs={12}>
                                                    {imageTitle && (<Typography variant="caption">{imageTitle}</Typography>)}
                                                    <DefaultTooltip title={`Gå till ${data.url}`}>
                                                        <Link href={data.url} target="_blank" rel="noreferrer" underline="none">
                                                            <img src={data.image} style={{ marginBottom: "auto", display: "block", maxWidth: "100%", maxHeight: "100%" }} alt="" />
                                                        </Link>
                                                    </DefaultTooltip>
                                                </Grid>
                                            )}
                                            <Grid item container spacing={1} xs={12}>
                                                {!data.image && (
                                                    <>
                                                        {data.text && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="caption">{data.text}</Typography>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <Link href={data.url} target="_blank" rel="noreferrer" underline="none" style={{ maxWidth: "100%", wordWrap: "break-word" }}>
                                                                <Typography variant="caption" sx={{ fontStyle: "italic", color: "rgba(0, 0, 0, 0.87)", lineHeight: 1 }}>{data.url}</Typography>
                                                            </Link>
                                                        </Grid>
                                                    </>
                                                )}
                                                {showAsPercentage ? (
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ lineHeight: 1 }}>{percentage}%</Typography>
                                                        <Typography sx={{ color: grey[600] }}>av {size ? "materialets" : "kampanjens"} klick</Typography>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" sx={{ lineHeight: 1 }}>{data.value} <span style={{ color: grey[600] }}>klick</span></Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};