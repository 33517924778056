import React, { useEffect, useState } from 'react';
import { Card, CardContent, FormControl, Button, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, Dialog, DialogTitle, IconButton, CircularProgress, DialogContent } from '@mui/material';
import { DefaultBody, DefaultPage, HeaderWithText } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import { getBackendSubDomain } from '../../functions/subDomain';
import CloseIcon from '@mui/icons-material/Close';

export const AiAdGenerator = props => {
    const info = flattenedLayout.aiAdGenerator;

    const [adSettings, setAdSettings] = useState({
        prompt: null,
        size: null,
    });
    const [loading, setLoading] = useState(false);
    const [generatedImage, setGeneratedImage] = useState(null);

    const generateImage = () => {
        async function getData() {
            setLoading(true);
            const response = await fetch(`${getBackendSubDomain('krv')}/openai/image/`, {
                headers: { 'content-type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(adSettings),
            });
            const data = await response.json();
            setGeneratedImage(data.url);
            setLoading(false);
        }
        getData();
    }

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <Grid item xs>
                <Paper elevation={0} sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item container xs={6} spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label='Beskriv din annons så utförligt som möjligt'
                                    disabled={loading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {
                                        setAdSettings({ ...adSettings, prompt: e.target.value });
                                    }}
                                    variant='outlined'
                                    value={adSettings.prompt ?? ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant='outlined' size='medium' fullWidth>
                                    <InputLabel>Välj format</InputLabel>
                                    <Select
                                        label='Välj format'
                                        value={adSettings.size}
                                        disabled={loading}
                                        onChange={e => {
                                            setAdSettings({ ...adSettings, size: e.target.value });
                                        }}
                                    >
                                        <MenuItem value={"256x256"}>Mobil Panorama</MenuItem>
                                        <MenuItem value={"256x256"}>Desktop Panorama</MenuItem>
                                        <MenuItem value={"256x256"}>Desktop Insider</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' color='secondary' onClick={() => generateImage()} disabled={loading}>
                                    Generera annons
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <PreviewOnSite image={generatedImage} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={6} spacing={2} justifyContent='center'>
                            <Grid item xs={12}>
                                {generatedImage ? (
                                    <img src={generatedImage} />
                                ) : (
                                    <Card sx={{ height: "100%" }} variant='outlined'>
                                        <Grid container sx={{ height: "100%" }} justifyContent="center" alignContent="center">
                                            {loading ? (
                                                <CircularProgress size={50} color='primary' />
                                            ) : (
                                                <Typography variant='body2'>
                                                    Ingen annons har genererats
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </DefaultPage>
    );
};

const PreviewOnSite = props => {
    const { image } = props;
    const sites = ["Corren", "NT", "MVT"]
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState(null);
    const [link, setLink] = useState(null);

    const uploadCreative = async () => {
        setLoading(true);
        const options = {
            headers: { 'content-type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                url: image,
                name: "BannerAD AI",
                size: { "width": 256, "height": 256 },
                lineitemId: 6375590530
            }),
        };
        await fetch(`${getBackendSubDomain('krv')}/gam/creative/create/`, options)
            .then(res => res.json())
            .then(data => {
                generateLink(data.creativeId);
            })
            .catch(err => {
                console.warn(err);
                setLoading(false);
            });
    };

    const generateLink = (creativeId) => {
        let tld = 'se';
        if (site === 'affarsliv') tld = 'com';
        if (site === 'kuriren') tld = 'nu';
        const siteUrl = `https://${site}.${tld}`;
        const options = {
            headers: { 'content-type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                lineItemId: 6375590530,
                creativeId: creativeId,
                site: siteUrl,
            }),
        };
        fetch(`${getBackendSubDomain('krv')}/gam/site_preview`, options)
            .then(res => res.text())
            .then(data => {
                setLink(data);
                setLoading(false);
            })
            .catch(err => {
                console.warn(err);
                setLoading(false);
            });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant='contained' color='secondary' onClick={handleOpen}>
                Generera förhandsvisning på sajt
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>Förhandsgranska på site</Grid>
                        <Grid item>
                            <IconButton edge='end' color='inherit' onClick={handleClose} aria-label='close'>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={2} pt={1} pb={link ? 0 : 2}>
                        <Grid item container spacing={2} alignItems='center' justifyContent='space-between'>
                            <Grid item xs={12} sm={8}>
                                <FormControl variant='outlined' size='small' fullWidth>
                                    <InputLabel>Välj site</InputLabel>
                                    <Select label='Välj site' value={site} onChange={value => setSite(value.target.value)}>
                                        {sites.sort().map(s => {
                                            return (
                                                <MenuItem key={s} value={s?.toLowerCase()}>
                                                    {s}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant='contained' sx={{ height: 40 }} fullWidth disabled={!site} onClick={uploadCreative}>
                                    Generera länk
                                </Button>
                            </Grid>
                        </Grid>
                        {loading && (
                            <Grid item container xs={12} mt={2} mb={1} justifyContent='center'>
                                <CircularProgress size={50} color='primary' />
                            </Grid>
                        )}
                        {link && !loading && (
                            <>
                                <Grid item xs={12}>
                                    <TextField value={link} multiline fullWidth maxRows={10} variant='outlined' />
                                </Grid>
                                <Grid item container justifyContent='flex-end' spacing={1}>
                                    <Grid item>
                                        <Button onClick={() => navigator.clipboard.writeText(link)}>Kopiera länk</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => window.open(link, '_blank')}>Öppna länk</Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};
