import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Divider } from '@mui/material';
import { deliverySort, formatDate } from '../../functions/misc';
import { lineItemStatus } from '../../functions/translate';
import { SortingContext } from './Customer';
import { DeliveryProgressBar } from '../DeliveryIndicators';
import { TargetingTooltip } from '../campaigns/LineItems';
import { LineitemCheckbox } from '../combinedReport/CombinedReportCheckboxes';

export const LineitemTable = ({ lineitems, showColumns, combinedReport, handleCombinedReport }) => {
    const sortingContext = useContext(SortingContext);
    const navigate = useNavigate();

    const sortLineitems = arr => {
        const column = sortingContext.sorting.column;
        const v = sortingContext.sorting.ascending ? 1 : -1;
        arr.sort((a, b) => {
            if (column === 'totalImpressionsDelivered') {
                a = a?.stats?.impressionsDelivered ?? 0;
                b = b?.stats?.impressionsDelivered ?? 0;
            } else if (column === 'totalClicksDelivered') {
                a = a?.stats?.clicksDelivered ?? 0;
                b = b?.stats?.clicksDelivered ?? 0;
            } else if (column === 'delivery') {
                a = deliverySort(a) ?? 0;
                b = deliverySort(b) ?? 0;
            } else {
                a = a[column];
                b = b[column];
            }
            if (a > b) return v;
            if (a < b) return -v;
            return 0;
        });
        return arr;
    };

    const sortedLineitems = sortLineitems(lineitems);

    return (
        <Grid container direction='column' spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.04)', pb: 1 }}>
            {sortedLineitems?.map((lineitem, i) => (
                <React.Fragment key={lineitem.id}>
                    <Grid
                        item
                        container
                        onClick={() => navigate(`/kampanj/${lineitem.externalId ?? lineitem.id}`)}
                        sx={{ cursor: 'pointer', mb: i !== lineitems?.length - 1 ? 1 : 0 }}
                    >
                        <Grid item xs={5} md={2} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden', pr: 1 }}>
                            <Typography variant='subtitle2' component='span' noWrap>
                                {lineitem.externalId ?? lineitem.name ?? lineitem.id}
                            </Typography>
                        </Grid>
                        {showColumns && (
                            <Grid item container md={1} alignContent='center'>
                                <TargetingTooltip lineitem={lineitem} />
                            </Grid>
                        )}
                        <Grid item xs={7} md={1.5} sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                            <Typography variant='subtitle2' component='span' noWrap>
                                {formatDate(lineitem.start)}{!showColumns && (` - ${formatDate(lineitem.end) ?? ''}`)}
                            </Typography>
                        </Grid>
                        {showColumns && (
                            <Grid item md={1.5}>
                                <Typography variant='subtitle2' component='span'>
                                    {formatDate(lineitem.end) ?? ''}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={5} md={1.5}>
                            <Typography variant='subtitle2' component='span' noWrap>
                                {lineitem?.stats?.impressionsDelivered?.toLocaleString('sv-SE') ?? 0}{!showColumns && ' visningar'}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={1.5}>
                            <Typography variant='subtitle2' component='span' noWrap>
                                {lineitem?.stats?.clicksDelivered?.toLocaleString('sv-SE') ?? 0}{!showColumns && ' klick'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={1}>
                            <Typography variant='subtitle2' component='span'>
                                {lineItemStatus[lineitem.status] ?? '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={1} container alignItems='center'>
                            <DeliveryProgressBar size='small' lineitem={lineitem} />
                        </Grid>
                        <Grid item xs md={1} container justifyContent='flex-end'>
                            <LineitemCheckbox
                                lineitemId={lineitem.id}
                                combinedReport={combinedReport}
                                handleCombinedReport={handleCombinedReport}
                            />
                        </Grid>
                    </Grid>
                    {i !== lineitems?.length - 1 && <Divider sx={{ mx: 1 }} />}
                </React.Fragment>
            ))}
        </Grid>
    );
};