import { useState, useEffect, useMemo, useContext } from 'react';
import { caluclateLineitemCTR, deliverySort } from '../misc';
import { getBackendSubDomain } from '../subDomain';
import { useDebounce } from 'use-debounce';
import { LocalStorageContext as LocalStorageContext } from '../../App';

export const customerIsFavorite = (arr, id) => arr?.find(advertiser => advertiser.id === id);

export const useLineitems = () => {
    const { localStorage, setLocalStorage } = { ...useContext(LocalStorageContext) };
    const [config, setConfig] = useState({
        direction: 'ascending',
        key: 'externalId',
        onlyFavorite: false,
        onlyMissingCreatives: false,
        status: 'completed',
    });
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleConfig = obj => {
        setConfig({
            ...config,
            ...obj,
        });
    };

    useEffect(() => {
        if (!config.status) return;

        const fetchLineitems = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${getBackendSubDomain('nadir')}/gam/lineitems/${config.status}`, {
                    headers: {
                        'content-type': 'application/json',
                    },
                    method: 'GET',
                });
                const r = await response.json();
                setItems(r);
            } catch (error) {
                console.warn(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLineitems();
    }, [config.status]);

    //debounced ms + usememo, trigger new items list if items, config.key, config.direction changes.
    const [sortedItems] = useDebounce(
        useMemo(() => {
            let sortableItems = [...items];

            sortableItems.sort((a, b) => {
                const direction = config.direction === 'ascending' ? 1 : -1;
                if (config.key === 'advertiser') [a, b] = [a?.advertiser?.name, b?.advertiser?.name];
                else if (config.key === 'delivery') [a, b] = [deliverySort(a), deliverySort(b)];
                else if (config.key === 'impressionsDelivered' || config.key === 'clicksDelivered') [a, b] = [a?.stats?.[config.key], b?.stats?.[config.key]];
                else [a, b] = [a[config.key], b[config.key]];
                return a < b ? -1 * direction : direction;
            });

            return sortableItems;
        }, [items, config.key, config.direction]),
        300
    );

    //debounced 300ms + usememo, trigger new items list if config.onlyFavorite, sortedItems
    const [filteredItems] = useDebounce(
        useMemo(() => {
            let filtered = [...sortedItems];
            if (config.onlyFavorite) filtered = filtered.filter(lineitem => customerIsFavorite(localStorage?.favoriteAdvertisers ?? [], lineitem.advertiser.id));
            if (config.onlyMissingCreatives) filtered = filtered.filter(lineitem => lineitem?.creatives?.length === 0);
            return filtered;
        }, [config.onlyMissingCreatives, config.onlyFavorite, sortedItems, localStorage.favoriteAdvertisers]),
        300
    );

    return { items: filteredItems, loading, config, handleConfig: handleConfig };
};

export const useLineitemsX = (req) => {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLineitems = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${getBackendSubDomain('nadir')}/gam/lineitems/all`, {
                    headers: {
                        'content-type': 'application/json',
                    },
                    method: 'GET',
                });
                const r = await response.json();
                setItems(r);
            } catch (error) {
                console.warn(error);
            } finally {
                setLoading(false);
            }
        };

        if (req) fetchLineitems();
    }, [req]);

    return { items: items, loading };
};
