import { Avatar } from '@mui/material';
import React from 'react';
import gotlandjustnu from '../images/gotlandjustnu.png';
import affarsliv from '../images/affarsliv.png';
import ekuriren from '../images/ekuriren.png';
import folkbladet from '../images/folkbladet.png';
import helagotland from '../images/helagotland.png';
import kindaposten from '../images/kindaposten.png';
import vt from '../images/vt.png';
import corren from '../images/corren.png';
import mvt from '../images/mvt.png';
import nsd from '../images/nsd.png';
import vimmerbytidning from '../images/vimmerbytidning.png';
import sn from '../images/sn.png';
import eposten from '../images/eposten.png';
import norrbottensaffarer from '../images/norrbottensaffarer.png';
import kuriren from '../images/kuriren.png';
import nt from '../images/nt.png';
import pt from '../images/pt.png';
import strengnastidning from '../images/strengnastidning.png';
import norran from '../images/norran.png';
import kkuriren from '../images/kkuriren.png';
import unt from '../images/unt.png';
//import framtidsfabriken from'../images/framtidsfabriken.svg';
import klackspark from '../images/klackspark.png';

import ntm from '../images/ntm.png';

const sites = {
    affarsliv: { icon: affarsliv },
    ekuriren: { icon: ekuriren },
    folkbladet: { icon: folkbladet },
    helagotland: { icon: helagotland },
    kindaposten: { icon: kindaposten },
    vt: { icon: vt },
    corren: { icon: corren },
    mvt: { icon: mvt },
    nsd: { icon: nsd },
    vimmerbytidning: { icon: vimmerbytidning },
    sn: { icon: sn },
    eposten: { icon: eposten },
    klackspark: { icon: klackspark },
    norrbottensaffarer: { icon: norrbottensaffarer },
    kuriren: { icon: kuriren },
    nt: { icon: nt },
    pt: { icon: pt },
    strengnastidning: { icon: strengnastidning },
    norran: { icon: norran },
    kkuriren: { icon: kkuriren },
    unt: { icon: unt },
    //"framtidsfabriken": {"icon": framtidsfabriken},
    gotlandjustnu: { icon: gotlandjustnu },
    ntm: { icon: ntm },
};

export const SiteImage = props => {
    const site = props?.site;
    const size = props?.size;
    return <Avatar sx={{ width: size ?? 32, height: size ?? 32, marginLeft: '-2px' }} src={sites?.[site?.toLowerCase()]?.['icon'] ?? ntm} />;
};
