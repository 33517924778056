import { Grid, Paper, Typography, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomInfoIcon } from '../InfoIcon';

export const CampaignsList = props => {
    const { arr, title, infoText } = props;
    const navigate = useNavigate();

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant='subtitle1' gutterBottom>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <CustomInfoIcon text={infoText} />
                </Grid>
            </Grid>
            <List component='ul' disablePadding dense sx={{ overflow: 'auto', maxHeight: '310px' }}>
                {arr?.map(campaign => {
                    return (
                        <ListItem disablePadding key={campaign?.id}>
                            <ListItemButton onClick={() => navigate(`/kampanj/${campaign.externalId ?? campaign.id}`)}>
                                <ListItemText
                                    primary={<Typography variant='subtitle2'>
                                        {campaign.externalId && `${campaign.externalId} - `}{campaign.name}
                                    </Typography>}
                                    secondary={<Typography variant='body2' color='text.secondary'>
                                        {campaign.customer}
                                    </Typography>} />
                                <Typography sx={{ fontWeight: 'bold' }}>{campaign?.type === 'dooh' ? 'DOOH' : 'Display'}</Typography>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            {!arr?.length && (
                <Typography variant='subtitle2' color='text.secondary'>
                    Du har inga {title.toLowerCase()}
                </Typography>
            )}
        </Paper>
    );
};
