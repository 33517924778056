import { Typography, Grid } from '@mui/material';
import { DefaultTooltip, PaperBody } from '../../DefaultComponents';
import { blueGrey } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import { HeaderButtons } from './HeaderButtons';

export const TitleGrid = ({ item, updateLineitem, isInternal }) => {
    const navigate = useNavigate();

    return <PaperBody paperSx={{ background: '#0E3C64' }}>
        <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
            <Grid item container xs={12} md={5} direction='column'>
                <Grid item xs>
                    <Typography variant='h5' color='white'>
                        {item?.order?.name.split('_0')[0] ?? item?.order?.name ?? item?.order?.id}
                    </Typography>
                </Grid>
                <Grid item xs>
                    {isInternal ? (
                        <DefaultTooltip title='Se fler av kundens ordrar' placement='right'>
                            <Typography
                                variant='p'
                                sx={{
                                    'color': blueGrey[300],
                                    'cursor': 'pointer',
                                    '&:hover': { color: blueGrey[400] },
                                }}
                                onClick={() =>
                                    navigate(`/kund/${item?.advertiser?.id}`, {
                                        state: { openOrder: item?.order?.id },
                                    })
                                }
                            >
                                {item?.advertiser?.name}
                            </Typography>
                        </DefaultTooltip>
                    ) : (
                        <Typography variant='p' sx={{ color: blueGrey[300] }}>
                            {item?.advertiser?.name}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {isInternal && <HeaderButtons lineItem={item} updateLineitem={updateLineitem} />}
        </Grid>
    </PaperBody>
}



